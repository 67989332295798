import { BASE_URL } from "../../insightConfig/insightConfig";

// Function to make a GET request to fetch course data
export async function fetchCourseData(coursename, authtoken, coursepayment, coursenotpayment) {
    let url = `${BASE_URL}/course/${coursename}`;
    // if (authtoken && coursepayment === 1 && coursenotpayment === 1) {
    //   url = `${BASE_URL}/course/${coursename}/${authtoken}`;
    //   console.log("i am using email with payment")
    // }
    if (authtoken && coursepayment === 1 && coursenotpayment === 0) {
      url = `${BASE_URL}/course/${coursename}/${authtoken}`;
      console.log("i am using email with payment")
    }
    else if (authtoken && coursepayment === 0 && coursenotpayment === 1) {
      url = `${BASE_URL}/course/${coursename}`;
      console.log("i am using email without payment")
    }
    else if (authtoken && coursepayment == 0 && coursenotpayment == 0){
      url = `${BASE_URL}/course/${coursename}`;
      console.log("i am using email no payment")
    }
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data[0]; // Assuming you expect the first item of the response array
    } catch (error) {
      console.error('Error fetching course data:', error);
      throw error; // Rethrow the error for handling in the component
    }
  }

// Function to make a GET request to fetch lesson data for a course
export async function fetchLessonData(courseID) {
    const url = `${BASE_URL}/course-lesson/${courseID}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function fetchtestData(testseriesname, authtoken, coursepayment, coursenotpayment) {
    let url = `${BASE_URL}/testseries/${testseriesname}`;
    // if (authtoken && coursepayment === 1 && coursenotpayment === 1) {
    //   url = `${BASE_URL}/course/${coursename}/${authtoken}`;
    //   console.log("i am using email with payment")
    // }
    if (authtoken && coursepayment === 1 && coursenotpayment === 0) {
      url = `${BASE_URL}/testseries/${testseriesname}/${authtoken}`;
      console.log("i am using email with payment")
    }
    else if (authtoken && coursepayment === 0 && coursenotpayment === 1) {
      url = `${BASE_URL}/testseries/${testseriesname}`;
      console.log("i am using email without payment")
    }
    else if (authtoken && coursepayment == 0 && coursenotpayment == 0){
      url = `${BASE_URL}/testseries/${testseriesname}`;
      console.log("i am using email no payment")
    }
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data[0]; // Assuming you expect the first item of the response array
    } catch (error) {
      console.error('Error fetching course data:', error);
      throw error; // Rethrow the error for handling in the component
    }
  }  

  export async function fetchtestLessonData(courseID) {
    const url = `${BASE_URL}/course-test/${courseID}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function fetchCurrentNewsData() {
    const url = `${BASE_URL}/current-affairs/News`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function fetchUserDetails(authtoken) {
    const url = `${BASE_URL}/studentdashboard/${authtoken}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function fetchMyCourse(authtoken) {
    const url = `${BASE_URL}/mycourse/${authtoken}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function fetchNotMyCourse(authtoken) {
    const url = `${BASE_URL}/notmycourse/${authtoken}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function fetchMyTest(authtoken) {
    const url = `${BASE_URL}/mytestseries/${authtoken}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function fetchNotMyTest(authtoken) {
    const url = `${BASE_URL}/notmytestseries/${authtoken}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      throw error;
    }
  }

  export async function SignupUser({ name, email, contact, password }) {
    try {
        const response = await fetch(`${BASE_URL}/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, contact, password }),
        });

        // Check if response is successful
        if (!response.ok) {
            throw new Error('Registration failed');
        }

        // If registration is successful, return the response data
        return await response.json();
    } catch (error) {
        // If an error occurs, throw it
        throw error;
    }
};  