import React, { useEffect, useState, useRef } from 'react';
import "./BlogPage.css";
import blog from "../../Image/blog.jpg";
import blog1 from "../../Image/blog1.jpg";
import blog2 from "../../Image/blog2.jpg";
import blog3 from "../../Image/blog3.jpg";
import blog4 from "../../Image/blog4.jpg";
import blog5 from "../../Image/blog5.jpg";
import blog6 from "../../Image/blog6.jpg";
import blog7 from "../../Image/blog7.jpg";
import blog8 from "../../Image/blog8.jpg";
import blog9 from "../../Image/blog9.jpg";
import blog10 from "../../Image/to1.jpg";
import blog11 from "../../Image/to1.jpg";
import blog12 from "../../Image/to2.jpg";
import blog13 from "../../Image/to3.jpg";
import blog14 from "../../Image/to4.jpg";
import blog15 from "../../Image/to5.jpg";
import blog16 from "../../Image/to6.jpg";
import blog17 from "../../Image/to7.jpg";
import blog18 from "../../Image/to8.jpg";
import blog19 from "../../Image/to9.jpg";
import Buttontabs from '../Buttontabs/Buttontabs';
import InfiniteScroll from 'react-infinite-scroll-component'; // Importing InfiniteScroll
import BlogPage1 from '../BlogPageComponent/BlogPage1';
import BlogPage2 from '../BlogPageComponent/BlogPage2';
import Pagelayout2 from '../EffectonLoad/pagelayout2';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../insightConfig/insightConfig';

function BlogPage() {
    const [contentToShow, setContentToShow] = useState('Blogs');
    const [blog, setBlog] = useState([]);
    const [today, setToday] = useState([]);
    const componentName = "Blog";
    const [hasMore, setHasMore] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [isloading, setisloading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/blogs/blog`);
                const data = await response.json();
                setBlog(data.data);
                setisloading(true)
                console.log(data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setisloading(false)
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/blogs/today`);
                const data = await response.json();
                setToday(data.data);
                setisloading(true);
                console.log(data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setisloading(false);
            }
        };
        fetchData();
    }, []);
    console.log(blog)
    const fetchMoreData = async () => {
        try {
            if (blog) {
                const response = await fetch(`${BASE_URL}/blogs/blog/${blog.length}`);
                const blogData = await response.json();
                const blogarraydata = blogData.data
                console.log(blogData.data)
                // Check if newData is iterable (an array)
                if (Array.isArray(blogarraydata)) {
                    setBlog(prevNews => [...prevNews, ...blogarraydata]);
                    // Update hasMore state based on whether there is more data to fetch
                    setisloading(true)
                } else {
                    // If newData is not iterable, it might indicate an error or unexpected response
                    console.error('Error: Unexpected response format:', blogData);
                    setHasMore(false); // Assuming there's no more data to fetch in case of unexpected response
                    setisloading(false)
                }
            }
            else {
                try {
                    const response = await fetch(`${BASE_URL}/blogs/today/${today.length}`);
                    const todayData = await response.json();
                    const todayarraydata = todayData.data
                    console.log(todayData.data)
                    // Check if newData is iterable (an array)
                    if (Array.isArray(todayarraydata)) {
                        setToday(prevNews => [...prevNews, ...todayarraydata]);
                        // Update hasMore state based on whether there is more data to fetch
                        setisloading(true)
                    } else {
                        // If newData is not iterable, it might indicate an error or unexpected response
                        console.error('Error: Unexpected response format:', todayData);
                        setHasMore(false); // Assuming there's no more data to fetch in case of unexpected response
                        setisloading(false)
                    }
                } catch (error) {
                    console.error('Error fetching more data:', error);
                    setisloading(false)
                }
            }
        } catch (error) {
            console.error('Error fetching more data:', error);
            setisloading(false)
        }
    };

    console.log(blog)
    const data2 = blog;
    const data3 = today;
    console.log(data3)
    // console.log(data2.Sn)
    const handleViewMoreClickblog = () => {
        setFetching(true)
        fetchMoreData();
        setHasMore(fetching)
    };

    const handleStopViewMoreClickblog = () => {
        setFetching(false)
        setHasMore(fetching)
    };



    return (
        <>
            <div className='BlogPage-background'>
                <div className='BlogPage-box1'>
                    <h1>BLOGS - Expand your Knowledge</h1>
                </div>
                <div className='BlogPage-box2'>
                    <Buttontabs componentName={componentName} setContentToShow={setContentToShow} />
                </div>
            </div>
            {/* Display Information Section Start */}
            <InfiniteScroll
                dataLength={contentToShow === 'Blogs' ? blog.length : today.length} // This is important to prevent infinite loop when the content is updated
                next={handleStopViewMoreClickblog}
                hasMore={hasMore}
                loader={
                    fetching ? <div className='Civil-section blog1-section'>
                        <div className='civil-row white'>
                            <div className='col-lg-8 col-md-9 col-sm-12'>
                                {/* Content Component Start */}
                                {/* <CivilCard CardData={CardData} /> */}
                                <Pagelayout2 />
                                {/* Content Component End */}
                                {/* <ButtonPage /> */}
                            </div>
                        </div>
                    </div> : null
                }

            >
                {contentToShow === 'Blogs'
                    && blog &&
                    <>
                        <BlogPage1 CardData={data2} contentToShow={contentToShow} isloading={isloading} componentName={componentName} />
                    </>}
                {contentToShow === "Today's Special"
                    && today &&
                    <>
                        <BlogPage2 CardData={data3} contentToShow={contentToShow} isloading={isloading} componentName={componentName} />
                    </>}
            </InfiniteScroll>
            <div className='Civil-section blog1-section'>
                <div className='civil-row text-center view-width1 d-flex justify-content-center white col-lg-4 blog-margin-bottom'>
                    {<Link className='list-group-item p-2 px-4 rounded-pill text-white Affair-background' to="#" onClick={handleViewMoreClickblog}>Click to View More</Link>}
                </div>
            </div>
        </>
    )
}

export default BlogPage;