import React from 'react';
import "./Tag.css";
import { IoCalendarOutline } from "react-icons/io5";

function Tag() {
    return (
        <>
            <div className='Tag-background'>
                <div className='tag1'>
                    <h1>More Related News On This Topic</h1>
                    <p><IoCalendarOutline className='me-2'/>Aug 14,2021</p>
                </div>
            </div>
        </>
    )
}

export default Tag;