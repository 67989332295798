import React, { useEffect, useState } from 'react';
import "./Current.css";
import Buttontabs from '../Buttontabs/Buttontabs';
import InfiniteScroll from 'react-infinite-scroll-component'; // Importing InfiniteScroll
import Current1 from '../CurrentAffairsComponent/Current1';
import Pagelayout from '../EffectonLoad/pagelayout';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Current({setDateState, datefilteredData, datefilter}) {
    const [contentToShow, setContentToShow] = useState('In News');
    const [news, setNews] = useState([]);
    const [editorial, setEditorial] = useState([]);
    const componentName = "currentaffairs";
    const [hasMore, setHasMore] = useState(false);
    const [isloading, setisloading] = useState(false);
    const [isfilterapply, setisfilterapply] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [searchkeyword, setSearchkeyword] = useState('');
    const [searcharray, setsearcharray] = useState([]);
    // const []

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/current-affairs/News`);
                const data = await response.json();
                setNews(data.data);
                setisloading(true)
            } catch (error) {
                console.error('Error fetching data:', error);
                setisloading(false)
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/current-affairs/Editorial`);
                const data = await response.json();
                setEditorial(data.data);
                setisloading(true)
            } catch (error) {
                console.error('Error fetching data:', error);
                setisloading(false)
            }
        };
        fetchData();
    }, []);

    const fetchMoreData = async () => {
        try {
            if (contentToShow === 'In News') {
                console.log("check3")
                if (isfilterapply) {
                    let combinedDatalength = news.length
                    const response = await fetch(`${BASE_URL}/current-affairs-search-filter/News/${searchkeyword}/${combinedDatalength}`);
                    const newData = await response.json();
                    const newarraydata = newData.data
                    const combinedData = [...searcharray, ...newarraydata];
                    setsearcharray(combinedData);
                    combinedDatalength = combinedData.length
                    setNews(combinedData)
                } else {
                    const response = await fetch(`${BASE_URL}/current-affairs/News/${news.length}`);
                    const newData = await response.json();
                    const newarraydata = newData.data
                    console.log(newData.data)
                    // Check if newData is iterable (an array)
                    if (Array.isArray(newarraydata)) {
                        setNews(prevNews => [...prevNews, ...newarraydata]);
                        // Update hasMore state based on whether there is more data to fetch
                        setisloading(true);
                    } else {
                        // If newData is not iterable, it might indicate an error or unexpected response
                        console.error('Error: Unexpected response format:', newData);
                        setHasMore(false); // Assuming there's no more data to fetch in case of unexpected response
                        setisloading(false);
                    }
                }
            }
            else if(contentToShow === 'Editorial') {
                console.log("check2")
                try {
                    if (isfilterapply) {
                        let combinedDatalength = editorial.length
                        const response = await fetch(`${BASE_URL}/current-affairs-search-filter/Editorial/${searchkeyword}/${combinedDatalength}`);
                        const newData = await response.json();
                        const newarraydata = newData.data
                        const combinedData = [...searcharray, ...newarraydata];
                        setsearcharray(combinedData);
                        combinedDatalength = combinedData.length
                        setEditorial(combinedData)
                        console.log("check1")
                    } else {
                        const response = await fetch(`${BASE_URL}/current-affairs/Editorial/${editorial.length}`);
                        const newData = await response.json();
                        const newarraydata = newData.data
                        console.log(newData.data)
                        // Check if newData is iterable (an array)
                        if (Array.isArray(newarraydata)) {
                            setEditorial(prevNews => [...prevNews, ...newarraydata]);
                            console.log(editorial)
                            // Update hasMore state based on whether there is more data to fetch
                            setisloading(true);
                        } else {
                            // If newData is not iterable, it might indicate an error or unexpected response
                            console.error('Error: Unexpected response format:', newData);
                            setHasMore(false); // Assuming there's no more data to fetch in case of unexpected response
                            setisloading(false);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching more data:', error);
                    setisloading(false);
                }
            }
        } catch (error) {
            console.error('Error fetching more data:', error);
            setisloading(false);
        }
    };
    const data2 = news;
    const data3 = editorial;
    console.log(data3)

    const handleViewMoreClick = () => {
        setFetching(true)
        fetchMoreData();
        setHasMore(fetching)
    };

    const handleStopViewMoreClick = () => {
        setFetching(false)
        setHasMore(fetching)
    };

    return (
        <>
            <div className='CurrentAffairs-background'>
                <div className='current-main-box'>
                    <h1>DAILY CURRENT AFFAIRS »»</h1>
                    <Buttontabs componentName={componentName} setContentToShow={setContentToShow} />
                </div>
            </div>
            {/* Display Information Section Start */}
            <InfiniteScroll
                dataLength={contentToShow === 'In News' ? news.length : editorial.length} // This is important to prevent infinite loop when the content is updated
                next={handleStopViewMoreClick}
                hasMore={hasMore}
                loader={<>
                    <div className='Civil-section blog1-section'>
                        <div className='civil-row'>
                            <div className='col-lg-8 col-md-9 col-sm-12'>
                                {!isloading && <Pagelayout />}
                            </div>
                        </div>
                    </div>
                </>}
            >
                {/* Display Information Section Start */}
                {contentToShow === 'In News'
                    && news &&
                    <> 
                        <Current1 CardData={data2} isloading={isloading} datefilter={datefilter} datefilteredData={datefilteredData} setDateState={setDateState} isfilterapply={isfilterapply} setsearcharray={setsearcharray} setSearchkeyword={setSearchkeyword} contentToShow={"News"} setisfilterapply={setisfilterapply} componentName={componentName} />
                    </>}
                {contentToShow === 'Editorial'
                    && news &&
                    <>
                        <Current1 CardData={data3} isloading={isloading} datefilter={datefilter} datefilteredData={datefilteredData} setDateState={setDateState} isfilterapply={isfilterapply} setsearcharray={setsearcharray} setSearchkeyword={setSearchkeyword} contentToShow={contentToShow} setisfilterapply={setisfilterapply} componentName={componentName} />
                    </>}
                {contentToShow === 'Yojana' && <div></div>}
                {contentToShow === 'Big Picture' && <div></div>}
                {contentToShow === 'Insight Radio' && <div></div>}
            </InfiniteScroll>
            <div className='Civil-section blog1-section'>
                <div className='civil-row white view-width1 d-flex justify-content-center blog-margin-bottom col-lg-4'>
                    {<Link className='list-group-item p-2 px-4 rounded-pill text-white list-background' to="#" onClick={handleViewMoreClick}>Click to View More</Link>}
                </div>
            </div>
        </>
    );
}

export default Current;
