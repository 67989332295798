import React from 'react';
import CardEffect from './CardEffect'; // Assuming CardEffect is in the same directory
import { ShimmerSectionHeader } from 'react-shimmer-effects';

function TwoRowThreeColumnLayout() {
  return (
    <div className="container-fluid">
        <div className='p-3 pb-0'>
        <ShimmerSectionHeader title={true} subTitle={false} />
        </div>
      <div className="row mb-3">
        <div className="col-md-4">
          <CardEffect />
        </div>
        <div className="col-md-4">
          <CardEffect />
        </div>
        <div className="col-md-4">
          <CardEffect />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-4">
          <CardEffect />
        </div>
        <div className="col-md-4">
          <CardEffect />
        </div>
        <div className="col-md-4">
          <CardEffect />
        </div>
      </div>
    </div>
  );
}

export default TwoRowThreeColumnLayout;
