import './App.css';
import Header from './jsx/Header/Header';
import Footer from './jsx/Footer/Footer';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './jsx/Home/Home';
import Login from './jsx/Login/Login';
import Signup from './jsx/Signup/Signup';
import Forgot from './jsx/Forgot/Forgot';
import Civilabout from './jsx/CivilPage/CivilHome/Civilabout';
import { HelmetProvider } from 'react-helmet-async';
import Current from './jsx/CurrentPage/Current';
import BlogPage from './jsx/BlogPage/BlogPage';
import HistoryOptionalCourses from './jsx/CivilPage/CivilHistory/HistoryOptionalCourses';
import CivilsCard from './jsx/CivilPage/CivilsCard/CivilsCard';
import CivilsTest from './jsx/CivilPage/CivilsCard/CivilsTest';
import Contact from './jsx/Contact/Contact';
import Terms from './jsx/TermsCondition/Terms';
import DynamicCourse from './jsx/DynamicCourse/DynamicCourse';
import DynamicNews from './jsx/DynamicNews/DynamicNews';
import Dynamicblog from './jsx/Dynamicblog/Dynamicblog';
import { useEffect, useState } from 'react';
import Dashborad from './jsx/Dashborad/Dashborad';
import CourseDescription from './jsx/CourseDescription/CourseDescription';
import Cuet from './jsx/CUET/Cuet';
import DynamicToday from './jsx/Dynamicblog/DynamicToday';
import DynamicaboutPage from './jsx/DynamicaboutPage/DynamicaboutPage';
import DynamicTestseries from './jsx/DynamicTestseries/DynamicTestseries';
import { BASE_URL } from './insightConfig/insightConfig';
import Questionsummary from './jsx/QuizComponent/Questionsummary/Questionsummary';
import Questionlayout from './jsx/QuizComponent/QuestionLayout/QuestionLayout';
import TestStart from './jsx/TestStartComponent/TestStart';
import QuestionLayout from './jsx/QuizComponent/QuestionLayout/QuestionLayout';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedLoggedIn = localStorage.getItem('isLoggedIn');
    return storedLoggedIn ? JSON.parse(storedLoggedIn) : false;
  });

  const [authtoken, setAuthtoken] = useState(() => {
    const storedAuthToken = localStorage.getItem('authtoken');
    return storedAuthToken ? storedAuthToken : null;
  });

  const [prevPath, setPrevPath] = useState(null);

  const [coursepayment, setcoursepayment] = useState(0);
  const [coursenotpayment, setcoursenotpayment] = useState(0);
  console.log(coursepayment)
  console.log(coursenotpayment)
  const [ispayment, setispayment] = useState()

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const [dateState, setDateState] = useState('')
  const [datefilter, setDateFilter] = useState('')
  const [datefilteredData, setDateFilteredData] = useState('')

  // const handleDateinput = async (date) => {
  //   // setSelectedDate(date)
  //   console.log(date)
  // };

  const handleDateinput = async (date) => {
    // setSelectedDate(date)
    console.log(date)
    console.log("i am in")
    const originalDate = new Date(date);
    const formattedDate = originalDate.toISOString().split('T')[0]; // Extracting only the date part

    try {
      setDateFilter(true);
      const response = await fetch(`${BASE_URL}/current-affairs-date-filter/${dateState}/${formattedDate}`);
      const data = await response.json();
      setDateFilteredData(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Header isHeadlog={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setAuthtoken={setAuthtoken} />
          <Routes>
            <Route path='/' element={<Home setPrevPath={setPrevPath} />} />
            <Route path='/home' element={<Home setPrevPath={setPrevPath} />} />
            <Route path='/historycourse/history-optional-courses' element={<HistoryOptionalCourses />} />
            <Route path='/civils' element={<CivilsCard />} />
            <Route path='/civilstestseries' element={<CivilsTest />} />
            <Route path='/about/:abouturl' element={<DynamicaboutPage />} />
            <Route path='/about-us' element={<Civilabout />} />
            <Route path="/course/:coursename" element={<DynamicCourse ispayment={ispayment} coursepayment={coursepayment} coursenotpayment={coursenotpayment} authtoken={authtoken} setPrevPath={setPrevPath} isAuth={isLoggedIn} prevPath={prevPath} />} />
            <Route path="/CourseDescription/:sectionid/:subjectname" element={<CourseDescription ispayment={ispayment} authtoken={authtoken} isAuth={isLoggedIn} setispayment={setispayment} />} />
            <Route path='/testseries/:testseriesname' element={<DynamicTestseries ispayment={ispayment} coursepayment={coursepayment} coursenotpayment={coursenotpayment} isAuth={isLoggedIn} authtoken={authtoken} setPrevPath={setPrevPath} />} />
            <Route path="/daily-current-affairs/News" element={<Current datefilter={datefilter} datefilteredData={datefilteredData} setDateState={setDateState} />} />
            <Route path="/readmore/News/:newsurl" element={<DynamicNews handleDateinput={handleDateinput} />} />
            <Route path='/login/'>
              <Route path='' element={isLoggedIn ? <Navigate to="/StudentDashboard" /> : <Login setIsLoggedIn={setIsLoggedIn} setAuthtoken={setAuthtoken} prevPath={prevPath} />} />
              <Route path='forgot' element={<Forgot />} />
            </Route>
            <Route path='/blogs' element={<BlogPage />} />
            {/* <Route path='/cuet' element={<Cuet />} /> */}
            <Route path='/blog/:blogurl' element={<Dynamicblog />} />
            <Route path='/blogs-today-in-history/detail/:todayurl' element={<DynamicToday />} />
            <Route path='/Registration' element={<Signup setIsLoggedIn={setIsLoggedIn} setAuthtoken={setAuthtoken} prevPath={prevPath} />} />
            <Route path='/contact-us' element={<Contact authtoken={authtoken} />} />
            <Route path='/teststart' element={<TestStart/>} />
            <Route path='/testseriesstart' element={<QuestionLayout/>} />
            <Route path='/terms-and-conditions' element={<Terms />} />
            <Route path='/StudentDashboard' element={isLoggedIn ? <Dashborad ispayment={ispayment} setispayment={setispayment} setcoursepayment={setcoursepayment} setcoursenotpayment={setcoursenotpayment} authtoken={authtoken} /> : <Navigate to="/login" />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;