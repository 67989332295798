import React, { useEffect, useState } from 'react';
import "./CivilToggle.css";
import { CiLock } from "react-icons/ci";
// import { CiUnlock } from "react-icons/ci";
import { HiPlay } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../../insightConfig/insightConfig';


function CivilToggle({ classesArray, isAuth, ispayment }) {
    const [classData2, setClassData2] = useState([]);
   console.log(ispayment)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const newData = [];
                for (let i = 0; i < classesArray.length; i++) {
                    const courseID = classesArray[i].CourceID;
                    const subject = classesArray[i].Subject;
                    console.log("Fetching data for CourseID:", courseID, "and Subject:", subject);
                    const response = await fetch(`${BASE_URL}/course-topic/${courseID}/${subject}`);
                    const data = await response.json();
                    newData.push(data);
                }
                setClassData2(newData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [classesArray]);

    return (
        <>
            <div className='Toggle-container bg-light'>
                <div className="accordion" id="accordionExample">
                    {classesArray.map((classItem, index) => (
                        <div className="accordion-item bg-gray m-3" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button className="accordion-button collapsed button-toggle-container bg-gray" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-controls={`collapse${index}`}>
                                    {`${classItem.Subject}`}
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ul className="list-group">
                                        {classData2[index]?.map((section, sectionIndex) => (
                                            <li className="list-group-item d-flex justify-content-between toggle-list py-3" key={sectionIndex}>
                                                <span>{section.Title}</span>
                                                <span>{(isAuth && ispayment && section.LockStatus !== 'Lock') ? <Link to={`/CourseDescription/${section.SectionID}/${section.Title}`}><HiPlay className='fs-4 text-danger'/></Link> : <CiLock className='fs-4 text-danger' />}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default CivilToggle;
