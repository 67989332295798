import React, { useEffect } from 'react'
import CardsPage from '../CardsPage/CardsPage'

function MyCourses({cards, heading, setcoursepayment, setcoursenotpayment, setispayment}){ 
  
  useEffect(()=>{
    // {cards.map((card) => (
    //    setcoursepayment(card.paymentdonecourse),      
    //    setcoursenotpayment(card.paymentdonecourse)      
    //   ))}
      setcoursepayment(1)
      setcoursenotpayment(0)
      setispayment(true)
    },[])
    // console.log(coursepayment)
    // console.log(coursenotpayment)
  return (
    <>
      <CardsPage  cards={cards} heading={heading}/>
    </>
  )
}

export default MyCourses
