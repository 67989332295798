import React from 'react';
import "./Terms.css";
import AllPageContent from "../AllPageContent/AllPageContent";

function Terms() {
  const paragraphstatic = [
    {
      text: "",
      styles: [
        {word: "Details of a Student:" , style: "font-weight: bold;"},
        {word: "student can access every class upto maximum 3 times." , style: "font-weight: bold"},
        {word: "(1) device to access the classes during the entire course." , style: "font-weight: bold"},
        {word: "Cyber-crime as per IT Act:" , style: "font-weight: bold"},
        {word: "Refund Policy:" , style: "font-weight: bold"},
        {word: "Validity period of course:" , style: "font-weight: bold"},
        {word: "Grievance Support:" , style: "font-weight: bold"},
        {word: "Jurisdiction Agreement:" , style: "font-weight: bold"},
        {word: "Privacy policy:" , style: "font-weight: bold"},
        {word: "Device ID" , style: "font-weight: bold"},
        {word: "Such details collected are not shared with any third-party organisations." , style: "font-weight: bold"},
        {word: "Account Deletion:" , style: "font-weight: bold"},
      ],
      lists: [
        "Details of a Student: Every student subscribing any courseof has to fill/uploadthe required details on his/her ‘Profile’ page such as passport size photo, ID Proof, Address proof, etc. in compliance with the KYC (Know your Customer) requirements mandated by the management of institute and the regulatory authorities",
        "Each class shall remain accessible only for a period of 14 days after the scheduled date of class. During this period, student can access every class upto maximum 3 times. It is strongly advised that classes should be attended by student day by day as per the class plan. If any student fails to attend the class during this period of 14 days, he/she shall be fully responsible for it. Institute management shall not be responsible for such lapse in any way.",
        "The classes are accessible only through our application which is compatible with Android and iOS devicessuch as Tablet devices, Mobile Phone, iPad, and iPhone.",
        "Student shall have to use only one (1) device to access the classes during the entire course.",
        "Cyber-crime as per IT Act: Sharing your access, recording, downloading or trying to sellor distribute videos and notes is a legally punishable offence under relevant IPC sections,IT Act and other relevant laws. We do not tolerate any such activity. Offences like hacking,data theft, virus attacks, denial of service attacks, illegal tampering with source codesincluding ransomware attacks could be prosecuted under S.66 r/w S.43 of the IT Act.",
        "Refund Policy: The payment once made shall not be refunded. On successful payment, user will be enrolled in the course purchased until the validity period of that course.",
        "Validity period of course: Student shall enjoy access to course account on insightdelhi.com till of 14 days after the scheduled date of last class.",
        "Grievance Support: If you face any issues regarding online payment or access to classes or any other difficulty regarding the course, please feel free to contact us at011-45090051/9818333201during 10:30 AM to 5:30 PM (Monday to Saturday) or write at insightacademydelhi@gmail.com.",
        "Jurisdiction Agreement: I accept and agree to abide by all the terms and conditions mentioned above in letter and spirit. I further accept that jurisdiction of this Agreement shall be exclusively in the courts of New Delhi, India.",
        "Privacy policy: We collect your personal details such as name, father's name, email id, phone number and user’s Device ID for the use of company to connect with you in future regarding this course or any other course offered by the company. The user’s Device ID is collected at the time of login on the App to restrict access on multiple devices with the same email ID. Such details collected are not shared with any third-party organisations.",
        "Account Deletion: In case a user insists on this, the grievance is registered at support@insightdelhi.com and account is deleted within 15-working days."
      ]
    }
  ]
  return (
    <>
      <div className='Terms-background'>
        <div className='Terms-card'>
          <h1 className='Terms-heading'>Terms and Conditions</h1>
         <AllPageContent paragraphstatic={paragraphstatic} listStyleType="i"/>
        </div>
      </div>
    </>
  )
}

export default Terms;