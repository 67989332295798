import React, { useState } from 'react';
import userImg from "../../Image/aboutimg.png"
import "../Dashborad/dashboard.css";
import { FaNoteSticky } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { BsFillCloudArrowDownFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaRupeeSign } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import CivilModal from '../ReusableComponent/CIVIL/CivilModal/CivilModal';
import 'animate.css';
function Dashboardleft2({testDetail, authtoken, payamount, CourseID, BatchID}) {
    const [isOpen3, setIsOpen3] = useState(false);

    const openModal3 = () => {
        setIsOpen3(true);
    };
    
    const closeModal3 = () => {
        setIsOpen3(false);
        // navigate(`/course/${coursename}`)
    };
    const formattedNumber1 = testDetail.Price.toLocaleString('en-IN');
    const formattedNumber2 = testDetail.Price - (testDetail.Price * (testDetail.Discount/100))
    const roundedNumber = Math.ceil(formattedNumber2)
    const formattedNumber3 = roundedNumber.toLocaleString('en-IN');
    return (
        <>
            <div className='dashboardleft-background'>
                <div className='dash1'>
                    <img src={testDetail.Image} alt={"..."} className='dash2-img' />
                    {testDetail.Subscriber !==0 && <div className='d-flex justify-content-between w-100 my-2'>
                        <button type="button" className="btn btn-darkblue"><FaNoteSticky className='me-1 fs-6'/> {testDetail.QuizCount} TESTS</button>
                        <button type="button" className="btn btn-darkblue"><FaUserFriends className='me-1 fs-6'/> {testDetail.SubscriberCount + testDetail.Subscriber} SUBS.</button>
                    </div>}
                </div>
                <div className='dash2'> 
                    <div class="d-grid gap-2">
                        <Link to={testDetail.PDFUrl} className="btn d-flex align-item-center justify-content-center btn-orange fw-bold py-3" type="button"><BsFillCloudArrowDownFill className='me-1 fs-5'/> DOWNLOAD SCHEDULE</Link>
                        {testDetail.Price !== 0 ? <><button className="btn btn-orange fw-bold py-3" type="button">
                            <h6 className='fw-bold text-decoration-line-through'>FEE <FaRupeeSign className='fs-6' /> {formattedNumber1}</h6>
                            <h6 className='fw-bold'>PAY JUST <FaRupeeSign className='fs-6' /> {formattedNumber3}</h6>
                            <hr style={{margin: "0!important;"}}/>
                            <h6 className='fw-bold'>DISCOUNT {testDetail.Discount}%</h6>
                        </button>
                        <Link className="btn btn-orange fw-bold py-3" to="#" onClick={openModal3} type="button"><FaRupeeSign className='fs-6' /> {formattedNumber3} ENROLL</Link></> : <button className="btn btn-orange fw-bold py-3" type="button">ENROLLED</button> }
                        <Link to="https://api.whatsapp.com/send?phone=91981833320" className="btn d-flex align-item-center justify-content-center bg-green fw-bold py-3" type="button"><FaWhatsapp className='me-1 fs-5'/> WHATSAPP 9818333201</Link>
                    </div>
                </div>
            </div>

            <Modal show={isOpen3} centered className="animate__animated animate__fadeIn mobileyoutube">
                <CivilModal payamount={payamount} BatchID={BatchID} authtoken={authtoken}  CourseID={CourseID} closeModal={closeModal3} />
            </Modal>
        </>
    )
}

export default Dashboardleft2; 