import React from 'react';
import "../ReusableComponent/CIVIL/CivilModal/Modal.css";
import { Link } from 'react-router-dom/dist';
import { IoLogoAndroid } from "react-icons/io";
import { FaApple } from "react-icons/fa";

function ButtonModal({ closeModal, VideoId }) {
    return (
        <>
            <div class="modal-content text-center">
                <div class="modal-header mb-2">
                    <h5 class="modal-title"></h5>
                    <button type="button" onClick={closeModal} className='btn-close' aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    {/* <div class="d-grid gap-2"> */}
                    <iframe src={`https://www.youtube.com/embed/${VideoId}`} width="465" height="300" allowfullscreen="allowfullscreen"/>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default ButtonModal;