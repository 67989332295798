import React, { useState, useEffect } from 'react';
import "./Buttontab.css";

function Buttontab({ componentName, setContentToShow }) {
    // Manage All button State
    const [activeButton, setActiveButton] = useState('In News'); //Track active button
    const [buttonNames, setButtonNames] = useState([]); //Manage All button Name Array

    useEffect(() => {
        // Map component names to button labels
        const buttonLabels = {
            'currentaffairs': ['In News', 'Editorial', 'Yojana', 'Big Picture', 'Insight Radio'],
            'Blog': ['Blogs', "Today's Special"],
            'Prelims': ['PT MCQs', "START"],
            'Cuet': ['CUET', 'CUET SECTION – IA', 'CUET SECTION – II', 'CUET SECTION – III', 'RESOURCES', 'PRACTICE QUIZ']
            // Add more component names and their associated button labels as needed
        };
        const defaultButtons = buttonLabels['civilHistory']; // Default to currentaffairs if componentName doesn't match
        const buttons = buttonLabels[componentName] || defaultButtons;
        // Manage Default State of Feature and Overview Content According to ComponentName
        switch (componentName) {
            case 'currentaffairs':
                setContentToShow('In News');
                break;
            case 'Blog':
                setContentToShow('Blogs');
                break;
            case 'PT MCQs':
                setContentToShow('PT MCQs');
                break;
            default:
                break;
        }
        setButtonNames(buttons);
        setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
    }, [componentName]);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName.toLowerCase().replace(/\s+/g, ''));
    };

    return (
        <>
            {/*Button tab Section Start */}
            <div className='buttontab-background'>

                {componentName !== 'Cuet' && componentName !== 'Blog' &&
                    <div className='p-3'>
                        <div className="buttontab-Container">
                            {buttonNames.map((name, index) => (
                                <button
                                    key={index}
                                    className={`btn btn-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activebuttontab' : "bgtab-white"}`}
                                    onClick={
                                        () => {
                                            handleButtonClick(name)
                                            switch (name) {
                                                case 'In News':
                                                    // Handle In News accordingly
                                                    setContentToShow('In News');
                                                    break;
                                                case 'Editorial':
                                                    // Handle Editorial accordingly
                                                    setContentToShow('Editorial');
                                                    break;
                                                case 'Yojana':
                                                    // Handle Yojana accordingly
                                                    setContentToShow('Yojana');
                                                    break;
                                                case 'Big Picture':
                                                    // Handle Big Picture accordingly
                                                    setContentToShow('Big Picture');
                                                    break;
                                                case 'Insight Radio':
                                                    // Handle Insight Radio accordingly
                                                    setContentToShow('Insight Radio');
                                                    break;
                                                case 'Blogs':
                                                    // Handle Blogs accordingly
                                                    setContentToShow('Blogs');
                                                    break;
                                                case "Today's Special":
                                                    // Handle Today's Special accordingly
                                                    setContentToShow("Today's Special");
                                                    break;
                                                case "PT MCQs":
                                                    // Handle PT MCQs accordingly
                                                    setContentToShow("PT MCQs");
                                                    break;
                                                case "START":
                                                    // Handle START accordingly
                                                    setContentToShow("START");
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                    type="button">{name}</button>
                            ))}
                        </div>
                    </div>
                }
                {componentName === 'Cuet' &&
                    <div className='p-3'>
                        <div className="buttoncuettab-Container">
                            {buttonNames.map((name, index) => (
                                <button
                                    key={index}
                                    className={`btn btncuet-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activecuetbuttontab' : "bgcuettab-white"}`}
                                    onClick={
                                        () => {
                                            handleButtonClick(name)
                                            switch (name) {
                                                case 'In News':
                                                    // Handle In News accordingly
                                                    setContentToShow('In News');
                                                    break;
                                                case 'Editorial':
                                                    // Handle Editorial accordingly
                                                    setContentToShow('Editorial');
                                                    break;
                                                case 'Yojana':
                                                    // Handle Yojana accordingly
                                                    setContentToShow('Yojana');
                                                    break;
                                                case 'Big Picture':
                                                    // Handle Big Picture accordingly
                                                    setContentToShow('Big Picture');
                                                    break;
                                                case 'Insight Radio':
                                                    // Handle Insight Radio accordingly
                                                    setContentToShow('Insight Radio');
                                                    break;
                                                case 'Blogs':
                                                    // Handle Blogs accordingly
                                                    setContentToShow('Blogs');
                                                    break;
                                                case "Today's Special":
                                                    // Handle Today's Special accordingly
                                                    setContentToShow("Today's Special");
                                                    break;
                                                case "PT MCQs":
                                                    // Handle PT MCQs accordingly
                                                    setContentToShow("PT MCQs");
                                                    break;
                                                case "START":
                                                    // Handle START accordingly
                                                    setContentToShow("START");
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                    type="button">{name}</button>
                            ))}
                        </div>
                    </div>
                }
                {componentName === 'Blog' &&
                <div className='pt-3'>
                    <div className="buttoncuettab-Container">
                        {buttonNames.map((name, index) => (
                            <button
                                key={index}
                                className={`btn btncuet-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activeblogbuttontab' : "bgblogtab-white"}`}
                                onClick={
                                    () => {
                                        handleButtonClick(name)
                                        switch (name) {
                                            case 'In News':
                                                // Handle In News accordingly
                                                setContentToShow('In News');
                                                break;
                                            case 'Editorial':
                                                // Handle Editorial accordingly
                                                setContentToShow('Editorial');
                                                break;
                                            case 'Yojana':
                                                // Handle Yojana accordingly
                                                setContentToShow('Yojana');
                                                break;
                                            case 'Big Picture':
                                                // Handle Big Picture accordingly
                                                setContentToShow('Big Picture');
                                                break;
                                            case 'Insight Radio':
                                                // Handle Insight Radio accordingly
                                                setContentToShow('Insight Radio');
                                                break;
                                            case 'Blogs':
                                                // Handle Blogs accordingly
                                                setContentToShow('Blogs');
                                                break;
                                            case "Today's Special":
                                                // Handle Today's Special accordingly
                                                setContentToShow("Today's Special");
                                                break;
                                            case "PT MCQs":
                                                // Handle PT MCQs accordingly
                                                setContentToShow("PT MCQs");
                                                break;
                                            case "START":
                                                // Handle START accordingly
                                                setContentToShow("START");
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                }
                                type="button">{name}</button>
                        ))}
                    </div>
                    </div>
                }
            </div>
            {/*Button tab Section End */}
        </>
    )
}

export default Buttontab;