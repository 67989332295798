import React, { useState, useEffect } from 'react';
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import "./Header.css";
import logo from "../../Image/logo.png";
import Civil from '../ReusableComponent/CIVIL/Civil';
import Neet from '../ReusableComponent/NEET/Neet';
import UG from '../ReusableComponent/UGNET/UG';
import { IoIosArrowDown } from "react-icons/io";
import { RiMenu4Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { FaCircleUser } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa";
import { BiSolidPhoneOutgoing } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import userImg from "../../Image/user.png";
import { RxDashboard } from "react-icons/rx";
import { MdLogout } from "react-icons/md";
import { useAlert } from 'react-alert';
import Socialicon from '../Socialicon/Socialicon';

const Header = ({ isHeadlog, setIsLoggedIn, setAuthtoken }) => {
    const [icon, SetIcon] = useState(true);
    const [isSticky, setIsSticky] = useState(false);
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const alert = useAlert();
    const navigate = useNavigate();
    // isLoggedIn = isHeadlog
    useEffect(() => {
        // Check user authentication status on component mount
        const isAuthenticated = checkUserAuthentication(); // Your authentication logic here
        if (isAuthenticated) {
            // Fetch user data if authenticated
            const user = fetchUserData(); // Your function to fetch user data
            setUserData(user);
            // setIsLoggedIn(true);
        }
    }, []);

    const checkUserAuthentication = () => {
        // Your authentication logic here (e.g., checking localStorage, cookies, etc.)
        // Return true if user is authenticated, false otherwise
        return false; // Example: replace with actual authentication check
    };

    const fetchUserData = () => {
        // Fetch user data from server or localStorage
        // Return user object
        return { username: 'example' }; // Example: replace with actual user data
    };

    const handleLogOut = () => {
        // Handle logout functionality
        // Clear user data and set isLoggedIn to false
        // setIsLoggedIn(false);
        setIsLoggedIn(false)
        setAuthtoken(null)
        // localStorage.removeItem('authtoken');
        // Redirect to home page after logout
        navigate('/');
        alert.show('Loggout Successfully');
    };

    useEffect(() => {
        // Add a scroll event listener
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsSticky(scrollPosition > 0);
        };

        window.addEventListener('scroll', handleScroll);

        // Add a active class after clicking a list
        $(".navbar .nav-link").on("click", function () {
            $(".navbar").find(".active").removeClass("active");
            $(this).addClass("active");
        });

        // Handle hover events on .nav-item.dropdown
        $('.nav-item.dropdown').hover(
            function () {
                $(this).addClass('show');
                $(this).find('.dropdown-menu').addClass('show');
                $(this).find('.dropdown-menu').removeClass('fade-out');
            },
            function () {
                // Hide the div after a certain delay (for demonstration purposes)
                const hideDivTimeout = setTimeout(() => {
                    $(this).removeClass('show');
                    $(this).find('.dropdown-menu').removeClass('show');
                    $(this).find('.dropdown-menu').removeClass('fade-out');
                }, 100); // Adjust the delay as needed
                return () => {
                    clearTimeout(hideDivTimeout);
                };
            }
        );

        $('.navbar .nav-item .civilpadding').on('click', function () {
            $('.navbar .nav-item .dropdown-menu').removeClass('show').addClass('fade-out');
        });

        return () => {
            // Remove the scroll event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };

    }, []); // Run this effect once when the component mounts

    function disappear() {
        SetIcon(!icon)
    }

    return (
        <>
            {/* Header Section Start  */}
            <div className='header-width'>
                <div className='header-background'>
                    <div className='head_left'>
                        <Link to="/testseries/Pre-Cum-Mains-Test-Series" type="button" className="btn btn-warning fw-bold me-2 small-font btn-hover">PRE-CUM-MAINS TEST SERIES 2024</Link>
                        <Link to="/historycourse/history-optional-courses" type="button" className="btn btn-warning fw-bold small-font btn-hover">HISTORY OPTIONAL COURSES</Link>
                    </div>
                    <div className='head_right'>
                        <div className="fs-5 d-flex text-white align-items-center">
                            <Link to="#" className='list-group-item p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                <BsFillTelephoneForwardFill />
                            </Link>
                            011-45090051, 9818333201
                            {/* <Socialicon /> */}
                        </div>
                        {/* <div>
                    </div> */}
                    </div>
                </div>
            </div>
            {/* Header Section End */}

            {/* Navbar Section Start */}
            <nav className={`navbar ${isSticky ? 'sticky-top' : ''} navbar-expand-lg navbar-light navbar-background`}>
                <div className="container-fluid mobile-nav">
                    <button className="navbar-toggler" onClick={() => disappear()} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        {icon ? <RiMenu4Fill className='fs-1' /> : <RxCross2 className='fs-1' />}
                    </button>
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className='image' alt='logo' />
                    </Link>
                    <Link className="navbar-toggler navbar-user" to="/login">
                        <FaCircleUser className='fs-1' />
                    </Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav navbar-list mobile-link ms-auto mb-2 mb-lg-0">
                            <li className="nav-item me-2">
                                <Link className="nav-link  list-link fw-bold" aria-current="page" to="/home">HOME</Link>
                            </li>
                            <li className="nav-item dropdown me-2">
                                <Link className="nav-link list-link dropdown-bs-toggle fw-bold" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    CIVILS
                                    <IoIosArrowDown />
                                </Link>
                                <ul className="dropdown-menu drop-background civil-background" aria-labelledby="navbarDropdown">
                                    {/* DropDown Component Start */}
                                    <Civil />
                                    {/* DropDown Component End */}
                                </ul>
                            </li>
                            <li className="nav-item dropdown me-2">
                                <Link className="nav-link list-link dropdown-bs-toggle fw-bold" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    NEET
                                    <IoIosArrowDown />
                                </Link>
                                <ul className="dropdown-menu drop-background neet-background" aria-labelledby="navbarDropdown">
                                    {/* DropDown Component Start */}
                                    <Neet />
                                    {/* DropDown Component End */}
                                </ul>
                            </li>
                            <li className="nav-item me-2">
                                <Link className="nav-link list-link fw-bold" aria-current="page" to="/cuet">CUET</Link>
                            </li>
                            <li className="nav-item dropdown me-2">
                                <Link className="nav-link list-link dropdown-bs-toggle fw-bold" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    UGC-NEET
                                    <IoIosArrowDown />
                                </Link>
                                <ul className="dropdown-menu drop-background ugc-neet-background" aria-labelledby="navbarDropdown">
                                    {/* DropDown Component Start */}
                                    <UG />
                                    {/* DropDown Component End */}
                                </ul>
                            </li>
                            <li className="nav-item me-2">
                                <Link className="nav-link list-link fw-bold" aria-current="page" to="/daily-current-affairs/News">CURRENT AFFAIRS</Link>
                            </li>
                            <li className="nav-item me-2">
                                <Link className="nav-link list-link fw-bold" aria-current="page" to="/blogs">BLOGS</Link>
                            </li>
                        </ul>
                        <form className="d-flex">
                            {!isHeadlog ? <Link className="btn fw-bold login-background text-white" type="submit" to="/login">LOGIN</Link> :
                                <div class="dropdown">
                                    <Link className="btn fw-bold d-flex profile-user-img dropdown-bs-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" type="submit" to="/profile">
                                        <img className='profile-user phone-user' src={userImg} />
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li><Link class="dropdown-item" to="/StudentDashboard"><RxDashboard className='me-3' />Dashboard</Link></li>
                                        <li><Link class="dropdown-item" onClick={handleLogOut} type='submit' to="#"><MdLogout className='me-3' />Logout</Link></li>
                                    </ul>
                                </div>}
                        </form>
                    </div>
                </div>
            </nav>
            {/* Footer navbar Section STart */}
            {/* Navbar Show in only phone Device */}
            <div className='Foot-navbar'>
                <div className='foot-nav-container'>
                    <Link to="/" className='list-group-item'>
                        <div className='fn1'>
                            <FaHome />
                            <h6>Home</h6>
                        </div>
                    </Link>
                    <Link to="/login" className='list-group-item'>
                        <div className='fn2'>
                            <FaUserFriends />
                            <h6>Login</h6>
                        </div>
                    </Link>
                    <Link to="" className='list-group-item'>
                        <div className='fn3'>
                            <FaClipboardCheck />
                            <h6>Quiz</h6>
                        </div>
                    </Link>
                    <Link to="/contact-us" className='list-group-item'>
                        <div className='fn4'>
                            <BiSolidPhoneOutgoing />
                            <h6>Contact</h6>
                        </div>
                    </Link>
                </div>
            </div>
            {/* Footer navbar Section End */}
            {/* Navbar Section End */}
        </>
    )
}

export default Header;