import React from 'react';
import { Link } from 'react-router-dom';
import { ShimmerButton, ShimmerContentBlock, ShimmerSimpleGallery, ShimmerTitle } from 'react-shimmer-effects';

function LongCard() {
    return (
        <>
            <div className='CivilCard-background'>
                <div className="CivilCard-box">
                    <div className="text-center CivilCard-imgs">
                        <Link>
                            <ShimmerSimpleGallery className='' row={1} col={1} imageHeight={150} />
                            <div style={{ width: "180px" }}></div>
                        </Link>
                    </div>
                    <div className="currenttextsec d-flex justify-content-between">
                        <div className='current-box1'>
                            <ul className='d-flex civilcard-tags'>
                                <ShimmerButton size="sm" />
                            </ul>
                            <h6>
                                <Link>
                                    <ShimmerTitle line={2} gap={10} variant="primary" />
                                </Link>
                            </h6>
                            <p><ShimmerButton size="sm" /></p>
                            <div className="current-box2">
                                <Link><ShimmerButton size="sm" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LongCard