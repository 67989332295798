import React from 'react';
import "./CivilrightSection.css";
import SocialComponent from '../../../SocialComponent/SocialComponent';
import ImageComponent from '../../../ImageComponent/ImageComponent';

function CivilrightSection() {
    return (
        <>
            {/* Civil Right Section Start */}
                     <SocialComponent/> 
                    <ImageComponent/>
            {/* Civil Right Section End */}
        </>
    )
}

export default CivilrightSection;