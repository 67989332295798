import React from 'react'
import "./Questionsummary.css"
import Questionnumber from '../Questionnumber/Questionnumber'

function Questionsummary({setCurrentQuestionIndex, currentQuestionIndex, queslength}) {
  return (
    <>
      <div className='row d-flex justify-content-between px-3'>
        <div className='col-lg-6 bg-whites my-2 d-flex flex-column align-items-center justify-content-center py-3'>
            <span className='bg-text-whites p-3 rounded-circle w-30 d-flex justify-content-center align-items-center'>{(queslength- 1) - currentQuestionIndex}</span>
            <span>Not Visited</span>
        </div>
        <div className='col-lg-6 bg-lightorange my-2 d-flex flex-column align-items-center justify-content-center py-3'>
            <span className='bg-text-lightorange p-3 rounded-circle w-30 d-flex justify-content-center align-items-center'>1</span>
            <span>Not Answered</span>
        </div>
        <div className='col-lg-6 bg-lightgreen my-2 d-flex flex-column align-items-center justify-content-center py-3'>
            <span className='bg-text-lightgreen p-3 rounded-circle w-30 d-flex justify-content-center align-items-center'>0</span>
            <span>Answered</span>
        </div>
        <div className='col-lg-6 my-2 bg-lightpurple d-flex flex-column align-items-center justify-content-center py-3'>
            <span className='bg-text-lightpurple p-3 rounded-circle w-30 d-flex justify-content-center align-items-center'>0</span>
            <span>Marked For Review</span>
        </div>
      </div>
      <Questionnumber setCurrentQuestionIndex={setCurrentQuestionIndex} currentQuestionIndex={currentQuestionIndex}/>
    </>
  )
}

export default Questionsummary
