import React, { useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview from '../Questionpreview/Questionpreview';

function QuestionLayout() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [queslength, setqueslength] = useState([])
  // const [questionarray, setquestionarray] = useState([])
  const [clearoption, setClearOption] = useState(null)
  // console.log(queslength)

  const handleNextQuestion = () => {
    console.log(clearoption)
    if (currentQuestionIndex < queslength.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setClearOption('')
    }
  };

  const loadPreviousAnswer = () => {
    // Retrieve user data from local storage
    const userDataJSON = localStorage.getItem('userdata');
    if (userDataJSON) {
      console.log("user data is correct")
      const userData = JSON.parse(userDataJSON);
      // Check if the user answer is for the previous question
      console.log(userData)
      if (userData[currentQuestionIndex].questionindex === currentQuestionIndex) {
        setClearOption(queslength[userData[currentQuestionIndex].questionindex-1].options.indexOf(userData[currentQuestionIndex - 1].useranswer));
      } else {
        // If there is no user answer for the previous question, clear the selection
        setClearOption(null);
      }
    } else {
      // If there is no user data in local storage, clear the selection
      setClearOption(null);
    }
  };

  const handlePreviousQuestion = () => {
    loadPreviousAnswer()
     
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const clearAnswer = () => {
    // Retrieve userData array from local storage
    const userDataJSON = localStorage.getItem('userdata');
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      // Remove the user's answer for the current question
      const updatedUserData = userData.filter(data => data.questionindex !== currentQuestionIndex);
      // Update the userData array in local storage
      localStorage.setItem('userdata', JSON.stringify(updatedUserData));
      // Clear the selected option
      setClearOption(null);
    }
  };

  // Function to handle clearing the answer
  const handleClear = () => {
    clearAnswer();
  };

  return (
    <>
      <div className='container-fluid p-3'>
        <div className='row'>
          <div className='col-lg-8'>
          <Questionpreview currentQuestionIndex={currentQuestionIndex} setqueslength={setqueslength} clearoption={clearoption} setClearOption={setClearOption}/>
          <div className='p-3 d-flex justify-content-around'>
            <div>
          {currentQuestionIndex<=0 ? <></> : <button type="button" class="btn bg-Question-white btn-md me-2" onClick={handlePreviousQuestion}>Previous</button>}
          <button type="button" class="btn bg-Question-white btn-md" onClick={handleClear}>clear</button>
          <button type="button" class="btn bg-Question-white btn-md ms-2">Mark for Review</button>
          </div>
          <button type="button" class="btn bg-Question-white btn-md" onClick={handleNextQuestion}>Save & Next</button>
          </div>
          </div>
          <div className='col-lg-4 p-3 card'>
            <Questionsummary queslength={queslength} setCurrentQuestionIndex={setCurrentQuestionIndex} currentQuestionIndex={currentQuestionIndex}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionLayout