import React from 'react';
import { Link } from 'react-router-dom';
import { ShimmerButton, ShimmerContentBlock, ShimmerSimpleGallery, ShimmerText, ShimmerTitle } from 'react-shimmer-effects';

function LongCard2() {
    return (
        <>
            <div className='blogCard-background'>
                    <div className="blogCard-box">
                        <h6>
                        <Link>
                                    <ShimmerTitle line={2} gap={10} variant="primary" />
                                </Link>
                        </h6>
                        <div className='blogcard-box2'>
                            <div className="text-center BlogCard-imgs">
                            <Link>
                            <ShimmerSimpleGallery className='' row={1} col={1} imageHeight={150} />
                            <div style={{ width: "180px" }}></div>
                            <ShimmerText line={1} gap={10} />
                        </Link>
                            </div>
                            <div className="blogcardtext d-flex justify-content-between">
                                <div className='blog-box1'>
                                    <div className=''>
                                        <p className='blog-text'>
                                        <ShimmerText line={5} gap={10} />
                                        </p>
                                        <p></p>
                                        <div className="blog-box2">
                                        <Link><ShimmerButton size="sm" /></Link>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                    <ShimmerButton size="sm" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default LongCard2