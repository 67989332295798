import React, { useState } from 'react';
import CountUp from 'react-countup';
import {Waypoint} from 'react-waypoint';

function Counter({ start, end, duration }) {
    const [isVisible, setIsVisible] = useState(false);

    const onEnter = () => {
        setIsVisible(true);
    };

    const onLeave = () => {
        setIsVisible(false);
    };

    return (
        <Waypoint onEnter={onEnter} onLeave={onLeave} bottomOffset="20%" fireOnRapidScroll>
            <div>
                {isVisible && (
                    <CountUp start={start} end={end} duration={duration} />
                )}+
            </div>
        </Waypoint>
    );
}

export default Counter;
