import React from 'react';
import "../Dashborad/dashboard.css";
import { HiOutlineBookOpen } from "react-icons/hi";

function Dashboardleft({authtoken, userDtails}) {
    return (
        <>
            <div className='dashboardleft-background'>
                <div className='dash1'>
                    <img src={userDtails.ProfileImage} alt={userDtails.FirstName} className='dash1-img' />
                    {/* <h6>{authtoken}</h6> */}
                    <h6>{userDtails.FirstName}</h6>
                    <p><HiOutlineBookOpen className='me-2'/>Code:{`${userDtails.AccessCode}`}</p>
                </div>
                <div className='dash2'>
                    <div class="d-grid gap-2">
                        <button class="btn btn-subdash" type="button">SUBSCRIPTIONS</button>
                        <button class="btn btn-perdash" type="button">PERFORMANCE</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboardleft;