import React from 'react';
import UGdropdown from './UGdropdown';

const UG = () => {
    // UG Drop Down Data Section Start
    const ug = [
        {
          title: 'Home',
          links: [
            { label: 'About Us', url: '/about/about-ugc-net-exam' },
            { label: 'Pattern Of Exam', url: '/about/pattern-of-ugc-net-exam' },
            { label: 'Syllabus', url: '/about/syllabus-for-ugc-net-exam' },
            { label: 'FAQs', url: '/about/frequently-asked-questions-for-ugc-net-exam' },
            // Add more links for the 'Home' category
          ]
        },
        {
          title: 'TEST SERIES',
          links: [
            { label: 'Common Paper', url: '/common-paper' },
            { label: 'Subject-Specific', url: '/subject-specific' },
            { label: 'Integgrated Mock Tests', url: '/integgrated-mock-tests' },
            // Add more links for 'TEST SERIES' category
          ]
        },
        {
          title: 'SUCCESS MANTRA',
          links: [
            { label: `Beginner's Strategy`, url: '/beginners-strategy' },
            // Add more links for 'SUCCESS MANTRA' category
          ]
        }
      ];
      // UG Drop Down Data Section End

  return (
    <div>
      <UGdropdown ug={ug}/>
    </div>
  );
};

export default UG;
