import React from 'react';
import "./CivilSchedule.css";
import CivilScheduleContent from '../CivilScheduleContent/CivilScheduleContent';

function CivilSchedule({ testtoggleData }) {
    return (
        <div className='Civil-Schedule-background'>
            <div className="shadow p-3 mb-5 bg-body rounded">
                    <CivilScheduleContent items={testtoggleData} />
            </div>
        </div>
    )
}

export default CivilSchedule;
