import React, { useEffect, useState, useRef } from 'react';
import "../../CIVIL/CivilButton/CivilButton.css";
import userImg from "../../../../Image/user.png";
import { FaTelegramPlane } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { useAlert } from 'react-alert';
import { IoCloudUploadSharp } from "react-icons/io5";
import pdfImg from "../../../../Image/pdfphoto.png"
// import { toast } from 'react-toastify';
import axios from "axios";
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../../insightConfig/insightConfig';

function CivilPop({ isAuth, ispayment, authtoken, sectionid, iscourseid, reviewtitle, isteacher, isupload, ctype1, ctype2 }) {
  console.log(ctype1)
  console.log(ctype2)
  const [userData, setUserData] = useState([]);
  const [reviewsData, setreviewsData] = useState(null);
  const [reviewsData1, setreviewsData1] = useState(null);
  const [CommentTitle, setCommentTittle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');
  const alert = useAlert();


  // const handleFileChange = (e) => {
  //   console.log(e.target.value, e.target.name)
  //   handleChangeCallBack(e.target.name, e.target.value)
  // };

  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log("i am calling file 1")
    console.log(selectedFile)
  };
  
  const handleClick = async () => {
    // e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      fileInputRef.current.click(); // Trigger click event on file input
      formData.append("Upload/PostComment", selectedFile);
      console.log(selectedFile);

      try {
        const result = await axios.post(`${BASE_URL}/upload-files`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(result)
        // Store the result.data value in a variable
        const uploadedFilename = result.data;
       console.log(uploadedFilename)
        // Set the uploaded filename as the CommentTitle
        setCommentTittle(uploadedFilename);
        alert.show("File uploaded successfully and click a Submit Button");
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error
      }
    }
  };
  

  const convertTimestamp = (originalTimestamp) => {
    // Parse the original timestamp into a Date object
    const originalDate = new Date(originalTimestamp)
    // Convert the Date object into the desired format
    const formattedDate = originalDate.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return formattedDate;
  }

  const fetchData = async () => {
    try {
      if (ctype1) {
        const response = await fetch(`${BASE_URL}/teachersupport/${ctype1}/${authtoken}`);
        const data = await response.json();
        setreviewsData(data.data);
        console.log(data)
        console.log("Data fetched successfully");
        console.log("i am useing 2");
      }
      const response = await fetch(`${BASE_URL}/teachersupport/${ctype2}/${authtoken}`);
      const data = await response.json();
      setreviewsData1(data.data);
      console.log(data)
      console.log("Data fetched successfully");
      console.log("i am useing 2");
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Fetch data from API
    fetch(`${BASE_URL}/studentdashboard/${authtoken}`)
      .then(response => response.json())
      .then(data => {
        // Update state with the fetched data
        setUserData(data.data);
        console.log(data.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures useEffect runs only once after initial render

  const handleSubmit = async () => {
    if (!CommentTitle.trim()) {
      setError('message is required');
      return;
    }
  
    try {
      const response = await fetch(`${BASE_URL}/teachersupportp/${authtoken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ CommentTitle, ctype: ctype1 || ctype2, Title: reviewtitle, Category: reviewtitle, SectionID: sectionid, CourseID: iscourseid }),
      });
  
      if (!response.ok) {
        throw new Error('message failed');
      }
  
      // Reset form fields and state
      setCommentTittle('');
      // Show success message
      alert.show('Message sent successfully');
      fetchData();
    } catch (error) {
      // Handle error
      console.error('Error submitting message:', error);
      setError('message failed. Please check your credentials.');
      alert.show('Message failed. Please check your credentials.');
    }
  };
  

  const handledelete = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/teachersupportd/${id}/${authtoken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, userCode: authtoken }),
      });

      if (!response.ok) {
        throw new Error('message failed');
      }
      console.log("jnfbnj")
      // Reset form fields and state
      // Show login success message
      alert.show('message deleting successfully');
      fetchData();
    } catch (error) {
      alert.show('message failed. Please check your credentials.');
      setError('message failed. Please check your credentials.');
    }
  };

  useEffect(() => {
    fetchData();
    // setIsPayment(true)
  }, [])
  if (!reviewsData && !userData) {
    return <div>Loading...</div>
  }
  console.log(reviewsData)
  return (
    <>
      {isAuth && ispayment ?
        <>
          {isteacher && <div class="card bg-skyblue text-center mx-5 mt-4">
            <div className="card-body d-flex justify-content-between">
              <div className='pop-img col-1 d-flex justify-content-start'>
                <img src={userImg} alt='' className='pop-user-img' />
              </div>
              <div class="form-floating col-11">
                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" value={CommentTitle} onChange={(e) => setCommentTittle(e.target.value)} style={{ height: "200px" }}></textarea>
                <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-darkpurple px-5" onClick={handleSubmit} type="button"><FaTelegramPlane /> SUBMIT</button>
                </div>
              </div>
            </div>
          </div>}
          {isupload && <div class="card bg-skyblue text-center mx-5 mt-4">
            <div className="card-body d-flex align-items-center">
              <div className='pop-img col-1 d-flex justify-content-start'>
                <img src={userImg} alt='' className='pop-user-img' />
              </div>
              <div class="form-floating">
                <div className="d-flex">
                  <IoCloudUploadSharp className='btn-upload-icon' />
                  <input type="file" className="form-control btn-input" accept='application/pdf' required name="image" id="inputGroupFile01" ref={fileInputRef} onChange={handleFileChange} />
                  {CommentTitle ? <button className="btn btn-darkpurple px-5 py-2" onClick={handleSubmit} type="button"><FaTelegramPlane /> SUBMIT</button> :<button className="btn btn-darkpurple px-5 py-2" onClick={handleClick} type="button">Upload</button>}
                </div>
              </div>
            </div>
          </div>}
          {isteacher && <div className='reviewsData-background' >
            {reviewsData && reviewsData.map((item, index) => (
              <div className='reviewsCard mb-3' key={index}>
                <div className='reviewsImg'>
                  <img src={userImg} alt='' className='pop-user-img' />
                </div>
                <div className='reviewsInfo'>
                {userData && userData[0] && userData[0].FirstName && <h5>{`${userData[0].FirstName} | ${convertTimestamp(item.PostDate)} | ${item.Title}`}</h5>}
                  {<p>{item.CommentTitle}</p>}
                  <div className='reviewsbtn'>
                    <button type="button" className="btn btn-success btn-sm me-2"><FiEdit className='fs-5 mb-1' /> REPLY</button>
                    <button type="button" onClick={() => handledelete(item.Sn)} className="btn btn-danger btn-sm"><MdDelete className='fs-5 mb-1' /> DELETE</button>
                  </div>
                </div>
              </div>
            ))}
          </div>}
          {isupload && <div className='reviewsData-background' >
            {reviewsData1 && reviewsData1.map((item, index) => (
              <div className='reviewsCard mb-3' key={index}>
                <div className='reviewsImg'>
                  <img src={userImg} alt='' className='pop-user-img' />
                </div>
                <div className='reviewsInfo'>
                {userData && userData[0] && userData[0].FirstName && <h5>{`${userData[0].FirstName} | ${convertTimestamp(item.PostDate)} | ${item.Title}`}</h5>}
                  {<Link className='reviewslink' to={item.CommentTitle}>
                    <img src={pdfImg} alt='' />
                  </Link>}
                  <div className='reviewsbtn'>
                    <button type="button" className="btn btn-success btn-sm me-2"><FiEdit className='fs-5 mb-1' /> REPLY</button>
                    <button type="button" onClick={() => handledelete(item.Sn)} className="btn btn-danger btn-sm"><MdDelete className='fs-5 mb-1' /> DELETE</button>
                  </div>
                </div>
              </div>
            ))}
          </div>}
        </>
        :
        <div class="card bg-skyblue text-center m-2">
          <div class="card-body">
            <h5 class="card-title fs-4 fw-400">ONLY FOR ENROLLED STUDENTS</h5>
          </div>
        </div>
      }
    </>
  )
}

export default CivilPop;