import React, { useEffect, useState } from 'react';
import "../ReusableComponent/CIVIL/CivilBatchSection/CivilBatch.css";
import CivilBatch from '../ReusableComponent/CIVIL/CivilBatchSection/CivilBatch';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import Radium from "radium";
// import { BASE_URL } from '../../insightConfig/insightConfig';
// import CourseShareComponent from '../CourseShareComponent/CourseShareComponent';
import { fetchCourseData, fetchCurrentNewsData, fetchLessonData } from './api';
function DynamicCourse({ setPrevPath, isAuth, authtoken, coursepayment, coursenotpayment, ispayment, prevPath }) {
    const { coursename } = useParams();
    const [classData, setClassData] = useState(null);
    const [classData1, setClassData1] = useState(null);
    const [classData3, setClassData3] = useState(null);
    const location = useLocation();

    // Update prevPath whenever the route changes
    useEffect(() => {
        setPrevPath(location.pathname);
    }, [location.pathname, setPrevPath]);

    const formatDate = (dateString) => {
        // Create a Date object from the dateString
        const date = new Date(dateString);
        // Get the day, month, and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        // Define the month names array
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        // Format the date string
        const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;
        return formattedDate;
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchCourseData(coursename, authtoken, coursepayment, coursenotpayment);
                setClassData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [coursename, authtoken, coursepayment, coursenotpayment]);

    useEffect(() => {
        const fetchData = async () => {
            if (classData && classData.CourseID) {
                try {
                    const data = await fetchLessonData(classData.CourseID);
                    setClassData1(data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [classData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchCurrentNewsData();
                setClassData3(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    if (!classData) {
        return <div>Loading...</div>
    }
    const componentName = "civilHistory";
    const displayPrice = Math.round(classData.Price * (100 - classData.Discount) * 0.01);
    const formattedDate = formatDate(classData.Bdate);

    // const urlString = classData.SyllabusPdf;
    let correctedURL = "#";
    if (classData.SyllabusPdf) {
        const parts = classData.SyllabusPdf.split("/");
        const filename = parts[parts.length - 1];
        const encodedFilename = encodeURIComponent(filename);
        correctedURL = parts.slice(0, -1).join("/") + "/" + encodedFilename;
    }

    const data = {
        batchName: `${classData.CourseTitle} : ${classData.BatchName}`,
        date: formattedDate,
        seat: classData.Subscriber,
        lesson: classData.TopicCount,
        price1: displayPrice,
        price2: classData.Price,
        price3: classData.Discount,
        paragraph: classData.Topic,
        Image: classData.Image,
        Syllabuspdf: correctedURL,
        VideoId: classData.VideoId
    };
    const data1 = classData1;
    const data2 = classData3;
    const paragraphs = classData.Description || [];

    const courseStyle = {
        before: {
            content: '""',
            width: "100%",
            backgroundImage: `url(${classData.Image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            opacity: "0.5",
            position: "absolute",
            filter: "blur(25px)",
            zIndex: "1",
        }
    }
    return (
        <>
            <Helmet>
                <title>{classData.MetaTitle}</title>
                <meta name="description" content={`${classData.MetaDescription}`}></meta>
                <meta property="og:title" content={classData.MetaTitle} />
                <meta property="og:image" content={classData.Image} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:description" content={classData.MetaDescription} />
            </Helmet>

            {/* Civil GS Foundation Section Start */}
            <section className={`${coursename}-background dynamic-course`}>
                <section style={courseStyle.before} className='dynamic-course-before' />
                {/* Civil Batch Section Start */}
                <CivilBatch 
                    batchName={data.batchName}
                    Image={data.Image}
                    date={data.date}
                    seat={data.seat}
                    lesson={data.lesson}
                    price1={data.price1}
                    price2={data.price2}
                    price3={data.price3}
                    Syllabuspdf={data.Syllabuspdf}
                    paragraph={data.paragraph}
                    isAuth={isAuth}
                    VideoId={data.VideoId}
                    ispayment={ispayment}
                    coursename={coursename}
                    CourseID={classData.CourseID}
                    authtoken={authtoken}
                    BatchID={classData.BatchId}
                    prevPath={prevPath}
                    setClassData={setClassData}
                />
                {/* Civil Batch Section End */}
            </section>
            <CivilButton reviewtitle={classData.CourseTitle} ispayment={ispayment} coursepayment={coursepayment} sectionid={classData.CourseID} data={data1} authtoken={authtoken} iscourseid={classData.CourseID} CardData={data2} isAuth={isAuth} paragraphs={paragraphs} componentName={componentName} />
            {/* Civil GS Foundation Section End */}
        </>
    )
}

export default Radium(DynamicCourse);