import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CardsPage from '../../CardsPage/CardsPage';
import TwoRowThreeColumnLayout from '../../EffectonLoad/TwoThreecolumnLayout';
import { BASE_URL } from '../../../insightConfig/insightConfig';

function CivilsCard() {
    const civilheading = "CIVILS COURSES"
    const [testsData, setTestsData] = useState([]);
    useEffect(() => {
      // Fetch data from API
      fetch(`${BASE_URL}/ExamBody/civils`) 
          .then(response => response.json())
          .then(data => {
              // Update state with the fetched data
              setTestsData(data.data);
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
  }, []); // Empty dependency array ensures useEffect runs only once after initial render
  if(testsData.length === 0){
    return<><TwoRowThreeColumnLayout/></>
  }

    const cards = testsData
    console.log(cards)
  return (
    <>
    <Helmet>
        <title>Index</title>
      </Helmet>
      <div>
        <CardsPage cards={cards} heading={civilheading}/>
      </div>
    </>
  )
}

export default CivilsCard;