import React, { useEffect, useState } from 'react';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import "./dashboard.css";
import Dashboardleft from '../DashboardLeft/Dashboardleft';
import { fetchMyCourse, fetchMyTest, fetchNotMyCourse, fetchNotMyTest, fetchUserDetails } from '../DynamicCourse/api';
import Dashboradeffect from '../EffectonLoad/Dashboardeffect';

function Dashborad({authtoken, setcoursepayment, setcoursenotpayment, ispayment, setispayment}) {
  const componentName = "dashboard"; 
  const civilheading = ""
  const [userData, setUserData] = useState([]);
  const [mycourse, setMycourse] = useState([]);
  const [notmycourse, setNotmycourse] = useState([]);
  const [mytest, setMytest] = useState([]);
  const [notmytest, setNotmytest] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await fetchUserDetails(authtoken);
            const data1 = await fetchMyCourse(authtoken);
            const data2 = await fetchNotMyCourse(authtoken);
            const data3 = await fetchMyTest(authtoken);
            const data4 = await fetchNotMyTest(authtoken);
            setUserData(data);
            setMycourse(data1);
            setNotmycourse(data2);
            setMytest(data3);
            setNotmytest(data4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
}, [authtoken]);

if (!userData || userData.length === 0) {
  return <div><Dashboradeffect/></div>;
}

  const notmycards = notmycourse;
  const mycards = mycourse;
  const mytests = mytest;
  const notmytests = notmytest;
  const userDtails = userData[0] || [];
  const paymentdoneCourse1 = true;
  console.log(paymentdoneCourse1)
  // console.log(paymentdoneCourse1)
  // const paymentdoneCourse2 = 
  // const paymentdoneCourse3 = 
  // const paymentdoneCourse4 = 
  // if(!userDtails){
  //    return<div><Dashboradeffect/></div>
  // }
  // if(!userDtails || !notmycards || !mycards || !mytests || !notmytests){
  //    return<div><Dashboradeffect/></div>
  // }
  console.log(userDtails)
  console.log(authtoken)
  console.log(mycards)
  return (
    <>
      <div className='Dashboard-background'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-12 col-sm-12 dashboardmain-left p-3'>
              <Dashboardleft authtoken={authtoken} userDtails={userDtails}/>
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12'>
              <CivilButton componentName={componentName} setispayment={setispayment} ispayment={ispayment} paymentdoneCourse1={paymentdoneCourse1} setcoursepayment={setcoursepayment} setcoursenotpayment={setcoursenotpayment} notmydashcourse={notmycards} authtoken={authtoken} notmydashtest={notmytests} mydashtest={mytests} mydashcourse={mycards} heading={civilheading} userDtails={userDtails}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashborad;
