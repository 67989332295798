import React, { useState } from 'react'
import Buttontab from '../Buttontabs/Buttontabs'

function Prelims() {
    const [contentToShow, setContentToShow] = useState('PT MCQs');
    // const [news, setNew] = useState(null);
    const componentName = "Prelims";
  return (
    <>
      <Buttontab componentName={componentName} setContentToShow={setContentToShow}/>
      {/* Display Information Section Start */}
            {contentToShow === 'PT MCQs' && <div>this mcq</div>}
            {contentToShow === 'START' && <div>this start</div>}
    </>
  )
}

export default Prelims;