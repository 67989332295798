import React, { useEffect, useState } from 'react';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import BlogCard from './BlogCard/BlogCard';
import Pagelayout2 from '../EffectonLoad/pagelayout2';
import { BASE_URL } from '../../insightConfig/insightConfig';
import RangeCalendar from '../Calendar/RangeCalendar';

function BlogPage1({ CardData, componentName, isloading }) {
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    // Set initial filtered data to first 10 cards
    setFilteredData(CardData);
    // setDateState(handleDateinput())
  }, [CardData]);
  const handleDateinput = async (date) => {
    // setSelectedDate(date)
    console.log(date)
    const originalDate = new Date(date);
    const formattedDate = originalDate.toISOString().split('T')[0]; // Extracting only the date part

    try {
      // setisfilterapply(true);
      const response = await fetch(`${BASE_URL}/blogs/blog/${formattedDate}`);
      const data = await response.json();
      setFilteredData(data.data);
      console.log(filteredData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    <>
      <div className='Civil-section blog1-section'>
        <div className='civil-row white blog-margin-top'>
          <div className='col-lg-8 col-md-12 col-sm-12'>
            {/* Content Component Start */}
            {/* <CivilCard CardData={CardData} /> */}
            <RangeCalendar/>
            {(!isloading && CardData.length === 0) ? <Pagelayout2 /> : <BlogCard CardData={filteredData} />}
            {filteredData.length === 0 && CardData.length !== 0 && <><h3 className='fw-bold text-center p-5'>No Result Found!</h3></>}
            {/* Content Component End */}
            {/* <ButtonPage /> */}
          </div>
          {/* Civil right Section Start */}
          <div className='col-lg-4 col-md-12 col-sm-12'>
            <div className='card p-2'>
              {/* <CivilrightSection /> */}
              <CurrentAffair onDateSelect={handleDateinput} componentName={componentName} />
            </div>
          </div>
          {/* Civil right Section End */}
        </div>
      </div>
    </>
  )
}

export default BlogPage1;