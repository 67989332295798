import React from 'react';
import "./CardsPage.css";
import { Link } from 'react-router-dom';
import { BsJournals } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";

function CardsPage({ cards, heading, heading2 }) {
    return (
        <>
            {heading === 'CIVILS COURSES' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard" key={index}>
                            <Link to={`/course/${card.TitleHeading}`}>
                            <img src={card.Image} className="card-img-top" alt={card.CourseTitle} />
                            </Link>
                            <div className="card-body All-bg">
                                {card.CourseTitle ? <h6 className="fw-bold">{card.CourseTitle}</h6> : <h6 className="fw-bold fs-5">{card.Title}</h6> }
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                        {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>}
                                        {card.SubscriberCount && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.SubscriberCount}</h6>}
                                    </Link>
                                    <Link to={`/course/${card.TitleHeading}`} className="btn-start list-group-item">START</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {heading === 'TEST SERIES' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard" key={index}>
                            <Link to={`/testseries/${card.TitleHeading}`}>
                            <img src={card.Image} className="card-img-top" alt={card.CourseTitle} />
                            </Link>
                            <div className="card-body All-bg">
                                {card.CourseTitle ? <h6 className="fw-bold fs-5">{card.CourseTitle}</h6> : <h6 className="fw-bold fs-5">{card.Title}</h6> }
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                        {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>}
                                        {card.Subscriber && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.Subscriber} SUBSCRIBERS</h6>}
                                    </Link>
                                    <Link to={`/testseries/${card.TitleHeading}`} className="btn-start list-group-item">START</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {heading === '' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard AllDashboard" key={index}>
                            <Link to={`/course/${card.TitleHeading}`}>
                            <img src={card.Image} className="card-img-top" alt={card.CourseTitle} />
                            </Link>
                            <div className="card-body All-bg">
                            {card.CourseTitle ? <h6 className="fw-bold">{card.CourseTitle}</h6> : <h6 className="fw-bold">{card.Title}</h6> }
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                        {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>}
                                        {card.Subscriber && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.Subscriber} SUBSCRIBERS</h6>}
                                    </Link>
                                    <Link to={`/course/${card.TitleHeading}`} className="btn-start list-group-item">START</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {heading2 === '' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard AllDashboard" key={index}>
                            <Link to={`/testseries/${card.TitleHeading}`}>
                            <img src={card.Image} className="card-img-top" alt={card.CourseTitle} />
                            </Link>
                            <div className="card-body All-bg">
                            {card.CourseTitle ? <h6 className="fw-bold">{card.CourseTitle}</h6> : <h6 className="fw-bold">{card.Title}</h6> }
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                        {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>}
                                        {card.SubscriberCount && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.SubscriberCount} SUBSCRIBERS</h6>}
                                    </Link>
                                    <Link to={`/testseries/${card.TitleHeading}`} className="btn-start list-group-item">START</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
        </>
    )
}

export default CardsPage;