// import React from 'react';
// import "../Login/Login.css";
// import { Link } from 'react-router-dom';
 
// function Forgot() {
//     return (
//         <>
//             <div className="forgotCOntainer">
//                 <div className='loginForm forgotForm'>
//                     <div className="card login-card">
//                         <div className="card-header m-3 text-center fw-500 text-white login-heading">
//                             Enter Registered Email-id to Get Password
//                         </div>
//                         <div className="card-body">
//                             <form>
//                                 <div className="mb-3">
//                                     <label for="exampleInputEmail1" className="form-label">Email address</label>
//                                     <input type="email" className="form-control" id="exampleInputEmail1" placeholder='Your Email-ID' aria-describedby="emailHelp" />
//                                 </div>
//                                 <div class="d-grid gap-2">
//                                     <button class="btn btn-primary login-btn py-2 border-0" type="button">SUBMIT</button>
//                                 </div>
//                             </form>
//                         </div>
//                         <div className='m-3 login-bottom'>
//                             <div className="card-footer py-3 d-flex justify-content-center login-footer">
//                                 Already Member..? <Link className='list-group-item px-1' to="/login">Login</Link>
//                             </div>
//                             <div className="card-footer py-3 d-flex justify-content-center login-footer">
//                                 New Here? <Link className='list-group-item px-1' to="/Registration">Sign Up</Link>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Forgot;

import React, { useState } from 'react';
import "../Login/Login.css";
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

function Forgot() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const alert = useAlert();
    const handleSubmit = async () => {
        try {
            const response = await fetch('http://68.178.172.24:8001/Forget', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (!response.ok) {
                throw new Error('Failed to send reset password instructions');
            }
            // Reset form fields or show success message
            
            // If registration is successful, reset form fields and show success message
            setEmail('');
            navigate('/login');
            // alert("Hello, this is an alert!");
            alert.show('Forget Password Successfully');
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <>
            <div className="forgotCOntainer">
                <div className='loginForm forgotForm'>
                    <div className="card login-card">
                        <div className="card-header m-3 text-center fw-500 text-white login-heading">
                            Enter Registered Email-id to Get Password
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder='Your Email-ID' aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary login-btn py-2 border-0" type="button" onClick={handleSubmit}>SUBMIT</button>
                                </div>
                            </form>
                        </div>
                        <div className='m-3 login-bottom'>
                            <div className="card-footer py-3 d-flex justify-content-center login-footer">
                                Already Member..? <Link className='list-group-item px-1' to="/login">Login</Link>
                            </div>
                            <div className="card-footer py-3 d-flex justify-content-center login-footer">
                                New Here? <Link className='list-group-item px-1' to="/Registration">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot;
