import React, { useState, useEffect } from 'react';
import "./Questionpreview.css";

function Questionpreview({ currentQuestionIndex, setqueslength, clearoption, setClearOption }) {
  const QuestionArray = [
    {
      "question": "What are the potential impacts of climate change on biodiversity?",
      "options": ["Habitat loss", "Species extinction", "Disruption of ecosystems", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does artificial intelligence impact job markets?",
      "options": ["Creates new job opportunities", "Automates repetitive tasks", "Increases productivity", "All of the above"],
      "answer": " All of the above"
    },
    {
      "question": "What are the key factors influencing consumer behavior in the digital age?",
      "options": ["Social media influence", "Online reviews", "Personalization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does globalization affect cultural identity?",
      "options": ["Promotes cultural exchange", "Leads to cultural homogenization", "Creates cultural hybridization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the ethical implications of genetic engineering in humans?",
      "options": ["Designer babies", "Genetic discrimination", "Informed consent", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the potential impacts of climate change on biodiversity?",
      "options": ["Habitat loss", "Species extinction", "Disruption of ecosystems", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does artificial intelligence impact job markets?",
      "options": ["Creates new job opportunities", "Automates repetitive tasks", "Increases productivity", "All of the above"],
      "answer": " All of the above"
    },
    {
      "question": "What are the key factors influencing consumer behavior in the digital age?",
      "options": ["Social media influence", "Online reviews", "Personalization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does globalization affect cultural identity?",
      "options": ["Promotes cultural exchange", "Leads to cultural homogenization", "Creates cultural hybridization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the ethical implications of genetic engineering in humans?",
      "options": ["Designer babies", "Genetic discrimination", "Informed consent", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the potential impacts of climate change on biodiversity?",
      "options": ["Habitat loss", "Species extinction", "Disruption of ecosystems", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does artificial intelligence impact job markets?",
      "options": ["Creates new job opportunities", "Automates repetitive tasks", "Increases productivity", "All of the above"],
      "answer": " All of the above"
    },
    {
      "question": "What are the key factors influencing consumer behavior in the digital age?",
      "options": ["Social media influence", "Online reviews", "Personalization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does globalization affect cultural identity?",
      "options": ["Promotes cultural exchange", "Leads to cultural homogenization", "Creates cultural hybridization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the ethical implications of genetic engineering in humans?",
      "options": ["Designer babies", "Genetic discrimination", "Informed consent", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the potential impacts of climate change on biodiversity?",
      "options": ["Habitat loss", "Species extinction", "Disruption of ecosystems", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does artificial intelligence impact job markets?",
      "options": ["Creates new job opportunities", "Automates repetitive tasks", "Increases productivity", "All of the above"],
      "answer": " All of the above"
    },
    {
      "question": "What are the key factors influencing consumer behavior in the digital age?",
      "options": ["Social media influence", "Online reviews", "Personalization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does globalization affect cultural identity?",
      "options": ["Promotes cultural exchange", "Leads to cultural homogenization", "Creates cultural hybridization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the ethical implications of genetic engineering in humans?",
      "options": ["Designer babies", "Genetic discrimination", "Informed consent", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the potential impacts of climate change on biodiversity?",
      "options": ["Habitat loss", "Species extinction", "Disruption of ecosystems", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does artificial intelligence impact job markets?",
      "options": ["Creates new job opportunities", "Automates repetitive tasks", "Increases productivity", "All of the above"],
      "answer": " All of the above"
    },
    {
      "question": "What are the key factors influencing consumer behavior in the digital age?",
      "options": ["Social media influence", "Online reviews", "Personalization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "How does globalization affect cultural identity?",
      "options": ["Promotes cultural exchange", "Leads to cultural homogenization", "Creates cultural hybridization", "All of the above"],
      "answer": "All of the above"
    },
    {
      "question": "What are the ethical implications of genetic engineering in humans?",
      "options": ["Designer babies", "Genetic discrimination", "Informed consent", "All of the above"],
      "answer": "All of the above"
    }
  ];

  const [selectedOption, setSelectedOption] = useState(clearoption);
    console.log(selectedOption)
    useEffect(() => {
      setqueslength(QuestionArray);
    }, []);
  
    useEffect(() => {
      setSelectedOption(clearoption)
      if (clearoption === '') {
        console.log("time out")
        const timeoutId = setTimeout(() => {
          setSelectedOption(null);
        }, 5);
        return () => clearTimeout(timeoutId);
      }
    }, [clearoption]);
  
  
  const saveUserAnswer = (currentQuestionIndex, answer, answerindex) => {
    // Retrieve existing user data array from local storage
    const existingUserDataJSON = localStorage.getItem('userdata');
    let existingUserData = [];
    if (existingUserDataJSON) {
      existingUserData = JSON.parse(existingUserDataJSON);
    }

    // setTimeout(()=>{
    //   if(clearoption === null){
    //     setSelectedOption(null)
    //   }
    // },[2000])
    
    // Update or append the user's answer for the current question
    const updatedUserData = existingUserData.map(userData => {
      if (userData.questionindex === currentQuestionIndex) {
        return { ...userData, useranswer: answer, useranswerindex: answerindex };
      }
      return userData;
    });

    // If no user data exists for the current question, append it
    if (!updatedUserData.some(userData => userData.questionindex === currentQuestionIndex)) {
      updatedUserData.push({ questionindex: currentQuestionIndex, useranswer: answer, useranswerindex: answerindex  });
    }

    // Store the updated user data array back into local storage
    localStorage.setItem('userdata', JSON.stringify(updatedUserData));
  };


  const handleOptionChange = (index, value, currentQuestionIndex) => {
    setSelectedOption(index);
    setClearOption(selectedOption)
    const updateoption = value
    saveUserAnswer(currentQuestionIndex, updateoption, index);
  };

  

  return (
    <>
      <div className='card p-5 py-4'>
        <p><b>{currentQuestionIndex + 1}. {QuestionArray[currentQuestionIndex].question}</b></p>
        <ul className='answer-list'>
          {QuestionArray[currentQuestionIndex].options.map((option, optionIndex) => (
            <li key={optionIndex} className={selectedOption === optionIndex ? 'selected' : ''}>
              <input
                type="radio"
                className='me-2'
                name={`question_${currentQuestionIndex}`}
                id={`option_${currentQuestionIndex}_${optionIndex}`}
                checked={selectedOption === optionIndex}
                onChange={() => handleOptionChange(optionIndex, option, currentQuestionIndex)}
              />
              <label htmlFor={`option_${currentQuestionIndex}_${optionIndex}`}>
                <b>({String.fromCharCode(65 + optionIndex)}) {option}</b>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Questionpreview;
