import React from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import Uglogo from "../../../Image/dropdown.png";
import { Link } from 'react-router-dom';

const DropdownCategory = ({ title, links }) => {
    return (
        //UG Drop Down List Section Start 
        <>
        <div className='col-lg-3 col-md-4 col-sm-4 civil-tablet-menu1'>
            <h2 className='fs-6 fw-bold drops'>{title}</h2>
            <ul className='ps-0'>
                {links.map((link, index) => (
                    <React.Fragment key={index}>
                        <li className='list-group-item civilpadding'>
                            <Link className='list-group-item' to={link.url}>
                                <IoIosArrowRoundForward className='fs-4 pb-1' /> {link.label}
                            </Link>
                        </li>
                        <li className='list-group-item'><hr style={{margin: "0.2vmax"}} className="dropdown-divider" /></li>
                    </React.Fragment>
                ))}
            </ul>
        </div>
        <div className='col-lg-3 civil-tablet-menu2'>
            <h2 className='fs-6 fw-bold drops'>{title}</h2>
            <ul className='ps-0'>
                {links.map((link, index) => (
                    <React.Fragment key={index}>
                        <li className='list-group-item civilpadding'>
                            <Link className='list-group-item' to={link.url}>
                                <IoIosArrowRoundForward className='fs-4 pb-1' /> {link.label}
                            </Link>
                        </li>
                        <li className='list-group-item'><hr style={{margin: "0.2vmax"}} className="dropdown-divider" /></li>
                    </React.Fragment>
                ))}
            </ul>
        </div>
        </>
        //UG Drop Down List Section End 
    );
};

const UGdropdown = ({ ug }) => {
    return (
        //UG Drop Down Section Start
        <div className='Container'>
            <div className='row background-submenu'>
                <div className='col-lg-3'>
                    <img src={Uglogo} alt='' className='drop-image' />
                </div>
                {ug.map((category, index) => (
                    <DropdownCategory key={index} title={category.title} links={category.links} />
                ))}
            </div>
        </div>
        //UG Drop Down Section End
    );
};

export default UGdropdown;
