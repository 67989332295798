import React, { useEffect } from 'react'
import CardsPage from '../CardsPage/CardsPage';

function Moretest({cards, heading2}) {
  // useEffect(()=>{
  //   {cards.map((card) => (
  //     setIsPayment(card.paymentdonecourse)
  //   ))}
  // },[])
  return (
    <div>
      <CardsPage cards={cards} heading2={heading2}/>
    </div>
  )
}

export default Moretest;