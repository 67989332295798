import React from 'react';
import { ShimmerButton, ShimmerPostItem, ShimmerSectionHeader, ShimmerSimpleGallery, ShimmerText, ShimmerTitle } from "react-shimmer-effects";
function CardEffect() {
  return (
    <>
      <div className='p-2'>
        <div className='card p-2 Allcard'>
          {/* <ShimmerPostItem card title cta /> */}
          <ShimmerSimpleGallery row={1} col={1} imageHeight={250} />
          <div className='card-body'>
            <ShimmerTitle line={1} gap={10} variant="primary" />
            <ShimmerText line={1} gap={10} />
            <div className='d-flex justify-content-between align-items-center'>
              <ShimmerButton size="md" />
              <ShimmerButton size="md" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CardEffect;