import React from 'react';
import craft from "../../Image/carft.png";
import "./Expert.css";

function Expert() {
  return (
    <>
    {/* Expert Section Start */}
     <div className='ExpertContainer'>
        <div className='ExpertBox'>
                <div className='Expert_left'>
                    <h1 className='fw-bold text-white pt-4'>Crafted by Experts</h1>
                    <p className='fs-4 text-white'>
                    1500+ students have cracked UPSC with our test-series. These test-series have been specially prepared by faculty with decades of experience. These are just what you need to realise your dream!
                    </p>
                </div>
                <div className='Expert_right'>
                    <img src={craft} alt='expert'/>
                </div>
        </div>
     </div> 
     {/* Expert Section End */}
    </>
  )
}

export default Expert;