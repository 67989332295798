import React from 'react'
import LongCard from './longCard';

function Pagelayout() {
    return (
        <>
                <LongCard/>
                <LongCard/>
                <LongCard/>
                <LongCard/>
                <LongCard/>
                <LongCard/>
                <LongCard/>
                <LongCard/>
                <LongCard/>
                <LongCard/>
        </>
    )
}

export default Pagelayout;