import React, { useState } from 'react';
import "./Setting.css";
import { FaUser } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import { IoCloudUpload } from "react-icons/io5";
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Setting({ userDtails, authtoken }) {
    const alert = useAlert();
    const navigate = useNavigate();
    const [FirstName, setFirstName] = useState(userDtails.FirstName);
    const [MobileNo, setMobileNo] = useState(userDtails.MobileNo);
    const [Address, setAddress] = useState(userDtails.Address);
    const [date, setDate] = useState(userDtails.Date);
    const [Month, setMonth] = useState(userDtails.Month);
    const [Year, setYear] = useState(userDtails.Year);
    const [error, setError] = useState('');

    // Function to format the date of birth
    const formatDateOfBirth = () => {
        const formattedDate = String(date).padStart(2, '0');
        const formattedMonth = String(Month + 1).padStart(2, '0'); // Adding 1 to month because months are zero-indexed
        return `${Year}-${formattedMonth}-${formattedDate}`;
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();

        if (!FirstName.trim() || !MobileNo.trim() || !Address.trim()) {
            setError('Please fill in all fields');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/updatestudent/${authtoken}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ FirstName , MobileNo, Address, "Date": date, Month, Year }),
            });

            if (!response.ok) {
                throw new Error('Update User failed');
            }
            // Reset form fields and state
            setFirstName(userDtails.FirstName)
            setMobileNo(userDtails.MobileNo);
            setAddress(userDtails.Address);
            setDate(userDtails.Date);
            setMonth(userDtails.Month);
            setYear(userDtails.Year);
            // Show login success message
            alert.show('Update User in successfully');
            navigate("/login");
        } catch (error) {
            setError('Please check your credentials.');
            alert.show('Please check your credentials.')
        }
    };

    return (
        <>
            <div className='Setting-background'>
                <form onSubmit={handleUpdateUser}>
                    <div className='row mb-3'>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><FaUser className='me-2' />Full Name</label>
                            <input type="text" className="form-control" value={FirstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><IoCalendarOutline className='me-2' /> Date Of Birth</label>
                            <input type="date" className="form-control" value={formatDateOfBirth()} onChange={(e) => { 
                                const selectedDate = new Date(e.target.value);
                                setDate(selectedDate.getDate());
                                setMonth(selectedDate.getMonth());
                                setYear(selectedDate.getFullYear());
                            }} />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><MdEmail className='me-2' />Email</label>
                            <input type="email" className="form-control" value={userDtails.EmailId} readOnly />
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><FaPhoneAlt className='me-2' />Mobile</label>
                            <input type="number" className="form-control" id="exampleInputPassword1" value={MobileNo} onChange={(e) => setMobileNo(e.target.value)} />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div class="col-lg-12">
                            <label class="form-label fw-bold"><FaLocationDot className='me-2' />Permanent Address</label>
                            <textarea class="form-control" rows="3" value={Address} onChange={(e) => setAddress(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><FaUserCheck className='me-2' />Academic Qualification</label>
                            <IoCloudUpload className='upload-icon1' /><input type="file" className="" />
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label fw-bold"><FaUserCheck className='me-2' />Address & ID Proof</label>
                            <IoCloudUpload className='upload-icon2' /><input type="file" className="" />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <button type="submit" className="btn btn-save">Save</button>
                    </div>
                </form>
                {/* Error message if login fails */}
                {error && <p>{error}</p>}
            </div>
        </>
    )
}

export default Setting;