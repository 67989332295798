import React, { useState } from 'react';
import "./Calendar.css";
import { FaRegRectangleList } from "react-icons/fa6";
import { BsFillCalendarWeekFill } from "react-icons/bs";

function RangeCalendar() {
    // console.log(selectedDates)
    const selectarray = ["NEWS", "EDITORIAL", "RADIO", "BIG-PICTURE", "YOJANA", "HISTORY", "POLITY", "ENVIRONMENT", "GEOGRAPHY", "SCI AND TECH", "ECONOMY", "INTERNATIONAL RELATIONS", "SOCIETY", "INTERNAL SECURITY", "GOVERNANCE", "DISASTER MANAGEMENT", "ART CULUTURE", "ETHICS"];
    const [startDate, SetStartDate] = useState("2023-01-31")
    const [endDate, SetEndDate] = useState(()=>{
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    })
    console.log(endDate) 

    // const [selectedDate, setSelectedDate] = useState('');
    // console.log(selectedDate)
    // const [selectedTag, setSelectedTag] = useState(null);

    // const handleTagChange = (event) => {
    //     const tag = event.target.value;
    //     setSelectedTag(tag);
    //     console.log(tag)
    //     onSearch(tag); // Pass selected tag to parent component
    // };

    // const handleDateChange = (event) => {
    //     const date = event.target.value;
    //     setSelectedDate(date);
    //     onDateSelect(date); // Pass selected date to parent component
    // };

    // const dropdownOptions = selectarray.map((option, index) => (
    //     <option key={index}>
    //       {option}
    //     </option>
    // ));

    return (
        <>
            <div className='bg-input-padding pe-3'>
                <div className='card C-background m-0 mb-3 p-3 py-1'>
                    <div className='row my-2 d-flex align-items-center'>
                        <div className='col-lg-5'>
                            {/* Start Date: */}
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text Purple-background pb-0" id="addon-wrapping"><h6>Start Date:</h6></span>
                                {/* <span className="input-group-text Purple-background" id="addon-wrapping"><BsFillCalendarWeekFill className='p-1' /> </span> */}
                                {/* {sel<input 
                                // {selectedDates ? <input 
                                    type="date" 
                                    className="form-control" 
                                    placeholder="MM/DD/YYYY" 
                                    aria-label="Username" 
                                    Placeholder="Select a date" 
                                    aria-describedby="addon-wrapping"
                                    // value={selectedDates}
                                    // onChange={handleDateClick}
                                /> :  */}
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="MM/DD/YYYY"
                                    aria-label="Start Date"
                                    Placeholder="Select a date"
                                    aria-describedby="addon-wrapping"
                                value={startDate}
                                // onChange={handleDateChange}
                                />
                                {/* } */}
                            </div>
                        </div>
                        <div className='my-2 col-lg-5'>
                            {/* End Date: */}
                            <div className="input-group flex-nowrap">
                                {/* <span className="input-group-text Purple-background" id="addon-wrapping"><BsFillCalendarWeekFill className='p-1' /></span> */}
                                <span className="input-group-text Purple-background pb-0" id="addon-wrapping"><h6>End Date:</h6></span>
                                {/* {selectedDates ? <input 
                                    type="date" 
                                    className="form-control" 
                                    placeholder="MM/DD/YYYY" 
                                    aria-label="Username" 
                                    Placeholder="Select a date" 
                                    aria-describedby="addon-wrapping"
                                    value={selectedDates}
                                    onChange={handleDateClick}
                                /> :  */}
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="MM/DD/YYYY"
                                    aria-label="End Date"
                                    Placeholder="Select a date"
                                    aria-describedby="addon-wrapping"
                                value={endDate}
                                // onChange={SetEndDate}
                                />
                                {/* } */}
                            </div>
                        </div>
                        <div className='col-lg-2'>
                        <span className="input-group-text d-flex justify-content-center Purple-background pb-0" id="addon-wrapping"><h6>Apply</h6></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RangeCalendar