import React from 'react';
import "./QuestionNumber.css";

function QuestionNumber({ setCurrentQuestionIndex, currentQuestionIndex }) {
  const numbers = Array.from({ length: 100 }, (_, index) => index + 1);

  const handleNumberClick = (number) => {
    setCurrentQuestionIndex(number - 1);
  };

  return (
    <div className='text-center'>
      <ul className='number-list'>
        {numbers.map((number) => (
          <li key={number} className={currentQuestionIndex === number - 1 ? 'numberActive' : ''} onClick={() => handleNumberClick(number)}>{number}</li>
        ))}
      </ul>
      <button type="button" className="btn bg-Question-orange btn-md">FINISH</button>
    </div>
  );
}

export default QuestionNumber;

