// import React, { useCallback, useState } from 'react';
// import "./Modal.css"
// import { Link } from 'react-router-dom';
// import useRazorpay from "react-razorpay";
// import logo1 from "../../../../Image/aboutimg.png";
// import { BASE_URL } from '../../../../insightConfig/insightConfig';

// function CivilModal({ closeModal, payamount, CourseID, authtoken }) {
//   const [userpay , setUserpay] = useState(null)
//   const [Razorpay] = useRazorpay();
//   console.log(payamount)
//   console.log(authtoken)

//   const handleUserDetails = useCallback(() => {
//     // Make an API call to fetch user details and CourseID after successful payment
//     fetch(`${BASE_URL}/studentdashboard/${authtoken}`)
//     .then(response => response.json())
//     .then(data => {
//         // Update state with the fetched data
//         setUserpay(data.data);
//         console.log(data.data)
//     })
//       .catch(error => {
//         console.error('Error fetching user details and CourseID:', error);
//       });
//   }, []);



//   const params = {
//     CourseID: `${CourseID}`,
//     quantity: 1,
//     customer: {
//       name: `${userpay.FirstName || ''}`,
//       email: `${authtoken}`,
//       phone: `${userpay.MobileNo}`,
//       address: `${userpay.Address}`,
//     }
//   };

//   function createOrder(params) {
//     // Simulate asynchronous operation (e.g., sending a request to the server)
//     return new Promise((resolve, reject) => {
//       // Assuming the server responds with a successful order creation
//       setTimeout(() => {
//         const order = {
//           // id: '123', // Dummy order ID
//           // Add any other properties required by Razorpay
//           // These properties would typically come from your backend server
//         };
//         console.log(order)
//         resolve(order);
//       }, 1000); // Simulating 1 second delay
//     });
//   }

//   const handlePayment = useCallback(() => {
//     timego()
//     createOrder(params).then(order => {
//       const options = {
//         key: "rzp_live_iSf1U415uJUZFH",
//         // amount: `100`,
//         amount: `${payamount * 100}`,
//         currency: "INR",
//         name: "Insight Edutech Pvt...",
//         description: "Test Transaction",
//         image: logo1,
//         order_id: order.id,
//         handler: (res) => {
//           console.log(res);
//           // handlePaymentSuccess()
//         },
//         prefill: {
//           name: "Insight Delhi",
//           email: "SBALIYANINDIA@GMAIL.COM",
//           contact: "9871280355",
//         },
//         notes: {
//           address: "Razorpay Corporate Office",
//         },
//         theme: {
//           color: "#005bf2",
//         },
//       };

//       const rzpay = new Razorpay(options);
//       // closeModal(); // Close modal after initiating payment
//       rzpay.open();
//     }).catch(error => {
//       console.error("Error creating order:", error); 

//     });
//   }, [Razorpay, params]);

//   const timego = () => {
//     setTimeout(() => {
//       closeModal()
//     }, 500)
//   }


//   return (
//     <>
//       <div className="modal-content text-center p-3">
//         <div className="modal-header">
//           <h5 className="modal-title">Terms & Conditions</h5>
//           <button type="button" onClick={closeModal} className='btn-close' aria-label="Close"></button>
//         </div>
//         <div className="modal-body">
//           <p>1. Details of a Student: Every student subscribing to any course has to fill/upload the required details on his/her 'Profile' page such as a passport size photo, ID Proof, Address proof, etc. in compliance with the KYC (Know your Customer) requirements mandated by the management of the institute and the regulatory authorities.</p>
//           {/* <p>2. Cyber-crime as per IT Act: Sharing your access, recording, downloading, or trying to sell or distribute videos and notes is a legally punishable offense under relevant IPC sections, IT Act, and other relevant laws. We do not tolerate any such activity. Offenses like hacking, data theft, virus attacks, denial of service attacks, illegal tampering with source codes including ransomware attacks could be prosecuted under S.66 r/w S.43 of the IT Act.</p> */}
//           <Link to="/terms-and-conditions" className='list-group-item fw-bold py-2 text-orange'>Read More...</Link>
//         </div>
//         <div className="d-grid gap-2">
//           {/* <button type="button" onClick={handlePayment} className="btn btn-primary py-3 my-1 fw-bold bg-pureorange proceed-btn">Proceed</button> */}
//           <button type="button" onClick={handleUserDetails} className="btn btn-primary py-3 my-1 fw-bold bg-pureorange proceed-btn">Proceed</button>
//         </div>
//       </div>
//     </>
//   )
// }

// export default CivilModal;


// import React, { useCallback, useState, useEffect } from 'react';
// import "./Modal.css"
// import { Link } from 'react-router-dom';
// import useRazorpay from "react-razorpay";
// import logo1 from "../../../../Image/aboutimg.png";
// import { BASE_URL } from '../../../../insightConfig/insightConfig';

// function CivilModal({ closeModal, payamount, CourseID, authtoken }) {
//   const [userpay , setUserpay] = useState('');
//   const [Razorpay] = useRazorpay();
//   console.log(payamount);
//   console.log(authtoken);

//   useEffect(() => {
//     const fetchUserDetails = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}/studentdashboard/${authtoken}`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch user details');
//         }
//         const data = await response.json();
//         setUserpay(data.data);
//         // console.log(data.data);
//       } catch (error) {
//         console.error('Error fetching user details:', error);
//       }
//     };

//     fetchUserDetails();
//   }, [authtoken]);

//   if (!userpay) {
//     timego()
//     console.error('User details are not available yet');
//     return;
//   }

//   const customer = {
//     name: `${userpay.FirstName || ''}`,
//     email: `${authtoken}`,
//     phone: `${userpay.MobileNo}`,
//     address: `${userpay.Address}`,
//   }

//   const handlePayment = useCallback(() => {
//     const params = {
//       CourseID: `${CourseID}`,
//       quantity: 1,
//       // customer: {
//       //   name: `${userpay.FirstName || ''}`,
//       //   email: `${authtoken}`,
//       //   phone: `${userpay.MobileNo}`,
//       //   address: `${userpay.Address}`,
//       // }
//       customer: customer
//     };

//     console.log(params);
//     timego()
//     createOrder(params).then(order => {
//       const options = {
//         key: "rzp_live_iSf1U415uJUZFH",
//         amount: `${payamount * 100}`,
//         currency: "INR",
//         name: "Insight Edutech Pvt...",
//         description: "Test Transaction",
//         image: logo1,
//         order_id: order.id,
//         handler: (res) => {
//           console.log(res);
//           // handlePaymentSuccess()
//         },
//         prefill: {
//           name: "Insight Delhi",
//           email: "SBALIYANINDIA@GMAIL.COM",
//           contact: "9871280355",
//         },
//         notes: {
//           address: "Razorpay Corporate Office",
//         },
//         theme: {
//           color: "#005bf2",
//         },
//       };

//       const rzpay = new Razorpay(options);
//       rzpay.open();
//     }).catch(error => {
//       console.error("Error creating order:", error); 
//     });
//   }, [Razorpay, authtoken, CourseID, payamount, userpay]);

//   const createOrder = (params) => {
//     // Simulate asynchronous operation (e.g., sending a request to the server)
//     return new Promise((resolve, reject) => {
//       // Assuming the server responds with a successful order creation
//       setTimeout(() => {
//         const order = {
//           // id: '123', // Dummy order ID
//           // Add any other properties required by Razorpay
//           // These properties would typically come from your backend server
//         };
//         console.log(order);
//         resolve(order);
//       }, 1000); // Simulating 1 second delay
//     });
//   };

//   const timego = () => {
//     setTimeout(() => {
//       closeModal();
//     }, 500);
//   };

//   return (
//     <>
//       <div className="modal-content text-center p-3">
//         <div className="modal-header">
//           <h5 className="modal-title">Terms & Conditions</h5>
//           <button type="button" onClick={closeModal} className='btn-close' aria-label="Close"></button>
//         </div>
//         <div className="modal-body">
//           <p>1. Details of a Student: Every student subscribing to any course has to fill/upload the required details on his/her 'Profile' page such as a passport size photo, ID Proof, Address proof, etc. in compliance with the KYC (Know your Customer) requirements mandated by the management of the institute and the regulatory authorities.</p>
//           <Link to="/terms-and-conditions" className='list-group-item fw-bold py-2 text-orange'>Read More...</Link>
//         </div>
//         <div className="d-grid gap-2">
//           <button type="button" onClick={handlePayment} className="btn btn-primary py-3 my-1 fw-bold bg-pureorange proceed-btn">Proceed</button>
//         </div>
//       </div>
//     </>
//   );
// }

// export default CivilModal;

import React, { useCallback, useState, useEffect } from 'react';
import "./Modal.css"
import { Link, useNavigate } from 'react-router-dom';
import useRazorpay from "react-razorpay";
import logo1 from "../../../../Image/aboutimg.png";
import { BASE_URL } from '../../../../insightConfig/insightConfig';

function CivilModal({ closeModal, payamount, CourseID, BatchID, authtoken, prevPath, }) {
  const [userpay, setUserpay] = useState('');
  const [userDataFetched, setUserDataFetched] = useState(false);
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  console.log(payamount);
  console.log(authtoken);
  console.log(BatchID)
  useEffect(() => {
    if (!authtoken) return;
    fetch(`${BASE_URL}/studentdashboard/${authtoken}`)
      .then(response => response.json())
      .then(data => {
        // Update state with the fetched data
        setUserpay(data.data);
        setUserDataFetched(true);
        console.log(data.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [authtoken]);

  const handlePaymentSuccess = useCallback(({ courseid, email, batchid, amount, transactionid, status }) => {
    fetch(`${BASE_URL}/payorder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authtoken}`
      },
      body: JSON.stringify({ courseid, email, batchid, amount, transactionid, status })
    })
      .then(response => response.json())
      .then(data => {
        // Handle the received user data
        console.log(data);
        // timego()
        navigate("/StudentDashboard")
      })
      .catch(error => {
        console.error('Error fetching user details and payment information:', error);
      });
  }, [payamount, authtoken, navigate, prevPath]);



  const handlePayment = useCallback(() => {
    // if (!userDataFetched) {
    //   timego();
    //   console.error('User details are not available yet');
    //   return;
    // }

    const userDetail = userpay[0]

    const params = {
      courseid: `${CourseID}`,
      batchid: `${BatchID}`,
      email: `${authtoken}`,
      quantity: 1,
      customer: {
        name: userDetail?.FirstName || '',
        phone: userDetail?.MobileNo || '',
        address: userDetail?.Address || '',
      },
      // customer: userpay[0],

    };

    console.log(params);
    timego();
    createOrder(params).then(order => {
      const options = {
        key: "rzp_live_iSf1U415uJUZFH",
        // amount: `${payamount * 100}`,
        amount: `100`,
        currency: "INR",
        name: "Insight Edutech Pvt...",
        description: "Test Transaction",
        image: logo1,
        order_id: order.id,
        handler: (res) => {
          console.log(res)
          // console.log(res.razorpay_payment_id)
          console.log(res.status_code)
          console.log(params);
          handlePaymentSuccess({ courseid: params.courseid, email: params.email, batchid: params.batchid, amount: payamount, transactionid: res.razorpay_payment_id, status: 200 })
        },
        prefill: {
          name: userDetail?.FirstName || '',
          email: authtoken,
          contact: userDetail?.MobileNo || '',
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#005bf2",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    }).catch(error => {
      console.error("Error creating order:", error);
    });
  }, [Razorpay, authtoken, CourseID, payamount, userpay]);

  const createOrder = (params) => {
    // Simulate asynchronous operation (e.g., sending a request to the server)
    return new Promise((resolve, reject) => {
      // Assuming the server responds with a successful order creation
      setTimeout(() => {
        const order = {
          // id: '123', // Dummy order ID
          // Add any other properties required by Razorpay
          // These properties would typically come from your backend server
        };
        console.log(order);
        resolve(order);
      }, 1000); // Simulating 1 second delay
    });
  };

  const timego = () => {
    setTimeout(() => {
      closeModal();
    }, 500);
  };

  return (
    <>
      <div className="modal-content text-center p-3">
        <div className="modal-header">
          <h5 className="modal-title">Terms & Conditions</h5>
          <button type="button" onClick={closeModal} className='btn-close' aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <p>1. Details of a Student: Every student subscribing to any course has to fill/upload the required details on his/her 'Profile' page such as a passport size photo, ID Proof, Address proof, etc. in compliance with the KYC (Know your Customer) requirements mandated by the management of the institute and the regulatory authorities.</p>
          <Link to="/terms-and-conditions" className='list-group-item fw-bold py-2 text-orange'>Read More...</Link>
        </div>
        <div className="d-grid gap-2">
          <button type="button" onClick={handlePayment} className="btn btn-primary py-3 my-1 fw-bold bg-pureorange proceed-btn">Proceed</button>
          {/* <button type="button" onClick={()=>handlePaymentSuccess({courseid:CourseID, batchid:BatchID, email:authtoken, amount:payamount, transactionid:"pay_Ny0x6XWDqUhxz3", status:200})} className="btn btn-primary py-3 my-1 fw-bold bg-pureorange proceed-btn">Proceed</button> */}
        </div>
      </div>
    </>
  );
}

export default CivilModal;

