import React, { useState } from 'react';
import "../Login/Login.css";
import logo from "../../Image/logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { useAlert } from 'react-alert';
// import { BASE_URL } from '../../insightConfig/insightConfig';
import { SignupUser } from '../DynamicCourse/api';

function Signup({ setIsLoggedIn, prevPath, setAuthtoken }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const alert = useAlert();
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const generateToken = (email) => {
        // Generate a unique token value based on user's email
        const token = email;
        return token;
    };

    const setCookie = (name, value) => {
        // Set the cookie with the token value
        document.cookie = `${name}=${value}`;
    };
    const handleRegistration = async () => {
        try {
            // const response = await fetch(`${BASE_URL}/signup`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({ name, email, contact, password }),
            // });

            // // Check if response is successful
            // if (!response.ok) {
            //     throw new Error('Registration failed');
            // }
            // Call the registerUser function with user data
            const responseData = await SignupUser({ name, email, contact, password });

            // If login is successful, generate a new token
            const token = generateToken(email);

            // Set the token as a cookie
            setCookie('token', token);
            console.log(token)
            console.log(responseData)
            // If registration is successful, reset form fields and show success message
            setName('');
            setEmail('');
            setContact('');
            setPassword('');
            navigate('/');
            if (prevPath) {
                navigate(prevPath)
                console.log(prevPath)
            } else {
                navigate('/StudentDashboard');
            }
            setIsLoggedIn(true)
            setAuthtoken(token)
            localStorage.setItem('prevPath', prevPath);
            localStorage.setItem('authtoken', token);
            // alert("Hello, this is an alert!");
            alert.show('Registration Successfully');
            // setError('Registration successful!');
        } catch (error) {
            setError('Registration failed. Please try again.');
        }
    };


    return (
        <div className='loginContainer'>
            <div className='loginImage'>
                <img src={logo} alt='' />
            </div>
            <div className='loginForm'>
                <div className="card login-card">
                    <div className="card-header m-3 text-center fs-4 fw-500 text-white login-heading">
                        Create an Account
                    </div>
                    <div className="card-body">
                        <form>
                            <div className='signup-inputs'>
                                <div className="mb-3 col-lg-6">
                                    <label htmlFor="fullName" className="form-label">Full Name</label>
                                    <input type="text" className="form-control" id="fullName" placeholder='Full Name' aria-describedby="emailHelp" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="mb-3 col-lg-6 px-2">
                                    <label htmlFor="email" className="form-label">Email id</label>
                                    <input type="email" className="form-control" id="email" placeholder='Your Email-ID' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className='signup-inputs'>
                                <div className="mb-3 col-lg-6">
                                    <label htmlFor="phoneNo" className="form-label">Phone No</label>
                                    <input type="number" className="form-control" id="phoneNo" placeholder='Mobile No' value={contact} onChange={(e) => setContact(e.target.value)} />
                                </div>
                                <div className="mb-3 col-lg-6 px-2">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary login-btn py-2 border-0" type="button" onClick={handleRegistration}>REGISTER</button>
                            </div>
                        </form>
                        {/* Display error message if any */}
                        {error && <p className="text-danger">{error}</p>}
                    </div>
                    <div className='m-3 login-bottom'>
                        <div className="card-footer py-3 d-flex justify-content-center login-footer signup-footer">
                            Already have an Account? <Link className='list-group-item px-1' to="/login">Login</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login-list">
                <div className='login-box'>
                    <Link to="/home" className='login-link'>HOME</Link>
                    <Link to="/about" className='login-link'>ABOUT US</Link>
                    <Link to="#" className='login-link'>CIVILS</Link>
                    <Link to="#" className='login-link'>NEET</Link>
                    <Link to="#" className='login-link'>CUET</Link>
                    <Link to="#" className='login-link'>UGC-NET</Link>
                    <Link to="/daily-current-affairs/News" className='login-link'>CURRENT AFFAIRS</Link>
                    <Link to="/blogs" className='login-link'>BLOGS</Link>
                </div>
                <div className='login2'>
                    <h2 className='text-white'>© 2022 - 2023 Insight Delhi</h2>
                </div>
                <div className='login3'>
                    <div>
                        <FaFacebookF />
                    </div>
                    <div>
                        <FaTelegramPlane />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
