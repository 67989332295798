import React, { useEffect, useState } from 'react';
import "../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../Image/user.png";
import CopImg from "../../Image/civilcard1.jpg";
import CivilrightSection from '../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../AllPageContent/AllPageContent';
import ButtonPage from '../ButtonPage/ButtonPage';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import Tag from '../TagComponent/Tag';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Buttontab from '../Buttontabs/Buttontabs';
import Radium from "radium";
import Likeshare from '../LikeandShareCompoent/Likeshare';
import { BASE_URL } from '../../insightConfig/insightConfig';

function DynamicToday() {
    const { todayurl } = useParams();
    const [newData, setNewData] = useState(null);
    const Buttonname = "Singlebutton";
    const componentName = "Blog";
    const [contentToShow, setContentToShow] = useState('In News');

    useEffect(() => {
        // if()
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/blog/${todayurl}`);
                const data = await response.json();
                setNewData(data[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [todayurl]);
    if (!newData) {
        return <div>Loading...</div>
    }
    console.log(newData.Title)
    const data = {
        academyName: newData.Title,
        userImage: userImage,
        userName: 'INSIGHT IAS',
        Image: newData.Image
    };

    const paragraphs = newData.Description
    const noteurl = newData.NotePDF

    const blogStyle = {
        before: {
            content: '""',
            width: "100%",
            backgroundImage: `url(${newData.Image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            opacity: "0.5",
            position: "absolute",
            filter: "blur(50px)",
            zIndex: "1"
        }
    }
    return (
        <>
            <Helmet>
                <title>{newData.MetaTitle}</title>
                <meta name="description" content={`${newData.MetaDescription}`}></meta>
            </Helmet>
            {/* Civil Cop Section Start */}
            <section className={`${todayurl} dynamic-blog`}>
            <section style={blogStyle.before} className='dynamic-blog-before' />
                {/* Civil Top Section Start */}
                <CivilTopSection
                    academyName1={data.academyName}
                    userImage={data.userImage}
                    userName={data.userName}
                    Image={data.Image}
                />
                {/* Civil Top Section End */}
            </section>
            <div className='Civil-section'>
                <div className='civil-row'>
                    <div className='col-lg-8 col-md-12 col-sm-12 me-2'>
                    <div className='BlogPage-box2 dynamicPage-box2 d-flex flex-wrap align-items-center justify-content-between'>
                            <div>
                            <Buttontab componentName={componentName} setContentToShow={setContentToShow} />
                            </div>
                            <div>
                            <Likeshare Like={newData.LikeCount + newData.PreLikes} Sn={newData.Sn} view={newData.ViewCount + newData.preViews}/>
                            </div>
                        </div>
                        <Content paragraphs={paragraphs} />
                        <ButtonPage Pagename={Buttonname} noteurl={noteurl} />
                        <Tag />
                    </div>
                    {/* Civil right Section Start */}
                    <div className='col-lg-4 col-md-12 col-sm-12'>
                        <div className='card p-2'>
                            <CurrentAffair componentName={componentName} />
                        </div>
                    </div>
                    {/* Civil right Section End */}
                </div>
            </div>
            {/* Civil Cop Section End */}
        </> 
    )
}

export default Radium(DynamicToday);