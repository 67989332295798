import React, { useEffect } from 'react'
import CardsPage from '../CardsPage/CardsPage';

function Mytest({cards, heading2}) {
  // useEffect(()=>{
  //   {cards.map((card) => (
  //     setIsPayment(card.paymentdonecourse) 
  //   ))}
  // },[])
  return (
    <>
      <CardsPage cards={cards} heading2={heading2}/>
    </>
  )
}

export default Mytest;