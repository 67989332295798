import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import gsImg from "../../../Image/gsprism.png";
import ptImg from "../../../Image/ptmaster.png";
import upscImg from "../../../Image/upsc.png";
import CardsPage from '../../CardsPage/CardsPage';
import TwoRowThreeColumnLayout from '../../EffectonLoad/TwoThreecolumnLayout';
import { BASE_URL } from '../../../insightConfig/insightConfig';

function CivilsTest() {
  const civilheading = "TEST SERIES"
  const [testsData, setTestsData] = useState([]);
  useEffect(() => {
    // Fetch data from API
    fetch(`${BASE_URL}/ExamBodytest/civils`)
      .then(response => response.json())
      .then(data => {
        // Update state with the fetched data
        setTestsData(data.data);
        console.log(data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures useEffect runs only once after initial render
  if (testsData.length === 0) {
    return <><TwoRowThreeColumnLayout /></>
  }

  const cards = testsData
  return (
    <>
      <Helmet>
        <title>Index</title>
      </Helmet>
      <div>
        <CardsPage cards={cards} heading={civilheading} />
      </div>
    </>
  )
}

export default CivilsTest;