import React, { useEffect, useState } from 'react';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import Dashboardleft2 from '../DashboardLeft/DashboardLeft2';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { fetchtestData, fetchtestLessonData } from '../DynamicCourse/api';

function DynamicTestseries({ isAuth, authtoken, coursepayment, coursenotpayment, ispayment, setPrevPath }) {
  const { testseriesname } = useParams();
  const [testData, setTestData] = useState(null);
  const [testDataDetail, setTestDataDetail] = useState(null);
  const componentName = "civilTest";
  const location = useLocation();

  // Update prevPath whenever the route changes
  useEffect(() => {
    setPrevPath(location.pathname);
  }, [location.pathname, setPrevPath]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchtestData(testseriesname, authtoken, coursepayment, coursenotpayment);
        setTestData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [testseriesname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchtestLessonData(testData.CourseID);
        setTestDataDetail(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [testData]);

  if (!testData) {
    return <div>Loading...</div>
  }
  else if (!testDataDetail) {
    return <div>Loading...</div>
  }

  const paragraphs = testData.Description

  // const testtoggleData = [
  //   {
  //     SNo: "PTS-0",
  //     Heading: "Demo Test-1",
  //     date: null,
  //     Lists: [
  //       [
  //         "Ancient India (Class VI): Chap.1 – 3, 5 – 8 (Old NCERT)",
  //         "Our Pasts Part – I (Class VI): Chap. 1 – 10 (New NCERT)",
  //         "Medieval India (Class VII): Chap. 2 – 8 (Old NCERT)",
  //         "Our Pasts Part – II (Class VII): Chap. 1 – 8 (New NCERT)",
  //         "Our Pasts Part – III (Class VIII): Chap. 1 – 8 (New NCERT)",
  //         "The Story of Civilization Volume – 1 (Class IX): Chap. 1 – 3 (Old NCERT)",
  //         "India and the Contemporary World – 1 (Class IX): Chap. 4 & 5 (New NCERT)",
  //         "The Story of Civilisation – 2 (Class X): Chap. 14, 15 & 16 (Old NCERT)",
  //         "India and the Contemporary World – 2 (Class X): (Chap. 1 – 5) (New NCERT)"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 May to 07 May’23)"
  //   },
  //   {
  //     SNo: "PTS-0",
  //     Heading: "Demo Test-2",
  //     date: null,
  //     Lists: [
  //       [
  //         "Social & Political Life – I (Class VI): Chap. 3 – 7 (New NCERT)",
  //         "Social & Political Life – II (Class VII): Chap. 1, 2, 3, 5, 6 & 7 (New NCERT)",
  //         "Social & Political Life (Class VIII): Chap. 1 – 10 (New NCERT)",
  //         "Democratic Politics Part - I (Class IX): Chap. 1 – 5 (New NCERT)"
  //       ]
  //     ],
  //     CurrentAffairs: ""
  //   },
  //   {
  //     SNo: "PTS-1",
  //     Heading: "INDIAN HISTORY & CULTURE (Basic Level)",
  //     date: null,
  //     Lists: [
  //       [
  //         "Ancient India (Class XI): Chap. 3 – 29 (Old NCERT)",
  //         "Medieval India (Class XI): Chap. 2 – 19 (Old NCERT)",
  //         "An Intro. to Indian Fine Arts (Class XI): Chap. 1 – 8 (New NCERT)",
  //         "Living Craft Traditions of India (Class XI): Chap. 1 – 10 (New NCERT)",
  //         "Tamil Nadu Board: History (Class XI): Chap. 1 – 14 (Old)",
  //         "Tamil Nadu Board: History (Class XI): Chap. 15 – 23 (Old)",
  //         "Tamil Nadu Board: History (Class XI): Volume 1 & 2 (New)",
  //         "Modern India (Class XII): Chap. 1 – 13 (Old NCERT)",
  //         "Themes in Indian History Part 1 (Class XII): Chap. 1 & 4 (New NCERT)",
  //         "Themes in Indian History Part 2 (Class XII): Chap. 5 – 8 (New NCERT)",
  //         "Themes in Indian History Part 3 (Class XII): Chap. 9 & 12 (New NCERT)",
  //         "Tamil Nadu Board: Class XII: History (Old)",
  //         "Tamil Nadu Board: Class XII: History (New): Volume 1"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (15 June to 21 June’23)"
  //   },
  //   {
  //     SNo: "PTS-2",
  //     Heading: "GEOGRAPHY OF INDIA & WORLD (Basic Level)",
  //     date: null,
  //     Lists: [
  //       [
  //         "Fundamentals of Physical Geography (Class XI): Chap. 2 – 14 (New NCERT)",
  //         "India: Physical Environment (Class XI): Chap. 1 – 6 (New NCERT)",
  //         "Fundamentals of Human Geography (Class XII): Chap. 1, 5, 6 & 8 (New NCERT)",
  //         "India-People & Economy (Class XII): Chap. 5, 7 & 8 (New NCERT)"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (22 June to 30 June’23)"
  //   },
  //   {
  //     SNo: "PTS-3",
  //     Heading: "INDIAN POLITY AND CONSTITUTION (Basic Level)",
  //     date: null,
  //     Lists: [
  //       [
  //         "Demo. Politics Part - II (Class X): Chap. 1 – 8 (New NCERT)",
  //         "Indian Constitution at Work (Class XI): Chap. 1 -10 (Old NCERT)",
  //         "Indian Constitution and Administration (Class XI): Chap. 1 – 8 (Old NCERT)",
  //         "Indian Constitution at Work (Class XI): Chap. 1 -10 (New NCERT)",
  //         "Political Theory (Class XI): Chap. 1 – 10 (New NCERT)",
  //         "Contemporary World Politics (Class XII): Chap. 1, 2, 5 & 6-9 (New NCERT)"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 July to 07 July’23)"
  //   },
  //   {
  //     SNo: "PTS-4",
  //     Heading: "ENVIRONMENTAL ISSUES & SUSTAINABLE DEVELOPMENT (Basic Level)",
  //     date: null,
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1 – 9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1 – 11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11 – 13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1 – 4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1 – 4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1 – 3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1 – 5), Module 5 (Chap. 1 – 4), Module 6 (Chap. 1 – 4), Module 7 (Chap. 1 – 4), Module 8A & 8B (Chap. 1 – 5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III – IX)"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (22 May to 31 May’28)"
  //   },
  //   {
  //     SNo: "PTS-5",
  //     Heading: "GENERAL SCIENCE (Basic Level)",
  //     date: 'December 31, 2023',
  //     Lists: [
  //       [
  //         "Science (Class VI): Chap. 2, 4, 6, 7, 9 & 11 (New NCERT)",
  //         "Science (Class VII): Chap. 1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12 & 13 (New NCERT)",
  //         "Science (Class VIII): Chap. 1 - 13 (New NCERT)",
  //         "Science (Class IX): Chap. 5 - 7, 10 & 12 (New NCERT)",
  //         "Science (Class X): Chap. 4, 6 & 9 – 13 (New NCERT)",
  //         "Chemistry (Class XI): Unit 14 (New NCERT)",
  //         "Biology (Class XI): Units 4 & 5 (New NCERT)",
  //         "Biology (Class XII): Units 8 – 10 (New NCERT)"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 June to 07 June’23)"
  //   },
  //   {
  //     SNo: "PTS-6",
  //     Heading: "INDIAN ECONOMY (Basic Level)",
  //     date: 'January 07, 2024',
  //     Lists: [
  //       [
  //         "Economics (Class IX): Chap. 3 & 4 (New NCERT)",
  //         "Understanding Economic Development (Class X): Chap. 2, 3, 4 & 5 (New NCERT)",
  //         "Indian Economic Development (Class XI): Chap. 1 – 7 (New NCERT)",
  //         "Introduction to Macroeconomics (Class XII): Chap. 1, 2, 3, 5 & 6 (New NCERT)",
  //         "Introduction to Microeconomics (Class XII): Chap. 1, 2 & 4 (New NCERT)"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (08 June to 14 June’23)"
  //   },
  //   {
  //     SNo: "PTS-7",
  //     Heading: "ANCIENT AND MEDIEVAL INDIA, ART & CULTURE",
  //     date: 'January 14, 2024',
  //     Lists: [
  //       [
  //         "cient India (Class VI): Chap.1-3, 5-8 (Old NCERT)",
  //         "Our Pasts Part-I (Class VI): Chap. 1-10 (New NCERT)",
  //         "Medieval India (Class VII): Chap. 2-8 (Old NCERT)",
  //         "Our Pasts Part-II (Class VII): Chap. 1-8 (New NCERT)",
  //         "Our Pasts Part-III (Class VIII): Chap. 1-8 (New NCERT)",
  //         "The Story of Civilization Volume-1 (Class IX): Chap. 1-3 (Old NCERT)",
  //         "India and the Contemporary World-1 (Class IX): Chap. 4 & 5 (New NCERT)",
  //         "The Story of Civilisation-2 (Class X): Chap. 14, 15 & 16 (Old NCERT)",
  //         "India and the Contemporary World-2 (Class X): (Chap. 1-5) (New NCERT)",
  //         "Ancient India (Class XI): Chap. 3-29 (Old NCERT)",
  //         "Medieval India (Class XI): Chap. 2-19 (Old NCERT)",
  //         "An Intro. to Indian Fine Arts (Class XI): Chap. 1-8 (New NCERT)",
  //         "Living Craft Traditions of India (Class XI): Chap. 1-10 (New NCERT)",
  //         "Tamil Nadu Board: History (Class XI): Chap. 1-14 (Old)",
  //         "Tamil Nadu Board: History (Class XI): Chap. 15-23 (Old)",
  //         "Tamil Nadu Board: History (Class XI): Volume 1 & 2 (New)",
  //         "Modern India (Class XII): Chap. 1-13 (Old NCERT)",
  //         "Themes in Indian History Part 1 (Class XII): Chap. 1 & 4 (New NCERT)",
  //         "Themes in Indian History Part 2 (Class XII): Chap. 5-8 (New NCERT)",
  //         "Themes in Indian History Part 3 (Class XII): Chap. 9-12 (New NCERT)",
  //         "Tamil Nadu Board: Class XII: History (Old)",
  //         "Tamil Nadu Board: Class XII: History (New): Volume 1",
  //         "Important Developments related to Indian History & Culture in last 2 years",
  //         "Other Important Books & Sources",
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Nov to 07 Nov'23)"
  //   },
  //   {
  //     SNo: "PTS-8",
  //     Heading: "CSAT PRACTICE TEST-1",
  //     date: 'January 14, 2024',
  //     Lists: [
  //       [
  //         "Comprehension",
  //       ]
  //     ],
  //     CurrentAffairs: ""
  //   },
  //   {
  //     SNo: "PTS-9",
  //     Heading: "MODERN INDIAN HISTORY, ART & CULTURE",
  //     date: 'January 25, 2024',
  //     Lists: [
  //       [
  //         "cient India (Class VI): Chap.1-3, 5-8 (Old NCERT)",
  //         "Our Pasts Part-I (Class VI): Chap. 1-10 (New NCERT)",
  //         "Medieval India (Class VII): Chap. 2-8 (Old NCERT)",
  //         "Our Pasts Part-II (Class VII): Chap. 1-8 (New NCERT)",
  //         "Our Pasts Part-III (Class VIII): Chap. 1-8 (New NCERT)",
  //         "The Story of Civilization Volume-1 (Class IX): Chap. 1-3 (Old NCERT)",
  //         "India and the Contemporary World-1 (Class IX): Chap. 4 & 5 (New NCERT)",
  //         "The Story of Civilisation-2 (Class X): Chap. 14, 15 & 16 (Old NCERT)",
  //         "India and the Contemporary World-2 (Class X): (Chap. 1-5) (New NCERT)",
  //         "Ancient India (Class XI): Chap. 3-29 (Old NCERT)",
  //         "Medieval India (Class XI): Chap. 2-19 (Old NCERT)",
  //         "An Intro. to Indian Fine Arts (Class XI): Chap. 1-8 (New NCERT)",
  //         "Living Craft Traditions of India (Class XI): Chap. 1-10 (New NCERT)",
  //         "Tamil Nadu Board: History (Class XI): Chap. 1-14 (Old)",
  //         "Tamil Nadu Board: History (Class XI): Chap. 15-23 (Old)",
  //         "Tamil Nadu Board: History (Class XI): Volume 1 & 2 (New)",
  //         "Modern India (Class XII): Chap. 1-13 (Old NCERT)",
  //         "Themes in Indian History Part 1 (Class XII): Chap. 1 & 4 (New NCERT)",
  //         "Themes in Indian History Part 2 (Class XII): Chap. 5-8 (New NCERT)",
  //         "Themes in Indian History Part 3 (Class XII): Chap. 9-12 (New NCERT)",
  //         "Tamil Nadu Board: Class XII: History (Old)",
  //         "Tamil Nadu Board: Class XII: History (New): Volume 1",
  //         "Important Developments related to Indian History & Culture in last 2 years",
  //         "Other Important Books & Sources"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (08 Nov to 14 Nov'23)"
  //   },
  //   {
  //     SNo: "PTS-10",
  //     Heading: "GEOGRAPHY OF INDIA",
  //     date: 'January 28, 2024',
  //     Lists: [
  //       [
  //         "Land and People-Part I (Class VI): Chap. 6, 11 & 14-16 (Old NCERT)",
  //         "The Earth Our Habitat (Class VI): Chap. 1-3, 5 & 6 (New NCERT)",
  //         "Land and People-Part II (Class VII): Chap. 1-8 & 10 (Old NCERT)",
  //         "Our Environment (Class VII): Chap. 2-7 (New NCERT)",
  //         "Land and People-Part III (Class VIII): Chap. 2-4 & 10-15 (Old NCERT)",
  //         "Resource and Development (Class VIII): Chap. 2-5 (New NCERT)",
  //         "Contemporary India-I (Class IX): Chap. 1-6 (New NCERT)",
  //         "Contemporary India-II (Class X): Chap. 1-7 (New NCERT)",
  //         "Fundamentals of Physical Geography (Class XI): Chap. 2-14 (New NCERT)",
  //         "India: Physical Environment (Class XI): Chap. 1-6 (New NCERT)",
  //         "Fundamentals of Human Geography (Class XII): Chap. 1, 5, 6 & 8 (New NCERT)",
  //         "India-People & Economy (Class XII): Chap. 5, 7 & 8 (New NCERT)",
  //         "Certificate Physical and Human Geography - G C Leong: (Chap. 1-3, 11 & 13 - 25)",
  //         "Physical Geography - Savindra Singh (Chap. 11, 24-26, 28, 37 & 38)",
  //         "Geography of India - Majid Hussain: (Chap. 2– 11)",
  //         "India: A Comprehensive Geography - D.R. Khullar",
  //         "Important Developments related to Indian & World Geography in last 2 years",
  //         "Other Important Books & Web Sources",
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (15 Nov to 21 Nov'23)"
  //   },
  //   {
  //     SNo: "PTS-11",
  //     Heading: "CSAT PRACTICE TEST-2",
  //     date: 'January 28, 2024',
  //     Lists: [
  //       [
  //         "Logical Reasoning and Analytical Ability"
  //       ]
  //     ],
  //     CurrentAffairs: ""
  //   },
  //   {
  //     SNo: "PTS-12",
  //     Heading: "GEOGRAPHY OF WORLD",
  //     date: 'February 04, 2024',
  //     Lists: [
  //       [
  //         "Land and People-Part I (Class VI): Chap. 6, 11 & 14-16 (Old NCERT)",
  //         "The Earth Our Habitat (Class VI): Chap. 1-3, 5 & 6 (New NCERT)",
  //         "Land and People-Part II (Class VII): Chap. 1-8 & 10 (Old NCERT)",
  //         "Our Environment (Class VII): Chap. 2-7 (New NCERT)",
  //         "Land and People-Part III (Class VIII): Chap. 2-4 & 10-15 (Old NCERT)",
  //         "Resource and Development (Class VIII): Chap. 2-5 (New NCERT)",
  //         "Contemporary India-I (Class IX): Chap. 1-6 (New NCERT)",
  //         "Contemporary India-II (Class X): Chap. 1-7 (New NCERT)",
  //         "Fundamentals of Physical Geography (Class XI): Chap. 2-14 (New NCERT)",
  //         "India: Physical Environment (Class XI): Chap. 1-6 (New NCERT)",
  //         "Fundamentals of Human Geography (Class XII): Chap. 1, 5, 6 & 8 (New NCERT)",
  //         "India-People & Economy (Class XII): Chap. 5, 7 & 8 (New NCERT)",
  //         "Certificate Physical and Human Geography - G C Leong: (Chap. 1-3, 11 & 13 - 25)",
  //         "Physical Geography - Savindra Singh (Chap. 11, 24-26, 28, 37 & 38)",
  //         "Geography of India - Majid Hussain: (Chap. 2– 11)",
  //         "India: A Comprehensive Geography - D.R. Khullar",
  //         "Important Developments related to Indian & World Geography in last 2 years",
  //         "Other Important Books & Web Sources"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (22 Nov to 30 Nov'23)"
  //   },
  //   {
  //     SNo: "PTS-13",
  //     Heading: "INDIAN POLITY AND CONSTITUTION",
  //     date: 'February 11, 2024',
  //     Lists: [
  //       [
  //         "Social & Political Life-I (Class VI): Chap. 3-7 (New NCERT)",
  //         "Social & Political Life-II (Class VII): Chap. 1-3, 5-7 (New NCERT)",
  //         "Social & Political Life (Class VIII): Chap. 1-8 (New NCERT)",
  //         "Democratic Politics Part-I (Class IX): Chap. 1-5 (New NCERT)",
  //         "Demo. Politics Part - II (Class X): Chap. 1-8 (New NCERT)",
  //         "Indian Constitution at Work (Class XI): Chap. 1 -10 (Old NCERT)",
  //         "Indian Constitution and Administration (Class XI): Chap. 1-8 (Old NCERT)",
  //         "Indian Constitution at Work (Class XI): Chap. 1 -10 (New NCERT)",
  //         "Political Theory (Class XI): Chap. 1-10 (New NCERT)",
  //         "Contemporary World Politics (Class XII): Chap. 1, 2, 5 & 6-9 (New NCERT)",
  //         "Indian Polity - M. Laxmikanth",
  //         "Governance in India - M. Laxmikanth: (Chap. 1-4, 7, 11-21)",
  //         "Important Developments related to Indian Polity & Constitution in last 2 years",
  //         "Other Important Books & Sources"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Dec to 15 Dec'23)",
  //   },
  //   {
  //     SNo: "PTS-14",
  //     Heading: "CSAT PRACTICE TEST-3",
  //     date: 'February 11, 2024',
  //     Lists: [
  //       [
  //         "General Mental Ability"
  //       ]
  //     ],
  //     CurrentAffairs: ""
  //   },
  //   {
  //     SNo: "PTS-15",
  //     Heading: "ECOLOGY & ENVIRONMENT",
  //     date: 'February 25, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-16",
  //     Heading: "CSAT PRACTICE TEST-4",
  //     date: 'February 25, 2024',
  //     Lists: [
  //       [
  //         "Decision Making and Problem Solving",
  //         "Interpersonal Skills including Communication Skills"
  //       ]
  //     ],
  //     CurrentAffairs: ""
  //   },
  //   {
  //     SNo: "PTS-17",
  //     Heading: "SCIENCE & TECHNOLOGY",
  //     date: 'March 03, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-18",
  //     Heading: "ECONOMIC DEVELOPMENT",
  //     date: 'March 10, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-19",
  //     Heading: "CSAT PRACTICE TEST-5",
  //     date: 'March 10, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-20",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-1",
  //     date: 'March 24, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-21",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-1",
  //     date: 'March 24, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-22",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-2",
  //     date: 'March 31, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-23",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-2",
  //     date: 'March 31, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-24",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-3",
  //     date: 'April 07, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-25",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-3",
  //     date: 'April 07, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-26",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-4",
  //     date: 'April 14, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-27",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-4",
  //     date: 'April 14, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-28",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-5",
  //     date: 'April 21, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-29",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-5",
  //     date: 'April 21, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-30",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-6",
  //     date: 'April 28, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-31",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-6",
  //     date: 'April 29, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-32",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-7",
  //     date: 'May 05, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-33",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-7",
  //     date: 'May 05, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-34",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-8",
  //     date: 'May 08, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-35",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-8",
  //     date: 'May 08, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-36",
  //     Heading: "GEN. STUDIES FULL SYLLABUS COMPREHENSIVE MOCK TEST-9",
  //     date: 'May 12, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  //   {
  //     SNo: "PTS-37",
  //     Heading: "CSAT FULL SYLLABUS COMPREHENSIVE MOCK TEST-9",
  //     date: 'May 12, 2024',
  //     Lists: [
  //       [
  //         "Our Environment (Class VII): Chap. 1-9 (New NCERT)",
  //         "Understanding Environment (Class IX): Chap. 1-11 (Old NCERT)",
  //         "Biology (Class XII): Chap. 11-13 (New NCERT)",
  //         "Environmental Education (Class IX): Chap. 1-4 (ICSE)",
  //         "Environmental Education (Class X): Chap. 1-4 (ICSE)",
  //         "NIOS Environment Notes: Module 1 (Chap. 1-3), Module 3 (Chap. 1 & 2), Module 4 (Chap. 1-5), Module 5 (Chap. 1-4), Module 6 (Chap. 1-4), Module 7 (Chap. 1-4), Module 8A & 8B (Chap. 1-5)",
  //         "Environment & Ecology by R. Rajagopalan (Part I, III-IX)",
  //         "Environment & Ecology by R. Rajagopalan: Part – II",
  //         "Ministry of Environment: Annual Report (Latest)",
  //         "Year End Review: Ministry of Environment, Forest and Climate Change.",
  //         "NIOS Environment Notes: Module 2: Chap. 1, 2, 3 & 4",
  //         "National Initiatives on Climate Change, Important Funds of Climate Change, Important Organisations, & International Conventions.",
  //         "Important Developments related to Environment & Ecology in last 2 years"
  //       ]
  //     ],
  //     CurrentAffairs: "Current Affairs: (01 Jan. to 15 Jan.'24)"
  //   },
  // ];
  const testtoggleData = testDataDetail
  console.log(testtoggleData)
  return (
    <>
      <Helmet>
        <title>{testData.MetaTitle}</title>
        <meta name="description" content={`${testData.MetaDescription}`}></meta>
      </Helmet>
      {/* Civil Test Page Start */}
      <div className='Dashboard-background'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-12 col-sm-12 dashboardmain-left p-3'>
              <Dashboardleft2 testDetail={testData} authtoken={authtoken} payamount={testData.Price} CourseID={testData.CourseID} BatchID={testData.BatchId} />
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12'>
              {/* Civil Button  Section Start */}
              <CivilButton authtoken={authtoken} isAuth={isAuth} ispayment={ispayment} CourseID={testData.CourseID} BatchID={testData.BatchId} payamount={testData.Price} componentName={componentName} paragraphs={paragraphs} testtoggleData={testtoggleData} />
              {/* Civil Button Foundation Section End */}
            </div>
          </div>
        </div>
      </div>
      {/* Civil Test Page End */}
    </>
  )
}

export default DynamicTestseries;