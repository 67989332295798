import React from 'react';
import "./Banner.css";
import { Link } from 'react-router-dom';

function Banner() {
    return (
        <>
            {/* Banner Section Start */}
            <div className='container-fluid Banner-background py-1'>
                <div className='banner-box'>
                    {/* Card Section Start */}

                    <Link className="card text-center card-background list-group-item" to="/civils">
                        <div className="bann-card">
                            <h5 className="banner-title">UPSC</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">Civil Services Courses</p>
                        </div>
                    </Link>

                    <Link className="card text-center card-background list-group-item" to="/civilstestseries">
                        <div className="bann-card">
                            <h5 className="banner-title">UPSC</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">Civil Services Test Courses</p>
                        </div>
                    </Link>

                    <Link className="card text-center card-background list-group-item">
                        <div className="bann-card">
                            <h5 className="banner-title">CUET</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">University Entrance Prep</p>
                        </div>
                    </Link>

                    <Link className="card text-center card-background list-group-item">
                        <div className="bann-card">
                            <h5 className="banner-title">NEET</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">Medical Preparation</p>
                        </div>
                    </Link>

                    <Link className="card text-center card-background list-group-item" to="/daily-current-affairs/News">
                        <div className="bann-card">
                            <h5 className="banner-title">NEWS</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">Daily Current Affairs</p>
                        </div>
                    </Link>

                    <Link className="card text-center card-background list-group-item">
                        <div className="bann-card">
                            <h5 className="banner-title">QUIZ</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">Test Yourself And Succeed</p>
                        </div>
                    </Link>

                    <Link className="card text-center card-background list-group-item" to="/blogs">
                        <div className="bann-card">
                            <h5 className="banner-title">BLOGS</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">Expand Your Knowledge</p>
                        </div>
                    </Link>

                    <Link className="card text-center card-background list-group-item" to="/book">
                        <div className="bann-card">
                            <h5 className="banner-title">BOOKS</h5>
                            <div style={{ height: "0.1px", backgroundColor: "white", margin: "0" }} />
                            <p className="banner-paragraph">Keep Learning</p>
                        </div>
                    </Link>

                    {/* Card Section End */}
                </div>
            </div>
            {/* Banner Section End */}
            {/* Animation Section Start */}
            <div className='Boxs'>
                <div className='b1'></div>
                <div className='b2'></div>
                <div className='b3'></div>
                <div className='b4'></div>
                <div className='b5'></div>
                <div className='b6'></div>
            </div>
            {/* Animation Section End */}
        </>
    )
}

export default Banner;