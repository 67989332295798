import React from 'react';
import "./Subscribe.css";
import { IoIosMailOpen } from "react-icons/io";

function Subscribe() {
    return (
        <>
            {/* Subscriber Section Start */}
            <div className='SubscribeContainer'>
                <div className='SubscribeBox'>
                    <IoIosMailOpen className='fs-1' />
                    <h1 className='fw-boldd fs-2'>SUBSCRIBE</h1>
                    <p className='text-center'>For latest news and trends related to the Competitive Examinations, Including updates on new courses, Test Series, Blogs, Study meaterial and current affairs.</p>
                    <div className='Subscribe_input'>
                        <input className="form-control" type='email' placeholder='Enter Your Email' />
                        <button className='input-group-text' type='submit'>Submit</button>
                    </div>
                </div>
            </div>
            {/* Subscriber Section End */}
        </>
    )
}

export default Subscribe;