// import React, { useEffect, useState, useRef } from 'react';
// import CivilrightSection from '../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
// import CivilCard from '../ReusableComponent/CIVIL/CivilCard/CivilCard';
// import Calendars from '../Calendar/Calendar';
// import '../Calendar/Calendar.css';
// import 'react-calendar/dist/Calendar.css';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
// import listPlugin from '@fullcalendar/list';
// import '../../../node_modules/@fullcalendar/core/main.css';
// import '../../../node_modules/@fullcalendar/daygrid/main.css';
// import '../../../node_modules/@fullcalendar/timegrid/main.css';
// import '../../../node_modules/@fullcalendar/list/main.css';
// import CurrentAffair from '../CurrentAffair/CurrentAffair';
// import Pagelayout from '../EffectonLoad/pagelayout';

// function Current1({ CardData, componentName, isloading, setisfilterapply, contentToShow, setSearchkeyword, setsearcharray, isfilterapply }) {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [filteredData, setFilteredData] = useState([]);
//   const calendarRef = useRef(null); // Reference to the FullCalendar instance

//   useEffect(() => {
//     // Set initial filtered data to first 10 cards
//     setFilteredData(CardData);
//   }, [CardData]);

//   // Function to handle single date clicks
//   const handleDateClick = async (arg) => {
//     setSelectedDate(arg.dateStr);

//     // Perform actions based on selection here (e.g., filter data, API calls)
//     if (calendarRef.current) {
//       calendarRef.current.getApi().unselect(); // Optionally clear selection after action
//     }
//     try {
//       setisfilterapply(true);
//       const response = await fetch(`http://68.178.172.24:8001/current-affairs-date-filter/${contentToShow}/${arg.dateStr}`);
//       const data = await response.json();
//       setFilteredData(data.data)
//   } catch (error) {
//       console.error('Error fetching data:', error);
//   }
//   };

//   const handleDateinput = async (date) => {
//     try {
//       setisfilterapply(true);
//       const response = await fetch(`http://68.178.172.24:8001/current-affairs-date-filter/${contentToShow}/${date}`);
//       const data = await response.json();
//       setFilteredData(data.data)
//   } catch (error) {
//       console.error('Error fetching data:', error);
//   }
//   }

//   const filterData = async (term) => {
//     try {
//         setisfilterapply(true);
//         const response = await fetch(`http://68.178.172.24:8001/current-affairs-search-filter/${contentToShow}/${term}`);
//         const data = await response.json();
//         setFilteredData(data.data)
//         setsearcharray(data.data)
//         console.log(data.data)
//         setSearchkeyword(term);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// };


//   return (
//     <>
//       <div className='Civil-section blog1-section'>
//         <div className='civil-row'>
//           <div className='col-lg-8 col-md-12 col-sm-12'>
//             <Calendars selectedDates={selectedDate} handleDateClick={handleDateClick} onDateSelect={handleDateinput} onSearch={filterData} />
//             {/* Content Component Start */}
//             {(!isloading && CardData.length === 0) ? <Pagelayout /> : <CivilCard CardData={filteredData} />}
//             {filteredData.length === 0 && CardData.length !== 0 && <><h3 className='fw-bold text-center p-5'>No Result Found!</h3></>}
//             {/* Content Component End */}
//             {/* <ButtonPage /> */}
//           </div>
//           {/* Civil right Section Start */}
//           <div className='col-lg-4 col-md-12 col-sm-12 card'>
//             <div>
//               <div className="full-calendar-container">
//                 <FullCalendar className="full-calendar-container"
//                   plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
//                   initialView="timeGridWeek"
//                   selectable={true} // Enable selectable feature
//                   weekends={true}
//                   dateClick={handleDateClick}
//                   ref={calendarRef}
//                 />
//               </div>
//               {/* {selectedDate} */}
//               <CivilrightSection />
//               <div class="card-body">
//                 <CurrentAffair componentName={componentName} />
//               </div>
//             </div>
//           </div>
//           {/* Civil right Section End */}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Current1;

import React, { useEffect, useState, useRef } from 'react';
import CivilrightSection from '../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import CivilCard from '../ReusableComponent/CIVIL/CivilCard/CivilCard';
import Calendars from '../Calendar/Calendar';
import '../Calendar/Calendar.css';
// import 'react-calendar/dist/Calendar.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import listPlugin from '@fullcalendar/list';
import '../../../node_modules/@fullcalendar/core/main.css';
import '../../../node_modules/@fullcalendar/daygrid/main.css';
import '../../../node_modules/@fullcalendar/timegrid/main.css';
import '../../../node_modules/@fullcalendar/list/main.css';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import Pagelayout from '../EffectonLoad/pagelayout';
import { FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";
import Calendar from 'react-calendar';
import { BASE_URL } from '../../insightConfig/insightConfig';
import 'react-calendar/dist/Calendar.css';
import RangeCalendar from '../Calendar/RangeCalendar';

function Current1({ CardData, componentName,datefilter, datefilteredData ,isloading, setDateState, setisfilterapply, contentToShow, setSearchkeyword, setsearcharray, isfilterapply }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const calendarRef = useRef(null); // Reference to the FullCalendar instance

  useEffect(() => {
    // Set initial filtered data to first 10 cards
    setFilteredData(CardData);
    // setDateState(handleDateinput())
    setDateState(contentToShow)
  }, [CardData]);

  // Function to handle single date clicks
  // const handleDateClick = async (arg) => {
  //   setSelectedDate(arg.dateStr);
  //   // Perform actions based on selection here (e.g., filter data, API calls)
  //   if (calendarRef.current) {
  //     calendarRef.current.getApi().unselect(); // Optionally clear selection after action
  //   }
  //   try {
  //     setisfilterapply(true);
  //     const response = await fetch(`http://68.178.172.24:8001/current-affairs-date-filter/${contentToShow}/${arg.dateStr}`);
  //     const data = await response.json();
  //     setFilteredData(data.data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  const handleDateClick = async (date) => {
    const originalDate = new Date(date);
    console.log(originalDate)
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const formattedDate = utcDate.toISOString().split('T')[0];;  // Extracting only the date part
    console.log(formattedDate)
    setSelectedDate(formattedDate); 
    try {
      setisfilterapply(true);
      const response = await fetch(`${BASE_URL}/current-affairs-date-filter/${contentToShow}/${formattedDate}`);
      const data = await response.json();
      setFilteredData(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleYearChange = (delta) => {
    const newYear = selectedYear + delta;
    setSelectedYear(newYear);
    calendarRef.current.getApi().gotoDate(`${newYear}-0${selectedMonth}-01`);
  };

  const handleDateinput = async (date) => {
    // setSelectedDate(date)
    console.log(date)
    const originalDate = new Date(date);
const formattedDate = originalDate.toISOString().split('T')[0]; // Extracting only the date part

    try {
      setisfilterapply(true);
      if(datefilter){
        setisfilterapply(datefilter)
      }
      const response = await fetch(`${BASE_URL}/current-affairs-date-filter/${contentToShow}/${formattedDate}`);
      const data = await response.json();
      setFilteredData(data.data);
      if(datefilteredData){
        setFilteredData(datefilteredData)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filterData = async (term) => {
    try {
      setisfilterapply(true);
      const response = await fetch(`${BASE_URL}/current-affairs-search-filter/${contentToShow}/${term}`);
      const data = await response.json();
      setFilteredData(data.data);
      setsearcharray(data.data);
      console.log(data.data);
      setSearchkeyword(term);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 
  return (
    <>
      <div className='Civil-section blog1-section'>
        <div className='civil-row blog-margin-top white'>
          <div className='col-lg-8 col-md-12 col-sm-12'>
            <Calendars selectedDates={selectedDate} handleDateClick={handleDateClick} onDateSelect={handleDateinput} onSearch={filterData} />
            {/* <RangeCalendar/> */}
            {/* Content Component Start */}
            {!isloading && CardData.length === 0 ? <Pagelayout /> : <CivilCard CardData={filteredData} />}
            {filteredData.length === 0 && CardData.length !== 0 && <><h3 className='fw-bold text-center p-5'>No Result Found!</h3></>}
            {/* Content Component End */}
            {/* <ButtonPage /> */}
          </div>
          {/* Civil right Section Start */}
          <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              {/* Year selection dropdown */}
              {/* <div className="year-navigation d-flex justify-content-between ">
                <button className='custom-fc-prev-button p-3 py-2' onClick={() => handleYearChange(-1)}><FaAnglesLeft /></button>
                <button className='custom-fc-next-button p-3 py-2' onClick={() => handleYearChange(1)}><FaAnglesRight /></button>
              </div> */}
              {/* <div className="full-calendar-container"> */}
                {/* <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                  // initialView="timeGridWeek"
                  selectable={true} // Enable selectable feature
                  weekends={true}
                  dateClick={handleDateClick}
                  ref={calendarRef}
                /> */}
                {/* <Calendar
                  onChange={handleDateClick}
                  // value={selectedDate}
                /> */}
                  {/* </div> */}
              {/* {selectedDate} */}
              <CivilrightSection />
              <div className="card-body">
                <CurrentAffair onDateSelect={handleDateinput} componentName={componentName} />
              </div>
            </div>
          </div>
          {/* Civil right Section End */}
        </div>
      </div>
    </>
  );
}

export default Current1;

