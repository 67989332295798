import React from 'react';
import "../ReusableComponent/CIVIL/CivilBatchSection/CivilBatch.css";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { Link } from 'react-router-dom';

function Socialicon() {
    return (
        <>
            <div className='row mt-3'>
                <ul className='d-flex justify-content-bewteen align-items-center'>
                    <li className='list-group-item m-1 mx-0'>
                        <Link className='list-group-item p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                            <FaFacebook />
                        </Link>
                    </li>
                    <li className='list-group-item m-1 mx-0'>
                        <Link className='list-group-item p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                            <FaWhatsapp />
                        </Link>
                    </li>
                    <li className='list-group-item m-1 mx-0'>
                        <Link className='list-group-item p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                            <FaYoutube />
                        </Link>
                    </li>
                    <li className='list-group-item m-1 mx-0'>
                        <Link className='list-group-item p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                            <FaTelegram />
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Socialicon;