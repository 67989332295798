import React, { useState } from 'react';
import "./CivilBatch.css";
import { BsPersonWorkspace } from "react-icons/bs";
import { BsCardChecklist } from "react-icons/bs";
import { BsCalendar2Week } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoLogoAndroid, IoIosShareAlt } from "react-icons/io";
import { Modal } from 'react-bootstrap';
import { FaRupeeSign } from "react-icons/fa";
import ButtonModal from '../../../ToggleButton/ToggleButton';
import YoutubeModal from '../../../YoutubeModal/YoutubeModal';
import CourseShareComponent from '../../../CourseShareComponent/CourseShareComponent';
import CourseShareMobileComponent from '../../../CourseShareComponent/CourseShareMobileComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import CivilModal from '../CivilModal/CivilModal';
import 'animate.css';

function CivilBatch({ batchName, date, seat, lesson, prevPath ,authtoken, BatchID ,ispayment, price1, price2, CourseID, price3, paragraph, Image, Syllabuspdf, isAuth, VideoId, coursename, setClassData }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    console.log(isAuth)
    console.log(authtoken)
    console.log(BatchID)
    const navigate = useNavigate();
    const openModal = () => {
        setIsOpen(true);
    };

    const openModal1 = () => {
        setIsOpen1(true);
    };

    const openModal2 = () => {
        setIsOpen2(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const closeModal1 = () => {
        setIsOpen1(false);
    };

    const closeModal2 = () => {
        setIsOpen2(false);
    };

    const openModal3 = () => {
        setIsOpen3(true);
    };
    
    const closeModal3 = () => {
        setIsOpen3(false);
        // navigate(`/course/${coursename}`)
    };
    // function formatIndianNumber(number) {
    //     const parts = number.toString().split('.');
    //     parts[0] = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ',');
    //     return parts.join('.');
    //   }

      const formattedNumber1 = price1.toLocaleString('en-IN');
    //   const formattedNumber1 = formatIndianNumber(price1);
    //   const formattedNumber2 = formatIndianNumber(price2);
      const formattedNumber2 = price2.toLocaleString('en-IN');
    return (
        <>
            {/* Civil Batch Section Start */}
            <div className='CivilBatch-row laptop-view'>
                <div className='col-lg-8 col-md-6 col-sm-12'>
                    <div className='row'>
                        <h1 className='fs-3 text-white d-flex justify-content-between'>{batchName}</h1>
                        {/* <h1 className='fs-3 text-white d-flex justify-content-between'>{batchName} <span className='d-flex align-items-center'><span className='fs-6' onClick={openModal2} ><IoIosShareAlt /> Share</span> <Link to="https://api.whatsapp.com/send?phone=919821333936" className='list-group-item bg-success ms-2 p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaWhatsapp />
                                </Link></span></h1> */}
                        <hr className='text-white' />
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                            <BsCalendar2Week className='text-white icon-size mb-2' />
                            <h5 className='text-white fs-6'>STARTS : {date}</h5>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                            <BsPersonWorkspace className='text-white icon-size mb-2' />
                            {seat !== 0 ? <h5 className='text-white fs-6'>{seat} SEATS AVAILABLE</h5> : <h5 className='text-white fs-6'>Admissions Closed</h5>}
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                            <BsCardChecklist className='text-white icon-size mb-2' />
                            <h5 className='text-white fs-6'>{lesson} LESSONS</h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div class="d-flex gap-3">
                            <Link class="btn bg-orange w-50 fw-bold fs-6 p-2" to={`${Syllabuspdf}`} type="button"><MdOutlineFileDownload className='fw-bold mb-1 fs-5' />Download Syllabus</Link>
                            <Link class="btn bg-purple w-50 fw-bold fs-6 p-2" to="#" onClick={openModal} type="button"><IoLogoAndroid className='fw-bold mb-1 fs-5' />Download App</Link>
                            {isAuth ? <>{VideoId ? <Link class="btn bg-orange w-50 fw-bold fs-6 p-2" to="#" onClick={openModal1} type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link> : ""}</> : <Link class="btn bg-orange w-50 fw-bold fs-6 p-2" to="/login" type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link>}
                        </div>
                    </div>
                    <div className='row py-0'>
                        <p className='text-white batch-para'>
                            {paragraph}
                            {/* {price2 !== 0 && (price3 !== 0 ? <h1 className='text-white fs-2'> <FaRupeeSign className='fs-4' />{price1}<b className='fs-4 fw-normal px-1 text-decoration-line-through'><FaRupeeSign className='fs-5' />{price2}</b><span className='text-white fs-5 px-0'>{price3}% off</span></h1> : <h1 className='text-white fs-2'><FaRupeeSign /> {price1}</h1>)} */}
                        </p>
                        <ul className='d-flex justify-content-bewteen'>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaFacebook />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-success p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaWhatsapp />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-danger p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaYoutube />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaTelegram />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        {/* <ul className='d-flex justify-content-bewteen'>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaFacebook />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-success p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaWhatsapp />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-danger p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaYoutube />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaTelegram />
                                </Link>
                            </li>
                        </ul> */}
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='d-grid pb-2'>
                        <img src={Image} className="batch-img" alt='...' />
                    </div>
                    <div className='row'>
                        {price2 !== 0 && (price3 !== 0 ? <h1 className='text-white ps-2 fs-4'> <FaRupeeSign className='fs-4' />{formattedNumber1}<b className='fs-6 fw-normal px-2 text-decoration-line-through'><FaRupeeSign className='fs-6'/>{formattedNumber2}</b><span className='text-white fs-6 px-0'>{price3}% off</span></h1> : <h1 className='text-white fs-2'><FaRupeeSign /> {formattedNumber1}</h1>)}
                    </div>
                    <div className='row'>
                        <div className="d-grid gap-3">
                            {isAuth ? <>{price2 !== 0 ? <Link class="btn bg-orange fw-bold fs-5 p-2" to="#" onClick={openModal3} type="button"><FaRupeeSign /> {formattedNumber1} ENROLL</Link> : <Link class="btn bg-orange fw-bold fs-5 p-2" to="#" type="button">ENROLLED</Link>}</> : <Link class="btn bg-orange fw-bold fs-5 p-2" to="/login" type="button"><FaRupeeSign /> {formattedNumber1} ENROLL</Link>}
                            {/* {price2 !== 0 ? <Link class="btn bg-orange fw-bold fs-6 p-2" to="/login" type="button">₹ {price1} ENROLL</Link> : <Link class="btn bg-orange fw-bold fs-6 p-2" to="#" type="button">ENROLLED</Link>} */}
                            {/* {isAuth && <Link class="btn bg-orange fw-bold fs-6 p-2" to="https://docs.google.com/forms/d/1LAL_L1mBhQWFpRUQVn9RgC7KpeguCGe81KTgAedFmTQ/viewform?edit_requested=true" type="button">BOOK DELIVERY ADDRESS</Link>} */}
                            <Link class="btn bg-green fw-bold fs-5 p-2" to="https://api.whatsapp.com/send?phone=919821333936" type="button"><FaWhatsapp className='fw-bold mb-1 fs-5' />+91 982 133 3936</Link>
                        </div>
                        <div className='d-grid pt-2'>
                            {isAuth && ispayment && <Link class="btn bg-orange w-100 fw-bold fs-6 p-2" to="https://docs.google.com/forms/d/1LAL_L1mBhQWFpRUQVn9RgC7KpeguCGe81KTgAedFmTQ/viewform?edit_requested=true" type="button">BOOK DELIVERY ADDRESS</Link>}
                        </div>

                    </div>
                </div>
            </div>

            <div className='CivilBatch-row phone-view'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='pb-2'>
                        <img src={Image} className="batch-img" alt='...' />
                    </div>
                    <div className='row py-1'>
                    {/* <h1 className='fs-3 text-white d-flex flex-wrap justify-content-between'>{batchName} <span className='d-flex align-items-center py-2'><Link className='fs-6 text-decoration-none text-white' onClick={openModal2} ><IoIosShareAlt /> Share</Link></span></h1> */}
                    <h1 className='fs-3 text-white d-flex justify-content-between'>{batchName}</h1>
                        <hr className='text-white' />
                    </div>
                    <div className='py-3 general-icon-container'>
                        <div className='col-lg-4 text-center p-3'>
                            <BsCalendar2Week className='text-white icon-size mb-2' />
                            <h6 className='text-white'>STARTS : {date}</h6>
                        </div>
                        <div className='col-lg-4 text-center p-3'>
                            <BsPersonWorkspace className='text-white icon-size mb-2' />
                            <h6 className='text-white'>{seat} SEATS AVAILABLE</h6>
                        </div>
                        <div className='col-lg-4 text-center p-3'>
                            <BsCardChecklist className='text-white icon-size mb-2' />
                            <h6 className='text-white'>{lesson} LESSONS</h6>
                        </div>
                    </div>
                    <div className='row py-1'>
                        <div class="d-grid gap-2">
                            <Link class="btn bg-orange fw-bold fs-6 p-2" to={`${Syllabuspdf}`} type="button"><MdOutlineFileDownload className='fw-bold mb-1 fs-5' />Download Syllabus</Link>
                            <Link class="btn bg-purple fw-bold fs-6 p-2" to="#" onClick={openModal} type="button"><IoLogoAndroid className='fw-bold mb-1 fs-5' />Download App</Link>
                            {isAuth ? <>{VideoId ? <Link class="btn bg-orange fw-bold fs-6 p-2" to="#" onClick={openModal1} type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link> : ""}</> : <Link class="btn bg-orange fw-bold fs-6 p-2" to="/login" type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link>}
                            <Link class="btn bg-green fw-bold fs-6 p-2" to="https://api.whatsapp.com/send?phone=919821333936" type="button"><FaWhatsapp className='fw-bold mb-1 fs-5' />+91 982 133 3936</Link>
                        </div>
                    </div>
                    <div className='row py-1'>
                        <div class="d-grid gap-3">
                            {isAuth ? <>{price2 !== 0 ? <Link class="btn bg-orange fw-bold fs-6 p-2" to="#" onClick={openModal} type="button"><FaRupeeSign /> {price1} ENROLL</Link> : <Link class="btn bg-orange fw-bold fs-6 p-2" to="#" type="button">ENROLLED</Link>}</> : <Link class="btn bg-orange fw-bold fs-6 p-2" to="/login" type="button"><FaRupeeSign /> {price1} ENROLL</Link>}
                            {isAuth && ispayment && <Link class="btn bg-orange fw-bold fs-6 p-2" to="https://docs.google.com/forms/d/1LAL_L1mBhQWFpRUQVn9RgC7KpeguCGe81KTgAedFmTQ/viewform?edit_requested=true" type="button">BOOK DELIVERY ADDRESS</Link>}
                        </div>
                    </div>
                    <div className='row'>
                        {/* {price2 !== 0 && (price3 !== 0 ? <h1 className='text-white ps-2 fs-3'> <FaRupeeSign className='fs-4' />{price1}<b className='fs-4 fw-normal px-0 text-decoration-line-through'><FaRupeeSign className='fs-5'/>{price2}</b><span className='text-white fs-5 px-0'>{price3}% off</span></h1> : <h1 className='text-white fs-2'><FaRupeeSign /> {price1}</h1>)} */}
                        {price2 !== 0 && (price3 !== 0 ? <h1 className='text-white ps-2 fs-2'> <FaRupeeSign className='fs-2' />{price1}<b className='fs-5 fw-normal px-2 text-decoration-line-through'><FaRupeeSign className='fs-5'/>{price2}</b><span className='text-white fs-5 px-0'>{price3}% off</span></h1> : <h1 className='text-white fs-2'><FaRupeeSign /> {price1}</h1>)}
                    </div>
                    <div className='row py-2'>
                        <p className='text-white batch-para'>
                            {paragraph}
                        </p>
                    </div>
                    <div className='row'>
                        <ul className='d-flex justify-content-bewteen'>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaFacebook />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-success p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaWhatsapp />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-danger p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaYoutube />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaTelegram />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Civil Batch Section End */}
            <Modal show={isOpen} centered className="animate__animated animate__fadeIn mobiledownload">
                <ButtonModal closeModal={closeModal} />
            </Modal>
            <Modal show={isOpen1} centered className="animate__animated animate__fadeIn mobileyoutube">
                <YoutubeModal closeModal={closeModal1} VideoId={VideoId} />
            </Modal>
            <Modal dialogClassName="modal-90w" show={isOpen2} centered className="animate__animated animate__fadeIn">
                <Modal.Body className='mobilesharewidth' style={{ backgroundColor: '#FFC107', borderRadius: "9px", width: "610px" }}>
                    {/* <CourseShareComponent coursename={coursename} closeModal={closeModal2} /> */}
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="modal-90vw" show={isOpen3} centered className="animate__animated animate__fadeIn">
                {/* <Modal.Body style={{ backgroundColor: '#FFC107', borderRadius: "9px", width: "510px" }}> */}
                    {/* <CourseShareMobileComponent coursename={coursename} closeModal={closeModal3} /> */}
                {/* </Modal.Body> */}
            </Modal>
            <Modal show={isOpen3} centered className="animate__animated animate__fadeIn mobileyoutube">
                <CivilModal payamount={price1} BatchID={BatchID} authtoken={authtoken}  CourseID={CourseID} prevPath={prevPath} closeModal={closeModal3} />
            </Modal> 
        </>
    );
}

export default CivilBatch;


