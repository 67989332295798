import React from 'react'
import gsImg from "../../Image/gsprism.png";
import ptImg from "../../Image/ptmaster.png";
import upscImg from "../../Image/upsc.png";
import { Link } from 'react-router-dom';
import "../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection.css";

function ImageComponent() {
    return (
        <>
            <div class="card-body">
                <Link to="/home" className='img-link'>
                    <img src={gsImg} className="img-civil-border" alt="..." />
                </Link>
            </div>
            <div class="card-body">
                <Link to="/home" className='img-link'>
                    <img src={ptImg} className="img-civil-border" alt="..." />
                </Link>
            </div>
            <div class="card-body" >
                <Link to="/home" className='img-link'>
                    <img src={upscImg} className="img-civil-border" alt="..." />
                </Link>
            </div>
        </>
    )
}

export default ImageComponent;