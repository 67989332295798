import React, { useState } from 'react';
import "./Login.css";
import logo from "../../Image/logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { useAlert } from 'react-alert';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Login({ setIsLoggedIn, prevPath, setAuthtoken }) { 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const alert = useAlert();
    const navigate = useNavigate();

    const generateToken = (email) => {
        // Generate a unique token value based on user's email
        const token = email;
        return token;
    };

    const setCookie = (name, value) => {
        // Set the cookie with the token value
        document.cookie = `${name}=${value}`;
    };

    const handleLogin = async () => {
        if (!email.trim()) {
            setError('Email address is required');
            return;
        } 

        try {
            const response = await fetch(`${BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            // If login is successful, generate a new token
            const token = generateToken(email);

            // Set the token as a cookie
            setCookie('token', token);
            console.log(token)

            // Reset form fields and state
            setEmail('');
            setPassword('');

            // Navigate to the previous route if it exists, otherwise navigate to default route
            if (prevPath) {
                navigate(prevPath);
            } else {
                navigate('/StudentDashboard');
            }

            // Update login state
            setIsLoggedIn(true);
            setAuthtoken(token)
            localStorage.setItem('prevPath', prevPath);
            localStorage.setItem('authtoken', token);
            // Show login success message
            alert.show('Logged in successfully');
        } catch (error) {
            setError('Login failed. Please check your credentials.');
        }
    };

    return (
        <>
            <div className='loginContainer'>
                <div className='loginImage'>
                    <img src={logo} alt='' />
                </div>
                <div className='loginForm'>
                    <div className="card login-card">
                        <div className="card-header m-3 text-center fw-500 text-white login-heading">
                            Login to your Account
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email ID</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder='Your email-id' aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary login-btn py-2 border-0" type="button" onClick={handleLogin}>LOGIN</button>
                                </div>
                            </form>
                            {/* Error message if login fails */}
                            {error && <p>{error}</p>}
                        </div>
                        <div className='m-3 login-bottom'>
                            <div className="card-footer py-3 d-flex justify-content-center login-footer">
                                Forgot Password..? <Link className='list-group-item px-1' to="/login/forgot">Click here</Link>
                            </div>
                            <div className="card-footer py-3 d-flex justify-content-center login-footer">
                                Not a Member? <Link className='list-group-item px-1' to="/Registration">Register</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-list">
                    <div className='login-box'>
                        <Link to="/home" className='login-link'>HOME</Link>
                        <Link to="/about" className='login-link'>ABOUT US</Link>
                        <Link to="/civils" className='login-link'>CIVILS</Link>
                        <Link to="#" className='login-link'>NEET</Link>
                        <Link to="#" className='login-link'>CUET</Link>
                        <Link to="#" className='login-link'>UGC-NET</Link>
                        <Link to="/daily-current-affairs/News" className='login-link'>CURRENT AFFAIRS</Link>
                        <Link to="/blogs" className='login-link'>BLOGS</Link>
                    </div>
                    <div className='login2'>
                        <h2 className='text-white'>© 2022 - 2023 Insight Delhi</h2>
                    </div>
                    <div className='login3'>
                        <div>
                            <FaFacebookF />
                        </div>
                        <div>
                            <FaTelegramPlane />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
