import React, { useEffect, useState } from 'react'
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton'
import CourseDescriptionleft from '../CourseDescriptionleft/CourseDescriptionleft'
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../insightConfig/insightConfig';

function CourseDescription({ isAuth, authtoken, setispayment, ispayment}) {
  const { sectionid } = useParams();
  const [courseDescriptionData, setCourseDescriptionData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/CourseDescription/${sectionid}`);
        const data = await response.json();
        setCourseDescriptionData(data[0]);
        console.log("Data fetched successfully");
        console.log("i am useing 2");
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    setispayment(true)
    fetchData();
  }, [])
  if (!courseDescriptionData) {
    return <div>Loading...</div>
  }
  console.log(courseDescriptionData)
  let componentName = "";
  const isDescription = courseDescriptionData.Description;
  if (isDescription) {
    componentName = "CourseDescription"
  } else {
    componentName = "PrelimsDescription"
  }
  const paragraphs = courseDescriptionData.Description;
  return (
    <>
      <div className='Dashboard-background'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-3 col-sm-12 dashboardmain-left p-3'>
              <CourseDescriptionleft courseDescriptionData={courseDescriptionData.topiclession} />
            </div>
            <div className='col-lg-9 col-md-9 col-sm-12'>
              <CivilButton authtoken={authtoken} ispayment={ispayment} reviewtitle={courseDescriptionData.CourseTitle} iscourseid={courseDescriptionData.CourseID} sectionid={courseDescriptionData.SectionID} isAuth={isAuth} paragraphs={paragraphs} componentName={componentName} />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default CourseDescription
