import React, { useEffect, useState } from 'react';
import "./CivilTopSection.css";
import { IoLogoFacebook } from "react-icons/io5";
import { FaTelegram } from "react-icons/fa";


// Reusable component for displaying user information
function UserInfo({ userImage, userName }) {
  return (
    <div className='written'>
      <div className='written-Img'>
        <img src={userImage} alt='...' className='userimg'/>
      </div>
      <div className='written-heading ms-3 pt-2'>
        <h5 className='fs-6 text-white'>Written By</h5>
        <h3 className='fs-6 fw-bold text-white'>{userName}</h3>
      </div>
    </div>
  );
}

// Reusable component for the Civil Top section
function CivilTopSection({ academyName, academyName1,  userImage, userName, Image }) {
  const [firstHalf, setFirstHalf] = useState('')
  const [secondHalf, setSecondHalf] = useState('')
  const [thirdHalf, setThirdHalf] = useState('')

    function splitStringIntoThreeParts(inputString) {
      const length = inputString.length;
      const partLength = Math.floor(length / 3); // Approximate length for each part
      const parts = [];
  
      let startIndex = 0;
      let endIndex = partLength;
  
      for (let i = 0; i < 2; i++) { // Loop for the first two parts
          // Find the index to split the string without breaking a word
          while (inputString[endIndex] !== ' ' && endIndex > startIndex) {
              endIndex--;
          }
          parts.push(inputString.substring(startIndex, endIndex).trim());
          startIndex = endIndex + 1;
          endIndex = startIndex + partLength;
      }
  
      // Last part includes the remaining string
      parts.push(inputString.substring(startIndex).trim());
  
      return parts;
  }

  useEffect(()=>{
    if(academyName1 && !academyName){
      const parts = splitStringIntoThreeParts(academyName1);
      setFirstHalf(parts[0]);
      setSecondHalf(parts[1]);
      setThirdHalf(parts[2]);
    }
  },[academyName1, academyName])
  
  return (
      <div className='civilTop-container'> 
        <div className='CivilTop-left d-flex flex-column justify-content-around'>
          {academyName && <h1 className='fs-2 py-0 pt-0 text-white'>{academyName}</h1>}
          {firstHalf && <h1 className='fs-2 py-0 pt-0 text-white'>{firstHalf}</h1>}
          {secondHalf && <h1 className='fs-2 py-0 pt-0 text-white'>{secondHalf}</h1>}
          {thirdHalf && <h1 className='fs-2 py-0 pt-0 text-white'>{thirdHalf}</h1>}
          <UserInfo userImage={userImage} userName={userName} />
        </div>
        <div className='CivilTop-right'>
          <img src={Image} alt='...' className='civilTop-img'/>
          <div className='text-center text-white phone-view-follow'>
            <h5 className='fs-6'>Follow us:</h5>
            <IoLogoFacebook className='fs-4 m-1' />
            <FaTelegram className='fs-4 m-1' />
          </div>
        </div>
      </div>
  );
}

export default CivilTopSection;
