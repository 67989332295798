import React from 'react';
import "./CivilOverview.css";
import Content from '../../../AllPageContent/AllPageContent';

function CivilOverview({paragraphs}) {
    return (
        <>
            <div className='CivilOverview-background m-3'>
            <Content paragraphs={paragraphs} />
            </div>
        </>
    )
}

export default CivilOverview;