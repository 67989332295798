import React, { useState, useEffect } from 'react';
import "./CivilButton.css";
import CivilFeature from '../CivilFeature/CivilFeature';
import CivilToggle from '../CivilToggle/CivilToggle';
import CivilCard from '../CivilCard/CivilCard';
import CivilPop from '../CivilPop/CivilPop';
import CivilOverview from '../CivilOverview/CivilOverview';
import Toggle from '../CivilTestToggle/CivilTestToggle';
import CivilSchedule from '../CivilSchedule/CivilSchedule';
import Setting from '../../../Settings/Setting';
import Security from '../../../Security/Security';
import Mycourse from '../../../Mycourse/MyCourses';
import Subscribemore from '../../../Subscribemore/Subscribemore';
import Mytest from '../../../Mytest/Mytest';
import Moretest from '../../../Moretest/Moretest';
import ClassQuestion from '../../../ClassQuestion/ClassQuestion';
import Prelim from '../../../Prelims/Prelims';

function CivilButton({ data, CardData, paragraphs, iscourseid, componentName, CourseID, payamount, BatchID , sectionid, testtoggleData, authtoken, notmydashtest, heading, userDtails, notmydashcourse, mydashcourse, mydashtest, isAuth, reviewtitle, setcoursepayment, setcoursenotpayment, coursepayment, ispayment, setispayment }) {
    const [features, SetFeatures] = useState(false);
    const [classes, SetClasses] = useState(false);
    const [current, SetCurrent] = useState(false);
    const [teacher, SetTeacher] = useState(false);
    const [upload, SetUpload] = useState(false);
    const [overview, SetOverview] = useState(false);
    const [test, SetTest] = useState(false);
    const [schedule, SetSchedule] = useState(false);
    const [setting, SetSetting] = useState(false);
    const [security, SetSecurity] = useState(false);
    const [mycourse, SetMyCourse] = useState(false);
    const [subscribemore, SetSubscribeMore] = useState(false);
    const [mytest, Setmytest] = useState(false);
    const [moretest, Setmoretest] = useState(false);
    const [classquestion, SetClassQuestion] = useState(false);
    const [Prelims, SetPrelims] = useState(false);
    const [activeButton, setActiveButton] = useState('features'); //Track active button
    const [buttonNames, setButtonNames] = useState([]); //Manage All button Name Array
    const ctype1 = "question"
    const ctype2 = "upload"
 console.log(BatchID)
    useEffect(() => {
        // Map component names to button labels
        const buttonLabels = {
            'civilHistory': ['Features', 'Classes', 'Current Developments', 'Teacher Support', 'Upload Answers'],
            'civilTest': ['Overview', 'Test', 'Schedule', 'Teacher Support'],
            'dashboard': ['Settings', 'Security', 'My Courses', 'Subscribe More Courses', 'My Test Series', 'Subscribe More Test Series'],
            'CourseDescription': ['Class Questions', 'Teacher Support', 'Upload Answers'],
            'PrelimsDescription': ['Prelims', 'Teacher Support', 'Upload Answers']
            // Add more component names and their associated button labels as needed
        };
        const defaultButtons = buttonLabels['civilHistory']; // Default to civilHistory if componentName doesn't match
        const buttons = buttonLabels[componentName] || defaultButtons;
        // Manage Default State of Feature and Overview Content According to ComponentName
        switch (componentName) {
            case 'civilHistory':
                SetFeatures(true);
                setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'civilTest':
                SetTest(true);
                setActiveButton(buttons[1].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'dashboard':
                SetMyCourse(true);
                setActiveButton(buttons[2].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'CourseDescription':
                SetClassQuestion(true);
                setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'PrelimsDescription':
                SetPrelims(true);
                setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
            default:
                break;
        }
        setButtonNames(buttons);
    }, [componentName]);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName.toLowerCase().replace(/\s+/g, ''));
    };

    return (
        <>
            {/* Civil Button Section Start */}
            <div className='Civilbutton-background'>
                <div className='p-3 bg-gray'>
                    <div className="civilbutton-Container">
                        {buttonNames.map((name, index) => (
                            <button
                                key={index}
                                className={`btn me-2 ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activebutton' : "bg-white"}`}
                                onClick={
                                    () => {
                                        handleButtonClick(name)
                                        switch (name) {
                                            case 'Features':
                                                // Handle Features accordingly
                                                SetFeatures(true)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Classes':
                                                // Handle Classes accordingly
                                                SetFeatures(false)
                                                SetClasses(true)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Current Developments':
                                                // Handle Current Developments accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(true)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Teacher Support':
                                                // Handle Teacher Support accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(true)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Upload Answers':
                                                // Handle Upload Answers accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(true)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Overview':
                                                // Handle Overview accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(true)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Test':
                                                // Handle Test accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(true)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Schedule':
                                                // Handle Schedule accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(true)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Settings':
                                                // Handle Settings accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(true)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Security':
                                                // Handle Security accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(true)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'My Courses':
                                                // Handle My Courses accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(true)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Subscribe More Courses':
                                                // Handle Subscribe More Courses accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(true)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'My Test Series':
                                                // Handle My Test Series accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(true)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Subscribe More Test Series':
                                                // Handle Subscribe More Test Series accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(true)
                                                SetClassQuestion(false)
                                                SetPrelims(false)
                                                break;
                                            case 'Class Questions':
                                                // Handle Subscribe More Test Series accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(true)
                                                SetPrelims(false)
                                                break;
                                            case 'Prelims':
                                                // Handle Subscribe More Test Series accordingly
                                                SetFeatures(false)
                                                SetClasses(false)
                                                SetCurrent(false)
                                                SetTeacher(false)
                                                SetUpload(false)
                                                SetOverview(false)
                                                SetTest(false)
                                                SetSchedule(false)
                                                SetSetting(false)
                                                SetSecurity(false)
                                                SetMyCourse(false)
                                                SetSubscribeMore(false)
                                                Setmytest(false)
                                                Setmoretest(false)
                                                SetClassQuestion(false)
                                                SetPrelims(true)
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                }
                                type="button">{name}</button>
                        ))}
                    </div>

                </div> 
                {/* Display Information Section Start */}
                <div className='Civilinfo-display'>
                    {/* ************************Civil History Component Start************************************ */}
                    {/* Civil Feature Section Start */}
                    {features && <div className='px-5 bg-cream'><CivilFeature paragraphs={paragraphs} /></div>}
                    {/* Civil Feature Section  End */}
                    {/* Civil Toggle Section Start  */}
                    {classes && <div className=''><CivilToggle ispayment={ispayment} isAuth={isAuth} classesArray={data} /></div>}
                    {/* Civil Toggle Section End */}
                    {/* Civil Card Section Start */}
                    {current && <div className='px-4 ps-5'><CivilCard CardData={CardData} /></div>}
                    {/* Civil Card Section End */}
                    {/* Civil Pop Section Start */}
                    {teacher && <CivilPop isAuth={isAuth} ispayment={ispayment} ctype1={ctype1} ctype2={''} isteacher={teacher} reviewtitle={reviewtitle} iscourseid={iscourseid} sectionid={sectionid} coursepayment={coursepayment} authtoken={authtoken} />}
                    {upload && <CivilPop isAuth={isAuth} ispayment={ispayment} ctype1={''} ctype2={ctype2} isupload={upload} reviewtitle={reviewtitle} iscourseid={iscourseid} sectionid={sectionid} coursepayment={coursepayment} authtoken={authtoken} />}
                    {/* Civil Pop Section End */}
                    {/* *************************Civil History Component End*************************************** */}
                    {/* *************************Civil Test Component Start**************************************** */}
                    {/* Civil Overview Section Start */}
                    {overview && <CivilOverview paragraphs={paragraphs} />}
                    {/* Civil Overview Section End */}
                    {/* Toggle Section Start */}
                    {test && <Toggle testtoggleData={testtoggleData} CourseID={CourseID} BatchID={BatchID} payamount={payamount} authtoken={authtoken} />}
                    {/* Toggle Section End */}
                    {/* Civil Schedule Section Start */}
                    {schedule && <CivilSchedule testtoggleData={testtoggleData} />}
                    {/* Civil Schedule Section End */}
                    {/* *************************Civil Test Component End*************************************** */}
                    {/* *************************Dashboard Component Start**************************************** */}
                    {/*Settings Section Start */}
                    {setting && <Setting userDtails={userDtails} authtoken={authtoken} />}
                    {/*Settings Section End */}
                    {/*Security Section Start */}
                    {security && <Security authtoken={authtoken} />}
                    {/*Security Section End */}
                    {/*My Courses Section Start */}
                    {mycourse && <Mycourse cards={mydashcourse} setispayment={setispayment} setcoursepayment={setcoursepayment} setcoursenotpayment={setcoursenotpayment} heading={heading} />}
                    {/*My Courses Section End */}
                    {/*Subscribe More Courses Section Start */}
                    {subscribemore && <Subscribemore cards={notmydashcourse} setispayment={setispayment} setcoursepayment={setcoursepayment} setcoursenotpayment={setcoursenotpayment} heading={heading} />}
                    {/*Subscribe More Courses Section End */}
                    {/*My Test Series Section Start */}
                    {mytest && <Mytest cards={mydashtest} heading2={heading} />}
                    {/*My Test Series Section End */}
                    {/*Subscribe More Test Series Section Start */}
                    {moretest && <Moretest cards={notmydashtest} heading2={heading} />}
                    {/*Subscribe More Test Series Section End */}
                    {/* *************************Dashboard Component End*************************************** */}
                    {/* *************************Course Description Component Start**************************************** */}
                    {/*Settings Section Start */}
                    {classquestion && <ClassQuestion paragraphs={paragraphs} />}
                    {/*Settings Section End */}
                    {/* *************************Course Description Component End*************************************** */}
                    {/* *************************Prelims Component Start**************************************** */}
                    {/*Settings Section Start */}
                    {Prelims && <Prelim />}
                    {/*Settings Section End */}
                    {/* *************************Prelims Component End*************************************** */}
                </div>
                {/* Display Information Section End */}
            </div>
            {/* Civil Button Section End */}
        </> 
    )
}

export default CivilButton;