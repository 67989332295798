// import React, { useState } from 'react';
// import { FaPlay } from "react-icons/fa";
// import { IoMdShare } from "react-icons/io";
// import { Link } from 'react-router-dom';
// import "../../ReusableComponent/CIVIL/CivilCard/CivilCard.css";
// import ShareComponent from '../../ShareComponent/ShareComponent';

// function BlogCard({ CardData }) {
//     const [shareVisibility, setShareVisibility] = useState({});

//     const toggleShareVisibility = (index) => {
//         setShareVisibility(prevState => ({
//             ...prevState,
//             [index]: !prevState[index]
//         }));
//     };

//     const [hoveredIndex, setHoveredIndex] = useState(-1);

//     const handleMouseEnter = (index) => {
//         setHoveredIndex(index);
//         console.log(hoveredIndex)
//     };
    
//     const handleMouseLeave = () => {
//         setHoveredIndex(-1);
//         toggleShareVisibility(-1);
//         console.log(hoveredIndex)
//     };
//     return (
//         <>
//             {CardData.map((item, index) => (
//                 <div className='blogCard-background' key={index}>
//                     <div className="blogCard-box">
//                         <h6>
//                             <Link className='list-group-item'>
//                                 {item.Title}
//                             </Link>
//                         </h6>
//                         <div className='blogcard-box2'>
//                             <div className="text-center BlogCard-imgs">
//                                 <Link to={`/blog/${item.TitleUrl}`} className='list-group-item'>
//                                     <img src={item.Image} className='BlogCard-img' alt={`Images ${index}`} />
//                                     <p className='fw-bold pt-3'>By : {item.WrittenBy}</p>
//                                 </Link>
//                             </div>
//                             <div className="blogcardtext d-flex justify-content-between">
//                                 <div className='blog-box1'>
//                                     <div className=''>
//                                         <p className='blog-text'>
//                                             {item.Subtitle}
//                                         </p>
//                                         <p></p>
//                                         <div className="blog-box2">
//                                             <Link to={`/blog/${item.TitleUrl}`} className='list-group-item bg-purepurple p-2 d-flex justify-content-center align-items-center fw-bold text-white'>Read More <FaPlay className='text-pureorange' /></Link>
//                                         </div>
//                                     </div>
//                                     <div className='d-flex flex-column align-items-end justify-content-end'>
//                                         <Link to="#" onMouseEnter={() => handleMouseEnter(index)} 
//                                             onMouseLeave={handleMouseLeave} className='list-group-item p-2 d-flex justify-content-center align-items-center border border-0.5 rounded-3 shadow-lg bg-body rounded border-secondary bg-light'><IoMdShare /> Share</Link>
//                                         {/* {shareVisibility[index] && <ShareComponent />} */}
//                                         {hoveredIndex === index || shareVisibility && <ShareComponent/>}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             ))}
//         </>
//     )
// }

// export default BlogCard;

import React from 'react';
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { GoClockFill } from "react-icons/go";
import "../../ReusableComponent/CIVIL/CivilCard/CivilCard.css";
import ShareComponent1 from '../../ShareComponent/ShareComponent1';

function BlogCard({ CardData }) {
    
    const convertTimestamp = (originalTimestamp) => {
        // Parse the original timestamp into a Date object
        const originalDate = new Date(originalTimestamp)
        // Convert the Date object into the desired format
        const formattedDate = originalDate.toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
    
        return formattedDate;
      }  
    return (
        <>
            {CardData.map((item, index) => (
                <div className='blogCard-background' key={index}>
                    <div className="blogCard-box">
                        <h6>
                            <Link className='list-group-item'>
                                {item.Title}
                            </Link>
                        </h6>
                        <div className='blogcard-box2'>
                            <div className="text-center BlogCard-imgs">
                                <Link to={`/blog/${item.TitleUrl}`} className='list-group-item'>
                                    <img src={item.Image} className='BlogCard-img' alt={`Images ${index}`} />
                                    <p className='fw-bold pt-3'>By : {item.WrittenBy}</p> 
                                </Link>
                            </div>
                            <div className="blogcardtext d-flex justify-content-between">
                                <div className='blog-box1'>
                                    <div className=''>
                                        <p className='blog-text'>
                                            {item.Subtitle}
                                        </p>
                                        <p><GoClockFill /> {convertTimestamp(item.PublishDate)}</p>
                                        {/* <div className="blog-box2">
                                            <Link to={`/blog/${item.TitleUrl}`} className='list-group-item bg-purepurple p-2 d-flex justify-content-center align-items-center fw-bold text-white'>Read More <FaPlay className='text-pureorange' /></Link>
                                        </div> */}
                                    </div>
                                    <div className="blog-box2 justify-content-end">
                                            <Link to={`/blog/${item.TitleUrl}`} className='list-group-item bg-darkblue p-2 d-flex justify-content-center align-items-center fw-bold text-white'>Read More <FaPlay className='ms-2 text-pureorange' /></Link>
                                            {/* <Link to={`/blog/${item.TitleUrl}`} className='p-2 d-flex justify-content-center align-items-center'>read more</Link> */}
                                        </div>
                                    {/* <ShareComponent1 index={index} url={item.TitleUrl}/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BlogCard;
