import React, { useEffect } from 'react'
import CardsPage from '../CardsPage/CardsPage';

function Subscribemore({cards, heading, setcoursenotpayment, setcoursepayment, setispayment}) {
  useEffect(()=>{
    // {cards.map((card) => (
    //   setcoursenotpayment(card.paymentdonecourse),
    //   setcoursepayment(card.paymentdonecourse)
    // ))}
    setcoursepayment(0)
    setcoursenotpayment(1)
    setispayment(false)
  },[])
  return (
    <>
     <CardsPage cards={cards} heading={heading}/> 
    </>
  )
}

export default Subscribemore;