import React from 'react';
import "../Dashborad/dashboard.css";
import "../CardsPage/CardsPage.css";
// Dashborad\dashboard.css
// import { ShimmerBadge } from "react-shimmer-effects";
import { ShimmerCircularImage, ShimmerTitle, ShimmerBadge, ShimmerText, ShimmerSectionHeader, ShimmerSimpleGallery, ShimmerButton } from "react-shimmer-effects";
import TwoRowThreeColumnLayout from './TwoThreecolumnLayout';
import CardEffect from './CardEffect';

function Dashboradeffect({ authtoken, setcoursepayment, setcoursenotpayment, ispayment, setispayment }) {

  return (
    <>
      <div className='Dashboard-background'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-12 col-sm-12 dashboardmain-left p-3'>
              {/* <Dashboardleft authtoken={authtoken} userDtails={userDtails}/> */}
              <div className='dashboardleft-background'>
                <div className='dash1'>
                  {/* <ShimmerCircularImage size={200} /> */}
                  <ShimmerSimpleGallery imageType="circular" row={1} col={1} imageHeight={200} caption />
                </div>
              </div>
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12'>
              {/* <CivilButton componentName={componentName} setispayment={setispayment} ispayment={ispayment} paymentdoneCourse1={paymentdoneCourse1} setcoursepayment={setcoursepayment} setcoursenotpayment={setcoursenotpayment} notmydashcourse={notmycards} authtoken={authtoken} notmydashtest={notmytests} mydashtest={mytests} mydashcourse={mycards} heading={civilheading} userDtails={userDtails}/> */}
              <div className='row pt-3' style={{ background: "rgba(0,0,0,0.1)" }}>
                <div className='col-lg-1'>
                  <ShimmerBadge />
                </div>
                <div className='col-lg-1'>
                  <ShimmerBadge />
                </div>
                <div className='col-lg-1'>
                  <ShimmerBadge />
                </div>
                <div className='col-lg-3'>
                  <ShimmerBadge width={270} />
                </div>
                <div className='col-lg-1'>
                  <ShimmerBadge />
                </div>
                <div className='col-lg-1'>
                  <ShimmerBadge width={270} />
                </div>
              </div>
              <div className='row'>
                <div className="container-fluid">
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <div className='p-2'>
                        <div className='card p-2 Allcard AllDashboard'>
                          {/* <ShimmerPostItem card title cta /> */}
                          <ShimmerSimpleGallery row={1} col={1} imageHeight={250} />
                          <div className='card-body'>
                            <ShimmerTitle line={1} gap={10} variant="primary" />
                            <ShimmerText line={1} gap={10} />
                            <div className='d-flex justify-content-between align-items-center'>
                              <ShimmerButton size="md" />
                              <ShimmerButton size="md" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                    <div className='p-2'>
                        <div className='card p-2 Allcard AllDashboard'>
                          {/* <ShimmerPostItem card title cta /> */}
                          <ShimmerSimpleGallery row={1} col={1} imageHeight={250} />
                          <div className='card-body'>
                            <ShimmerTitle line={1} gap={10} variant="primary" />
                            <ShimmerText line={1} gap={10} />
                            <div className='d-flex justify-content-between align-items-center'>
                              <ShimmerButton size="md" />
                              <ShimmerButton size="md" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                    <div className='p-2'>
                        <div className='card p-2 Allcard AllDashboard'>
                          {/* <ShimmerPostItem card title cta /> */}
                          <ShimmerSimpleGallery row={1} col={1} imageHeight={250} />
                          <div className='card-body'>
                            <ShimmerTitle line={1} gap={10} variant="primary" />
                            <ShimmerText line={1} gap={10} />
                            <div className='d-flex justify-content-between align-items-center'>
                              <ShimmerButton size="md" />
                              <ShimmerButton size="md" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboradeffect;
