import React, { useState } from 'react';
import './Toggle.css'; // You can create a CSS file for styling
import { Link } from 'react-router-dom';
import { FaClock } from "react-icons/fa6";
// import Modal from 'react-modal';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import CivilModal from '../CivilModal/CivilModal';
import CivilTestToggleContent from '../CivilTestToggleContent/CivilTestToggleContent';


function Toggle({ testtoggleData, authtoken, payamount, CourseID, BatchID }) {
    console.log(authtoken)
    const [activeIndex, setActiveIndex] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
    };

    const toggleState = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <div className='card p-2 m-4 shadow-lg'>
                {testtoggleData.map((testData, index) => (
                    <div key={index} className="d-grid gap-1 m-1 test-togglebackground">
                        <button onClick={() => toggleState(index)} className="toggle-button py-2">
                            <div className="text-nowrap me-2">
                                {testData.Type === "MCQ" ? <h1 className='fs-6'>PTS-{testData.AccessCode}</h1> : <h1 className='fs-6'>MTS-{testData.AccessCode}</h1>}
                            </div>
                            <div className='toggleheadingContainer me-3'>
                                <h1 className='fs-6'>{testData.Title}</h1>
                            </div>
                            {testData.PublishDateNew == 'Unlock' && !authtoken ? (
                                <Link to="/login" className='list-group-item p-1 fw-bold bg-pureorange test-btn text-white'>
                                    TAKE TEST
                                </Link>
                            ) : ((authtoken && testData.Type != "MCQ" && testData.PublishDateNew == "Unlock" && testData.paymentdonetest == 0) ?
                                <Link to="/teststart" className='list-group-item p-1 fw-bold bg-pureorange test-btn text-white'>
                                    TAKE TEST
                                </Link> : ((authtoken && testData.Type == "MCQ" && testData.PublishDateNew == "Unlock" && testData.paymentdonetest == 0) ?
                                    <Link to="/teststart" className='list-group-item p-1 fw-bold bg-pureorange test-btn text-white'>
                                        TAKE TEST
                                    </Link> : ((authtoken && testData.PublishDateNew == "Unlock" && testData.Availability == "close" && testData.paymentdonetest == 1) ?
                                        <Link to="/teststart" className='list-group-item p-1 fw-bold bg-pureorange test-btn text-white'>
                                            TAKE TEST
                                        </Link> : ((authtoken && testData.PublishDateNew == "Unlock" && (testData.Availability == "open" || testData.Availability == "open to all") && testData.paymentdonetest == 1) ?
                                            <Link to="/teststart" className='list-group-item p-1 fw-bold bg-pureorange test-btn text-white'>
                                                TAKE TEST
                                            </Link> :
                                            <Link to="#" onClick={openModal} className='list-group-item p-1 fw-bold bg-pureorange text-white test-btn'>
                                                <FaClock className='mb-1 me-2' />
                                                {new Date(testData.PublishDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
                                            </Link>
                                        )
                                    )
                                )
                            )}
                        </button>

                        {activeIndex === index && (
                            <div className="content p-4">
                                <CivilTestToggleContent items={testData.Description || []} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {/* <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            > 
                <CivilModal closeModal={closeModal} />
            </Modal> */}
            <Modal show={isOpen} centered className="animate__animated animate__fadeIn mobileyoutube">
                <CivilModal closeModal={closeModal} authtoken={authtoken} BatchID={BatchID} CourseID={CourseID} payamount={payamount}/>
            </Modal>
        </>
    );
}

export default Toggle;


