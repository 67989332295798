import React from 'react';
import "../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection.css";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom';

function  SocialComponent() {
  return (
    <>
      <div class="card-body SocialComponent-background flex-wrap">
                        <Link to="https://api.whatsapp.com/send?phone=919821333936" className='text-decoration-none text-center flex-grow-1 py-3 bg-sky bg-success me-3'>
                            <FaWhatsapp className='fs-1 text-white mb-1 rounded-circle' />
                            <h5 className='fs-6 text-white'>Follow us on</h5>
                            <h6 className='fs-5 text-white'>Whatsapp</h6>
                        </Link>
                        <Link to="https://t.me/INSIGHTICS" className='text-decoration-none text-center flex-grow-1 py-3 bg-sky'>
                            <FaTelegramPlane className='fs-1 text-white mb-1 rounded-circle' />
                            <h5 className='fs-6 text-white'>Follow us on</h5>
                            <h6 className='fs-5 text-white'>Telegram</h6>
                        </Link>
                        <Link to="https://www.youtube.com/@INSIGHTACADEMYIAS" className='text-decoration-none text-center flex-grow-1 py-3 mt-3 bg-red me-3'>
                            <FaYoutube className='fs-1 text-white mb-1 rounded-circle' />
                            <h5 className='fs-6 text-white'>Follow us on</h5>
                            <h6 className='fs-5 text-white'>YouTube</h6>
                        </Link>
                        <Link to="#" className='text-decoration-none text-center flex-grow-1 py-3 mt-3 bg-red bg-primary'>
                            <FaFacebook className='fs-1 text-white mb-1 rounded-circle' />
                            <h5 className='fs-6 text-white'>Follow us on</h5>
                            <h6 className='fs-5 text-white'>Facebook</h6>
                        </Link>
                    </div>
    </>
  )
}

export default SocialComponent;