import React from 'react'
import LongCard2 from './LongCard2';

function Pagelayout2() {
    return (
        <>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
                <LongCard2/>
        </>
    )
}

export default Pagelayout2;