import React from 'react';
import "./AllPageContent.css";

// All Content Page 
const CivilContent = ({ paragraphs, paragraphstatic, listStyleType, customclass}) => {
  console.log(paragraphs)
  const generateTableClass = (index) => `${customclass}-table-${index}`;
  const renderParagraphs = (paragraphs) => {
      return <div dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };
  const renderParagraphstatic = (paragraphstatic) => {
    return paragraphstatic.map((paragraph, paragraphIndex) => {
      const { text, styles, lists, table, images, video } = paragraph;
      let newText = text;

      // Applying styles to paragraph text
      styles.forEach(({ word, style }) => {
        const parts = newText.split(word);
        newText = parts.join(`<span style="${style}">${word}</span>`);
      });

      // Rendering the main list and nested lists if they exist
      const styledLists = lists && lists.length > 0 && lists.map((item, index) => {
        let styledItem = item;

        // If the list item is an array (nested list)
        if (Array.isArray(item)) {
          styledItem = item.map((nestedItem, nestedIndex) => {
            return (
              <ul key={nestedIndex}>
                <li dangerouslySetInnerHTML={{ __html: nestedItem }} />
              </ul>
            );
          });
        } else {
          // If the list item is a string
          styles.forEach(({ word: word2, style }) => {
            const parts = styledItem.split(word2);
            styledItem = parts.join(`<span style="${style}">${word2}</span>`);
          });
          styledItem = <li key={index} dangerouslySetInnerHTML={{ __html: styledItem }} />;
        }

        return styledItem;
      });

      // Rendering the table if it exists
      const styledTable = table && table.length > 0 && (
        <div className={`${customclass}-tablebackground`}>
        <table className={`${customclass}-table ${generateTableClass(paragraphIndex)}`} key={`table_${paragraphIndex}`}>
          <tbody>
            {table.map((row, rowIndex) => (
              
              <tr className={`table-row${paragraphIndex}${rowIndex}`} key={`row_${rowIndex}`}>
                <td className={`table-heading ${customclass}-table-heading`}>{row.Heading}</td>
                <td className={`table-row table-row${paragraphIndex}_1`}>{row.data}</td>
                <td className={`table-row table-row${paragraphIndex}_2`}>{row.data1}</td>
                <td className={`table-row table-row${paragraphIndex}_3`}>{row.data2}</td>
                <td className={`table-row table-row${paragraphIndex}_4`}>{row.data3}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      );

      // Rendering images if they exist
      const styledImages = images && images.length > 0 && images.map((image, imageIndex) => (
        <div className={`${customclass}-image text-center`}>
        <img
          key={`image_${paragraphIndex}_${imageIndex}`}
          src={image.src}
          alt={image.alt}
          className={`${customclass}-image`}
        />
        </div>
      ));

      // Rendering video if it exists
      const styledVideo = video && (
        <div className={`video-Container`} key={`video_${paragraphIndex}`}>
          <iframe
            title={video.title}
            width="560"
            height="315"
            alt={video.alt}
            src={video.src}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      );

      return (
        <div key={paragraphIndex} className='Paragraphs'>
          <p dangerouslySetInnerHTML={{ __html: newText }} />
          {styledImages}
          {styledLists && <ul style={{ listStyleType: listStyleType }}>{styledLists}</ul>}
          {styledTable}
          {styledVideo}
        </div>
      );
    });
      // return <div dangerouslySetInnerHTML={{ __html: paragraphstatic }} />;
  };

  return <div className='dynamic-paragraph'>{paragraphs ? renderParagraphs(paragraphs): renderParagraphstatic(paragraphstatic)}</div>;
};

export default CivilContent;
// All Content Page