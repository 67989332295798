import React from 'react';
import "./CivilFeature.css";
import Content from '../../../AllPageContent/AllPageContent';

function CivilFeature({ paragraphs }) {
    return (
        <>
            {/* Civil Feature Page Start */}
            <div className='p-4 bg-light'>
            <Content paragraphs={paragraphs} />
            </div>
            {/* Civil Feature Page End */}
        </>
    )
}

export default CivilFeature;